<template>
  <div class="show">
    <v-responsive @click="viewShow" :aspect-ratio="aspect" :height="height" :width="showWidth" class="show-image-container cursor-pointer">
      <v-img :src="show.image" :lazy-src="lazyImage" height="100%" width="100%">
      </v-img>
    </v-responsive>
  </div>
</template>

<style lang="scss">
.show {
  height: 100%;
}
</style>

<script>
  import { EventBus } from '../../../eventBus';
  export default {
    name: 'Show',
    props: ['show', 'height', 'disabled'],
    data: () => ({
    }),
    computed: {
      aspect () {
        if (this.show.showType == 'video') {
          return 16/9;
        } else if (this.show.showType == 'audio') {
          return 1;
        }
        return 16/9;
      },
      showWidth () {
        return this.height * this.aspect;
      },
      lazyImage () {
        if (this.show.showType == 'audio') {
          return '/backgrounds/loading-thumbnail-square.jpg';
        }
        return '/backgrounds/loading-thumbnail.jpg';
      },
    },
    methods: {
      viewShow: function () {
        if (!this.disabled) {
          EventBus.$emit('viewShow', this.show)
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.show-image-container {
  border-radius: 10px;
}
</style>
