<template>
  <div>
    <v-btn tile @click="navigate('register')" color="primary" class="mr-4">
      Join
    </v-btn>
    <v-btn v-if="isMobile" @click="navigate('login')" outlined tile class="login-button">
      Login
    </v-btn>
    <v-menu
      id="login-menu"
      v-else
      v-model="loginMenu"
      content-class="login-menu"
      :close-on-content-click="false"
      offset-y
      :nudge-top="-32"
      :nudge-left="160">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" outlined tile class="login-button">
          Login
        </v-btn>
      </template>
      <login :menu="true"/>
    </v-menu>
  </div>
</template>
<script>
import login from './login'
export default {
  name: 'LoginBar',
  components: {
    login
  },
  data: () => ({
    loginMenu: false
  }),
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.xs
    }
  },
  methods: {
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
</style>
