<template>
  <v-card class="" color="#363636" tile flat>
    <v-card-title>
      Active Users
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div id="user-pie"></div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import c3 from 'c3';

export default {
  name: 'UserPie',
  props: ['data', 'width', 'height'],
  components: {
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    loadChart: function () {
      c3.generate({
        bindto: '#user-pie',
        data: {
          columns: this.data,
          type : 'pie',
          colors: {
            paypal: '#00457C',
            stripe: '#635bff',
            coupon: '#AA0000',
            free: '#b1b1b155'
          },
          onclick: function (d, i) { console.log("onclick", d, i); },
          onmouseover: function (d, i) { console.log("onmouseover", d, i); },
          onmouseout: function (d, i) { console.log("onmouseout", d, i); }
        },
        pie: {
          label: {
            format: function (value, ratio) {
              return value + ' (' + ratio.toFixed(2) + '%)';
            }
          }
        },
        size: {
          width: this.width,
          height: this.height
        }
      });
    }
  },
  mounted () {
    this.loadChart();
  }
}
</script>
<style lang="scss">
#user-pie .tick,
#user-pie .c3-legend-item {
  fill: white;
}

#user-pie .domain {
  fill: none;
  stroke: white;
}

#user-pie text {
  fill: white;
}
</style>
