<template>
  <v-carousel-item @click="clicked" class="cursor-pointer">
    <v-img :src="item.image" :height="height" class="carousel-image"></v-img>
    <div class="carousel-content">
      <div class="carousel-text">
        <div v-if="item.banner" class="carousel-banner">
          {{ item.banner }}
        </div>
        <v-container fill-height fluid class="carousel-title">
          <v-row justify="center" align="center" v-bind:style="{ 'height': '100%', 'width': '100%' }">
            <v-col class="pa-0">
              <div v-bind:style="{ 'height': '100%', 'width': '100%' }" 
              v-resize-text="{ ratio: sizingRatio, minFontSize: '20px', maxFontSize: '60px', delay: 200 }">
                {{item.title}}
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="carousel-subtext">
          {{ item.subtitle }}
        </div>
      </div>
    </div>
  </v-carousel-item>
</template>
<script>
import ResizeText from 'vue-resize-text'
export default {
  name: 'CarouselPage',
  props: ['item', 'height', 'preventNavigate'],
  directives: {
    ResizeText
  },
  components: {
  },
  data: () => ({
  }),
  computed: {
    sizingRatio () {
      if (this.mobile) {
        return (this.item.title.length / 30) * 1.5;
      }
      return (this.item.title.length / 30) * .8;
    },
    mobile () {
      return this.$store.getters.getIsMobile;
    }
  },
  watch: {
  },
  methods: {
    clicked: function () {
      if (!this.preventNavigate && this.item.link) {
        window.location.href = this.item.link;
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.carousel-content {
  position: absolute;
  left: 40px;
  top: calc(50% - 45px);
}
.carousel-text {
  position: relative;
}
.carousel-banner {
  font-size: 10px;
  background-color: var(--v-primary-base);
  display: inline-block;
  padding: 0 4px;
}
.carousel-subtext {
  font-size: 16px;
}
.carousel-image {
  -webkit-mask-image: -webkit-radial-gradient(circle at center, #000000ff 0%, #00000000 80%);
  mask-image: radial-gradient(circle at center, #000000ff 0%, #00000000 80%);
}

.disrupt-mobile .carousel-content {
  width: 100vw;
  left: 0px;
  top: 0%;
  bottom: 0%;
}

.disrupt-mobile .carousel-text {
  height: 100%;
}

.disrupt-mobile .carousel-banner,
.disrupt-mobile .carousel-title,
.disrupt-mobile .carousel-subtext {
  margin: auto;
}

.disrupt-mobile .carousel-banner {
  height: 20px;
  margin-top: 20px;
}
.disrupt-mobile .carousel-title {
  // height: 70%;
  height: calc(100% - 100px);
  max-height: calc(100% - 100px);
}

.disrupt-mobile .carousel-image {
  -webkit-mask-image: -webkit-linear-gradient(to bottom, #000000ff 0%, #00000000 90%);
  mask-image: linear-gradient(to bottom, #000000ff 0%, #00000066 90%);
}
.disrupt-mobile .carousel-text {
  text-align: center;
}
</style>
