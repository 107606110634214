<template>
  <v-hover v-slot="{ hover }" open-delay="100">
    <v-row class="comment-row" v-bind:class="{ 'mb-4': !separator && !preview }" @click="onClick">
      <v-col v-if="separator" cols="12" class="py-1">
        <v-divider />
      </v-col>
      <v-col class="comment-image-column">
        <v-avatar>
          <!-- <v-img src="https://cdn.vuetifyjs.com/images/profiles/marcus.jpg"></v-img> -->
          <v-img :src="profileImage"></v-img>
        </v-avatar>
      </v-col>
      <v-col class="comment-body-column">
        <v-row>
          <v-col cols="12" class="pa-0 comment-name">
            <span>{{ comment.username }}</span>
            <span class="ml-2 comment-timespan">{{ datedLabel }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0 comment-text">
            <!-- {{ comment.text }} -->
            <div v-text="comment.text"></div>
          </v-col>
        </v-row>
        <v-row v-if="!preview">
          <v-col cols="12" class="pl-0 comment-rating">
            <rating :content="comment" contentType="comment" :height="20" />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="comment-end-column" v-if="!preview && mostLiked">
        <v-container>
          <v-row>
            <v-col class="px-0 most-liked-image">
              <v-img src="/icons/based.png" width="28"/>
            </v-col>
            <v-col>
              <div class="most-liked-text">Most Liked</div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="comment-mod-column" v-if="!preview">
        <div class="mod-button" v-if="hover && isMod">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="deleteComment" icon :height="16" :width="16">
                <v-icon :size="16" color="red">
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Delete message</span>
          </v-tooltip>
        </div>
        <div class="mod-button" v-if="hover && isMod">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="banUser" icon :height="16" :width="16">
                <v-icon :size="16" color="red">
                  mdi-cancel
                </v-icon>
              </v-btn>
            </template>
            <span>Ban user</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
  </v-hover>
</template>
<script>
import rating from '../rating/rating.vue'

import moment from 'moment'
export default {
  name: 'Comment',
  props: ['comment', 'preview', 'separator', 'mostLiked', 'isMod'],
  components: {
    rating
  },
  data: () => ({
  }),
  computed: {
    moment () {
      return moment;
    },
    today () {
      return new moment();
    },
    daysAgo () {
      return this.today.diff(moment(this.comment.createdAt, "YYYY-MM-DD HH:mm:ss"), 'days');
    },
    monthsAgo () {
      return this.today.diff(moment(this.comment.createdAt, "YYYY-MM-DD HH:mm:ss"), 'months');
    },
    datedLabel () {
      if (this.monthsAgo > 12) {
        // years
        let years = Math.floor(this.monthsAgo / 12);
        if (years == 1) {
          return '1 Year Ago';
        }
        return years + ' Years Ago';
      } else if (this.monthsAgo > 0) {
        // months
        return this.monthsAgo + ' Months Ago';
      } else if (this.daysAgo >= 7) {
        // weeks
        let weeks = Math.floor(this.daysAgo / 7);
        if (weeks == 1) {
          return '1 Week Ago';
        }
        return weeks + ' Weeks Ago';
      } else if (this.daysAgo > 2) {
        // days
        return this.daysAgo + ' Days Ago'
      } else if (this.daysAgo > 1) {
        return 'Yesterday';
      } else {
        return 'Today';
      }
    },
    profileImage () {
      if (this.comment) {
        if (this.comment.image) {
          return this.comment.image;
        }
      }
      return "/icons/user.png";
    }
  },
  watch: {
  },
  methods: {
    onClick: function (e) {
      this.$emit('click', e);
    },
    deleteComment: function () {
      this.$emit('deleteComment', this.comment);
    },
    banUser: function () {
      this.$emit('banUser', this.comment);
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.comment-row {
  // margin-bottom: 24px;
}

.comment-image-column {
  flex-grow: 0;
  // min-width: 80px;
}
.comment-body-column {

}
.comment-end-column {
  flex-grow: 0;
  min-width: 160px;
}

.comment-name {
  color: grey;
  font-size: 16px;
}
.comment-text {
  color: white;
  font-size: 16px;
}
.comment-timespan {
  color: grey;
  font-style: italic;
  font-size: 12px;
}

.comment-rating {
  max-width: 110px;
}

.most-liked-image {
  flex-grow: 0;
}
.most-liked-text {
  font-size: 14px;
  padding-top: 3px;
}

.comment-mod-column {
  flex-grow: 0;
  min-width: 20px;
}

.mod-button {
  width: 100%;
  height: 24px;
}

.mod-button .v-btn {
  float: right;
}
</style>
