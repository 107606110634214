<template>
  <v-card class="" color="#363636" tile flat>
    <v-card-title>
      User Stats
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div class="pa-4" v-bind:style="{ 'height': this.height + 'px' }">
            <v-row>
              <v-col cols="12">
                <div class="stat-title">
                  Active Users
                </div>
                <div class="stat-number">
                  {{ data.users }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="stat-title">
                  Monthly Revenue
                </div>
                <div class="stat-number">
                  {{ data.monthlyRevenue }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="stat-title">
                  Yearly Revenue
                </div>
                <div class="stat-number">
                  {{ data.yearlyRevenue }}
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'UserStats',
  props: ['data', 'width', 'height'],
  components: {
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.stat-title {
  height: 48px;
  color: white;
  opacity: 1.0;
  text-align: center;
  font-size: 24px;
}
.stat-number {
  height: 48px;
  color: white;
  opacity: 1.0; 
  text-align: center;
  font-size: 24px;
}
</style>
