<template>
  <div class="show-group">
    <div class="title-bar">
      {{ title }}
    </div>
    <div class="show-list">
      <div class="show-container" :key ="title+'-'+item.id" v-for="item in items">
        <episode v-if="item.episodeType" :episode="item" :compact="true" :height="height"/>
        <show v-else :show="item" :height="height" :disabled="scrolling"/>
      </div>
    </div>
    <v-btn v-if="showArrows" depressed plain :ripple="false" class="left-arrow" @click="arrowLeft">
      <v-icon large>
        mdi-chevron-left
      </v-icon>
    </v-btn>
    <v-btn v-if="showArrows" depressed plain :ripple="false" class="right-arrow" @click="arrowRight">
      <v-icon large>
        mdi-chevron-right
      </v-icon>
    </v-btn>
  </div>
</template>
<script>
  import show from '../show/show'
  import episode from '../episode/episode'

  export default {
    name: 'Category',
    components: {
      show,
      episode
    },
    props: ['title', 'height', 'items'],
    data: () => ({
      scrolling: false,
      mousedown: null,
      mouseup: null,
      movemouse: null,
      mouseleave: null
    }),
    computed: {
      showArrows () {
        if (this.items.length > 7) {
          return true;
        }
        return false;
      },
    },
    methods: {
      arrowLeft: function () {
        this.scrollList(-0.8);
      },
      arrowRight: function () {
        this.scrollList(0.8);
      },
      scrollList: function (val) {
        let scrollElement = this.$el.getElementsByClassName('show-list')[0];

        val = val * scrollElement.clientWidth;

        let newLeft = scrollElement.scrollLeft + val;
        if (scrollElement.clientWidth + scrollElement.scrollLeft >= scrollElement.scrollWidth) {
          newLeft = 0;
        }

        scrollElement.scrollTo({
          top: 0,
          left: newLeft,
          behavior: 'smooth'
        })
      },
      setupEvents: function () {
        let scope = this;
        let scrollElement = this.$el.getElementsByClassName('show-list')[0];

        let x = 0;
        let scrollLeft = 0;

        scope.mousedown = function (e) {
          scrollElement.addEventListener('mousemove', scope.mousemove);
          x = e.pageX;
          scrollLeft = scrollElement.scrollLeft;
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();

        }
        scope.mouseup = function (e) {
          scrollElement.removeEventListener('mousemove', scope.mousemove);
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();

          setTimeout(function () {
            scope.scrolling = false;
          }, 50);
            
        }

        scope.mousemove = function (e) {
          let newLeft = scrollLeft - (e.pageX - x);
          scrollElement.scrollLeft = newLeft;
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          
          scope.scrolling = true;
        }

        scope.mouseleave = function (e) {
          if (e.fromElement == scrollElement) {
            scope.mouseup(e);
          }
        }

        scrollElement.addEventListener('mousedown', scope.mousedown, true);
        scrollElement.addEventListener('mouseup', scope.mouseup, true);
        scrollElement.addEventListener('mouseleave', scope.mouseleave, true);
      }
    },
    beforeDestroy () {
      let scope = this;
      let scrollElement = this.$el.getElementsByClassName('show-list')[0];

      if (scope.mousedown) {
        scrollElement.removeEventListener('mouseup', scope.mousedown, true);
      }
      
      if (scope.mouseup) {
        scrollElement.removeEventListener('mousedown', scope.mouseup, true);
      }
      
      if (scope.mousemove) {
        scrollElement.removeEventListener('mousemove', scope.mousemove, true);
      }
      
      if (scope.mouseleave) {
        scrollElement.removeEventListener('mouseleave', scope.mouseleave, true);
      }
      
    },
    mounted () {
      this.setupEvents();
    }
  }
</script>

<style lang="scss" scoped>
  .show-group {
    margin-bottom: 16px !important;
    margin-left: 32px !important;
    margin-right: 32px !important;
    position: relative;
  }
  .disrupt-mobile .show-group {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .title-bar {
    height: 40px;
    font-size: 16px;
    padding: 8px 0;
    letter-spacing: 2px;
  }
  .show-list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 2px;
  }
  .show-container {
    display: inline-block;
    min-height: 150px;
  }
  .show-container:not(:first-child) {
    padding-left: 8px;
  }

  .left-arrow,
  .right-arrow {
    position: absolute;
    height: calc(100% - 40px) !important;
    top: 40px;

    border-radius: 0px !important;
    box-shadow: none;

    background: rgb(0,0,0);
    background-color: initial !important;
  }
  .left-arrow {
    padding-right: 80px !important;
    padding-left: 8px !important;
    background: linear-gradient(90deg, rgba(32,32,32,0.75) 0%, rgba(32,32,32,0.6) 48%, rgba(32,32,32,0) 100%);
  }
  .right-arrow {
    right: 0px;
    padding-right: 8px !important;
    padding-left: 80px !important;
    background: linear-gradient(90deg, rgba(32,32,32,0) 0%, rgba(32,32,32,0.6) 52%, rgba(32,32,32,0.75) 100%);
  }
</style>
