<template>
  <v-container>
    <v-row justify="center">
      <v-col class="text-center display-1">
        <div>Dashboard</div>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="analytics">
      <v-col cols="4">
        <userStats :data="analytics.userStats" :height="250" />
      </v-col>
      <v-col cols="4">
        <userPie :data="analytics.userPie" :height="250" />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="analytics">
      <v-col cols="4">
        <loginGraph :data="analytics.loginGraph" :height="250" />
      </v-col>
      <v-col cols="4">
        <userGraph :data="analytics.userGraph" :height="250" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import { Api } from '../../../js/api';

import userPie from '../analytics/userPie';
import userGraph from '../analytics/userGraph';
import loginGraph from '../analytics/loginGraph';
import userStats from '../analytics/userStats';
export default {
  name: 'Dashboard',
  props: [],
  components: {
    userPie,
    userGraph,
    loginGraph,
    userStats
  },
  data: () => ({
    analytics: null
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    getAnalyticsData: function () {
      let scope = this;
      Api.get('AdminDashboard/analytics').then(function (res) {
        let data = res.data;

        let formattedData = {
          userGraph: [
            ['x', ...data.userGraph.dates],
            ['paypal', ...data.userGraph.paypal],
            ['stripe', ...data.userGraph.stripe],
            ['coupon', ...data.userGraph.coupon],
            ['free', ...data.userGraph.free],
          ],
          userPie: [
            ['paypal', data.userPie.paypal],
            ['stripe', data.userPie.stripe],
            ['coupon', data.userPie.coupon],
            ['free',  data.userPie.free]
          ],
          loginGraph: [
            ['x', ...data.loginGraph.dates],
            ['logins', ...data.loginGraph.logins],
          ],
          userStats: {
            users: data.userStats.users,
            monthlyRevenue: '$' + data.userStats.monthlyRevenue.toFixed(2),
            yearlyRevenue: '$' + data.userStats.yearlyRevenue.toFixed(2)
          }
        }
        scope.analytics = formattedData
      })

      // this.analytics = {
      //   userGraph: [
      //     ['x', '2021-05-01T00:00:00', '2021-05-02T00:00:00', '2021-05-03T00:00:00', '2021-05-04T00:00:00', '2021-05-05T00:00:00', '2021-05-06T00:00:00', '2021-05-07T00:00:00', '2021-05-08T00:00:00'],
      //     ['paypal', 1, 6, 8, 12, 11, 11, 16, 18],
      //     ['stripe', 0, 1, 5, 5, 4, 5, 6, 7],
      //     ['coupon', 1, 2, 6, 7, 8, 9, 11, 16],
      //     ['free', 10, 12, 25, 35, 40, 50, 65, 76]
      //   ],
      //   userPie: [
      //     ['paypal', 11],
      //     ['stripe', 4],
      //     ['coupon', 8],
      //     ['free', 25]
      //   ],
      //   loginGraph: [
      //     ['x', '2021-05-01T00:00:00', '2021-05-02T00:00:00', '2021-05-03T00:00:00', '2021-05-04T00:00:00', '2021-05-05T00:00:00', '2021-05-06T00:00:00', '2021-05-07T00:00:00', '2021-05-08T00:00:00'],
      //     ['logins', 1, 6, 8, 12, 11, 5, 6, 8],
      //   ],
      //   userStats: {
      //     users: 1415,
      //     revenue: '$849'
      //   }
      // }
    }
  },
  mounted () {
    this.getAnalyticsData();
  }
}
</script>
<style lang="scss" scoped>
.income-card,
.analytics-card {
  height: 300px;
}
.income-text {
  font-size: 24px;
  line-height: normal;
}
.income-number {
  font-size: 64px;
  line-height: normal;
}
</style>
