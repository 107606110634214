<template>
  <v-dialog v-model="dialog" width="600" content-class="delete-category-dialog">
    <v-card>
      <v-card-title>
        Delete Category?
      </v-card-title>
      <v-card-text class="delete-category-body">
        Are you sure you want to delete this category?
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-btn tile class="cancel-button" @click="cancel()">
          Cancel
        </v-btn>
        <v-spacer/>
        <v-btn tile class="delete-button" @click="deleteCategory()" color="primary">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { EventBus } from '../../../eventBus.js';
import { Api } from '../../../js/api';

export default {
  name: 'DeleteCategoryDialog',
  components: {
  },
  data: () => ({
    dialog: false,
    category: null
  }),
  computed: {
  },
  methods: {
    cancel: function () {
      this.dialog = false;
    },
    deleteCategory: function () {
      let scope = this;

      let data = {
        id: this.category.id
      };

      Api.post('AdminCategory/deleteCategory', data).then(function (res) {
        scope.dialog = false;

        if (res.valid) {
          EventBus.$emit('alert', {
            text: 'Category deleted',
            alertColor: 'green',
            alertIcon: 'success'
          })
        }

        scope.$emit('complete');
      })
    }
  },
  mounted () { let scope = this
    EventBus.$on('deleteCategoryDialog', function (category) {
      scope.category = category;
      scope.dialog = true;
    })
  }
}
</script>
<style lang="scss">
.delete-category-dialog,
.delete-category-dialog .v-card {
  background-color: #202020 !important;
  border-radius: 0px !important;
}
.delete-category-body {
  height: 200px;
}
</style>