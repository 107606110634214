import Vue from 'vue'
import Vuex from 'vuex'
import Vuetify from '../plugins/vuetify'
import Cookies from 'js-cookie'

Vue.use(Vuex)
import { Api } from '../js/api'
import * as signalR from "@microsoft/signalr";

export default new Vuex.Store({
  state: {
    loaded: false,
    browser: '',
    platform: '',
    browserDetect: null,
    errors: [],
    maintenance: true,
    user: null,
    legacy: true,
    chatConnected: false,
    chatSocket: null,
    chatMessages: [],
    isLoggedIn: false,
    carouselPages: [],
    livestream: null,
    shows: [],
    showEpisodes: {},
    episodes: [],
    categories: [],
    userViews: [],
    comments: [],
    mostLikedComment: null,
    videoSuggestions: [],
    contentPlaying: false,
    popoutOpen: false,
    popoutMinimised: false,
    playerEpisodeId: null,
    playingEpisode: null,
    playingShow: null,
    showDialog: false,
    captchaResponse: null
  },
  getters: {
    getLoaded: state => {
      return state.loaded;
    },
    getErrors: state => {
      return state.errors;
    },
    /* eslint-disable */
    getMaintenance: state => {
      return MAINTENANCE; // state.maintenance;
      /* eslint-enable */
    },
    /* eslint-disable */
    getCookie: state => key => {
      /* eslint-enable */
      return Cookies.get(key);
    },
    getIsLoggedIn: state => {
      return state.isLoggedIn;
    },
    getUser: state => {
      return state.user;
    },
    getlegacy: state => {
      return state.legacy;
    },
    getCarouselPages: state => {
      return state.carouselPages;
    },
    getShow: state => id => {
      return state.shows.find(s => s.id == id);
    },
    getShows: state => {
      return state.shows;
    },
    getInvestorShows: state => {
      return state.shows.filter(s => s.shareholdersOnly);
    },
    getEpisodes: state => showId => {
      if (state.showEpisodes[showId]) {
        return state.showEpisodes[showId];
      }
      return [];
    },
    getEpisode: state => id => {
      return state.episodes.find(e => e.id == id);
    },
    getCategories: state => {
      return state.categories;
    },
    getUserViews: state => {
      return state.userViews;
    },
    getEpisodeView: state => id => {
      return state.userViews.find(v => v.episodeId == id);
    },
    getChatConnected: state => {
      return state.chatConnected;
    },
    getChatSocket: state => {
      return state.chatSocket;
    },
    getChatMessages: state => {
      return state.chatMessages;
    },
    getLivestream: state => {
      return state.livestream;
    },
    getComments: state => {
      return state.comments;
    },
    getMostLikedComment: state => {
      return state.mostLikedComment;     
    },
    hasKey: state => key => {
      if (state.user) {
        if (state.user.keys) {
          if (state.user.keys.includes(key)) {
            return true;
          }
        }
      }
      return false;
    },
    getIsMobile: () => {
      if (Vuetify.framework.breakpoint.xs) {
        return true;
      }
      if (Vuetify.framework.breakpoint.sm) {
        return true;
      }
      return false;
    },
    getPlatform: state => {
      return state.platform;
    },
    getBrowser: state => {
      return state.browser;
    },
    /* eslint-disable */
    breakpoint: state => {
      /* eslint-enable */
      if (Vuetify.framework.breakpoint.xs) {
        return 'xs';
      }
      if (Vuetify.framework.breakpoint.sm) {
        return 'sm';
      }
      if (Vuetify.framework.breakpoint.md) {
        return 'md';
      }
      if (Vuetify.framework.breakpoint.lg) {
        return 'lg';
      }
      if (Vuetify.framework.breakpoint.xl) {
        return 'xl';
      }
      return ''
    },
    getVideoSuggestions: state => {
      return state.videoSuggestions;
    },
    getIsContentPlaying: state => {
      return state.contentPlaying;
    },
    getPopoutOpen: state => {
      return state.popoutOpen;
    },
    getPopoutMinimised: state => {
      return state.popoutMinimised;
    },
    getPlayerEpisodeId: state => {
      return state.playerEpisodeId;
    },
    getBrowserDetect: state => {
      return state.browserDetect;
    },
    getPlayingEpisode: state => {
      return state.playingEpisode;
    },
    getPlayingShow: state => {
      return state.playingShow;
    },
    getShowDialog: state => {
      return state.showDialog;
    },
    getCaptchaResponse: state => {
      return state.captchaResponse;
    }
  },
  mutations: {
    addError(state, error) {
      state.errors.push(error);
    },
    setIsLoggedIn (state, val) {
      state.isLoggedIn = val;
    },
    setUser (state, user) {
      if (user) {
        state.user = user;
      } else {
        state.user = { role: '' }
      }
    },
    setLoaded (state, val) {
      state.loaded = val;
    },
    setCookie(state, data) {
      Cookies.set(data.key, data.val);
    },
    setCarouselPages (state, val) {
      state.carouselPages = val;
    },
    setShows (state, val) {
      state.shows = val;
    },
    addShows (state, shows) {
      shows.forEach(show => {
        state.shows.push(show)
      })
    },
    setShow (state, val) {
      let show = state.shows.find(s => s.id == val.id);
      if (show) {
        Object.assign(show, val);
      } else {
        state.shows.push(val);
      }
    },
    setEpisodes (state, data) {
      state.showEpisodes[data.showId] = data.episodes;
    },
    setEpisode (state, val) {
      let episode = state.episodes.find(e => e.id == val.id);
      if (episode) {
        Object.assign(episode, val);
      } else {
        state.episodes.push(val);
      }
    },
    setCategories (state, val) {
      state.categories = val;
    },
    setUserViews (state, val) {
      state.userViews = val;
    },
    setUserView (state, val) {
      let view = state.userViews.find(v => v.id == val.id);
      if (view) {
        Object.assign(view, val);
      } else {
        state.userViews.push(val);
      }
    },
    setChatConnected (state, val) {
      state.chatConnected = val;
    },
    setChatSocket (state, val) {
      state.chatSocket = val;
    },
    setChatMessages (state, val) {
      state.chatMessages = val;
    },
    setUserBanned (state, val) {
      let messagesToRemove = state.chatMessages.filter(m => m.username == val);
      for(let m = 0; m < messagesToRemove.length; m++) {
        messagesToRemove[m].removed = true;
        messagesToRemove[m].text = "Message deleted by a moderator";
      }
    },
    insertChatMessages (state, val) {
      state.chatMessages = state.chatMessages.concat(val);
    },
    addChatMessage (state, val) {
      state.chatMessages.unshift(val);
    },
    setChatMessage (state, val) {
      let message = state.chatMessages.find(m => m.id == val.id);
      if (message != null) {
        Object.assign(message, val);
      }
    },
    setLivestream (state, val) {
      state.livestream = val;
    },
    setComments (state, comments) {
      state.comments = comments;
    },
    appendComments (state, comments) {
      state.comments = state.comments.concat(comments);
    },
    removeComment(state, comment) {
      let found = state.comments.find(c => c.id == comment.id);
      if (found != null) {
        state.comments.splice(state.comments.indexOf(found), 1);
      }
    },
    setMostLikedComment (state, comment) {
      state.mostLikedComment = comment;
    },
    setVideoSuggestions(state, suggestions) {
      state.videoSuggestions = suggestions;
    },
    setContentPlaying(state, value) {
      state.contentPlaying = value;
    },
    setPopoutOpen(state, value) {
      state.popoutOpen = value;
    },
    setPopoutMinimised (state, value) {
      state.popoutMinimised = value;
    },
    setPlayerEpisodeId (state, value) {
      state.playerEpisodeId = value;
    },
    setPlatform (state, value) {
      state.platform = value;
    },
    setBrowser (state, value) {
      state.browser = value;
    },
    setBrowserDetect (state, value) {
      state.browserDetect = value;
    },
    setPlayingEpisode (state, value) {
      state.playingEpisode = value;
    },
    setPlayingShow (state, value) {
      state.playingShow = value;
    },
    setShowDialog (state, value) {
      state.showDialog = value;
    },
    setCaptchaResponse(state, value) {
      state.captchaResponse = value;
    }
  },
  actions: {
    browserDetect (context, browserDetect) {
      context.commit('setBrowserDetect', browserDetect);

      // platform
      if (browserDetect.isIOS) {
        context.commit('setPlatform', 'iOS');
      } else {
        if (context.getters.getIsMobile) {
          context.commit('setPlatform', 'android'); 
        } else {
          context.commit('setPlatform', 'desktop');
        }
      }

      // browser
      let checks = [
        "isOpera",
        "isEdge",
        "isFirefox",
        "isSafari",
        "isIE",
        "isChrome",
        "isChromeIOS",
        "isBrave"]
  
      let browsers = [
        "opera",
        "edge",
        "firefox",
        "safari",
        "IE",
        "chrome",
        "chromeIOS",
        "brave"]
  
      let browserFound = false;

      checks.forEach(function (c, i) {
        if(browserDetect[c]) {
          context.commit('setBrowser', browsers[i]);
          browserFound = true;
          return;
        }
      })

      if (!browserFound) {
        if (browserDetect.meta.name.toLowerCase() == 'safari') {
          context.commit('setBrowser', 'safari');
        }
      }
    },
    updateLoggedIn (context) {
      return new Promise((resolve) => {
        let auth = Cookies.get('auth_cookie');
        let loggedIn = auth != null && auth != "";
        context.commit('setIsLoggedIn', loggedIn);
        resolve(loggedIn);
      })
    },
    clearLoggedIn(context) {
      return new Promise((resolve) => {
        Cookies.set('auth_cookie', "");
        context.commit('setIsLoggedIn', false);
        resolve();
      })
    },
    getUser (context) {
      return new Promise((resolve, reject) => {
        Api.get('User').then(function (res) {
          if (res.valid) {
            context.commit('setUser', res.data);
            resolve(res.data);
          } else {
            context.commit('setUser', null);
            resolve(null);
          }
        }, function () {
          context.commit('setUser', null);
          reject(null);
        });
      })
    },
    hasKey (context, key) {
      return new Promise((resolve) => {
        context.dispatch('updateLoggedIn').then(function (res) {
          if (res) {
            if (context.getters.getUser) {
              resolve(context.getters.hasKey(key));
            } else {
              context.dispatch('getUser').then(function () {
                resolve(context.getters.hasKey(key));
              })
            }
          } else {
            resolve(false);
          }
        })
      });
    },
    setLoaded (context, val) {
      context.commit('setLoaded', val)
    },
    getHomeData: function (context) {
      return new Promise((resolve) => {
        Api.get('Content/home').then(function (res) {
          if (res.valid) {
            context.commit('setCarouselPages', res.data.carouselPages);
            context.commit('setShows', res.data.shows);
            context.commit('setCategories', res.data.categories);
            resolve();
          } else {
            resolve(null);
          }
        })
      })
    },
    getInvestorShows: function (context) {
      return new Promise((resolve) => {
        Api.get('Investor/shows').then(function (res) {
          if (res.valid) {
            context.commit('addShows', res.data);
            resolve();
          } else {
            resolve(null);
          }
        })
      })
    },
    getUserViews (context) {
      return new Promise((resolve) => {
        Api.get('View/all').then(function (res) {
          if (res.valid) {
            context.commit('setUserViews', res.data);
            resolve();
          } else {
            resolve(null);
          }
        });
      })
    },
    clearUserViews (context) {
      return new Promise((resolve) => {
        Api.post('View/clear').then(function () {
          context.commit('setUserViews', []);
          resolve();
        })
      })
    },
    getShow(context, showId) {
      return new Promise((resolve) => {
        let show = context.getters.getShow(showId);
        if (show) {
          resolve(show);
          return;
        }
        Api.get('Content/show/' + showId).then(function (res) {
          if (res.valid) {
            context.commit('setShow', res.data);
            resolve(res.data)
          } else {
            resolve(null);
          }
        })
      })
    },
    getEpisodes(context, showId) {
      return new Promise((resolve) => {
        let episodes = context.getters.getEpisodes(showId);
        if (episodes && episodes.length > 0) {
          resolve(episodes);
          return;
        }
        Api.get('Content/episodes/' + showId).then(function (res) {
          if (res.valid) {
            context.commit('setEpisodes', { showId: showId, episodes: res.data });
            resolve(res.data)
          } else {
            resolve(null);
          }
        })
      })
    },
    getEpisode(context, episodeId) {
      return new Promise((resolve) => {
        let episode = context.getters.getEpisode(episodeId);
        if (episode) {
          resolve(episode);
          return;
        }
        Api.get('Content/episode/' + episodeId).then(function (res) {
          if (res.valid) {
            context.commit('setEpisode', res.data);
            resolve(res.data)
          } else {
            resolve(null);
          }
        })
      })
    },
    getEpisodeView(context, episodeId) {
      return new Promise((resolve) => {
        let episodeView = context.getters.getEpisodeView(episodeId);
        if (episodeView) {
          resolve(episodeView);
          return;
        }
        Api.get('View/' + episodeId).then(function (res) {
          if (res.valid && res.data) {
            context.commit('setUserView', res.data);
            resolve(res.data)
          } else {
            resolve(null);
          }
        })
      })
    },
    connectChat (context) {
      let url = process.env.VUE_APP_WSS_URL + "chatHub/";
      let connection = new signalR.HubConnectionBuilder()
        .withUrl(url)
        .build();

      context.commit('setChatSocket', connection);

      connection.on('open', function() {
        console.log('open');
      });
       
      connection.on('message', function(event) {
        let response = JSON.parse(event);
        context.commit('addChatMessage', response.data);
      });

      connection.on('updated', function(event) {
        let response = JSON.parse(event);
        context.commit('setChatMessage', response.data);
      })

      connection.on('banned', function(event) {
        let response = JSON.parse(event);
        context.commit('setUserBanned', response.data);
      })

      connection.on('streamchanged', function (event) {
        let response = JSON.parse(event);
        context.commit('setLivestream', response.data);
      })

      connection.start().then(function () {
        context.commit('setChatConnected', true);
      });
    },
    getChatMessages (context) {
      return new Promise((resolve) => {
        Api.get('Chat/all').then(function (res) {
          if (res.valid) {
            context.commit('setChatMessages', res.data);
            resolve(res.data)
          } else {
            resolve(null);
          }
        })
      })
    },
    disconnectChat (context) {
      let connection = context.getters.getChatSocket;
      if (connection) {
        context.commit('setChatSocket', null);
        if (connection.close) {
          connection.close();
        }
      }
    },
    getMoreChatMessages (context, data) {
      return new Promise((resolve) => {
        Api.get('Chat/' + data.offset + '/' + data.amount).then(function (res) {
          if (res.valid) {
            context.commit('insertChatMessages', res.data);
            resolve(res.data)
          } else {
            resolve(null);
          }
        })
      })
    },
    getLivestream (context) {
      return new Promise((resolve) => {
        Api.get('Content/livestream').then(function (res) {
          if (res.valid) {
            context.commit('setLivestream', res.data);
            resolve(res.data)
          } else {
            resolve(null);
          }
        })
      })
    },
    subscribe (context, data) {
      return new Promise((resolve) => {
        Api.post('Subscription/subscribe', data).then(function (res) {
          resolve(res);
        })
      })
    },
    confirmPaypal (context, data) {
      return new Promise((resolve) => {
        Api.post('Subscription/confirmPaypal', data).then(function (res) {
          resolve(res);
        })
      })
    },
    canRedeemReward () {
      return new Promise((resolve) => {
        Api.get('Rewards/canRedeemReward').then(function (res) {
          resolve(res);
        })
      })
    },
    getReward () {
      return new Promise((resolve) => {
        Api.post('Rewards/getReward').then(function (res) {
          resolve(res);
        })
      })
    },
    getLastReward () {
      return new Promise((resolve) => {
        Api.get('Rewards/getLastReward').then(function (res) {
          resolve(res);
        })
      })
    },
    getNotificationSettings () {
      return new Promise((resolve) => {
        Api.get('Notification/settings').then(function (res) {
          resolve(res.data);
        })
      })
    },
    saveNotificationSettings (context, settings) {
      return new Promise((resolve) => {
        Api.post('Notification/settings/update', settings).then(function (res) {
          resolve(res.data);
        })
      })
    },
    logError (context, error) {
      return new Promise((resolve) => {
        context.commit('addError', error);
        resolve(error);
      })
    },
    getEpisodeComments (context, episodeId) {
      return new Promise((resolve) => {
        Api.get('Comment/episode/' + episodeId).then(function (res) {
          context.commit('setComments', res.data.comments);
          context.commit('setMostLikedComment', res.data.mostLikedComment);
          resolve(res.data);
        })
      })
    },
    getForumComments (context, forumId) {
      return new Promise((resolve) => {
        Api.get('Comment/forum/' + forumId).then(function (res) {
          context.commit('setComments', res.data.comments);
          context.commit('setMostLikedComment', res.data.mostLikedComment);
          resolve(res.data);
        })
      })
    },
    getMoreEpisodeComments (context, data) {
      return new Promise((resolve) => {
        Api.get('Comment/episode/' + data.episodeId + '/' + data.skip).then(function (res) {
          context.commit('appendComments', res.data);
          resolve(res.data);
        })
      })
    },
    getMoreForumComments (context, data) {
      return new Promise((resolve) => {
        Api.get('Comment/forum/' + data.forumId + '/' + data.skip).then(function (res) {
          context.commit('appendComments', res.data);
          resolve(res.data);
        })
      })
    },
    sendComment (context, data) {
      return new Promise((resolve) => {
        Api.post('Comment', data).then(function (res) {
          resolve(res.data);
        })
      })
    },
    rateEpisode (context, data) {
      return new Promise((resolve) => {
        Api.post('Rating/rateEpisode', data).then(function (res) {
          resolve(res.data);
        })
      })
    },
    rateComment (context, data) {
      return new Promise((resolve) => {
        Api.post('Rating/rateComment', data).then(function (res) {
          resolve(res.data);
        })
      })
    },
    getVideoSuggestions(context, data) {
      return new Promise((resolve) => {
        Api.post('Suggestion', data).then(function (res) {
          context.commit('setVideoSuggestions', res.data.episodes);
          resolve(res.data);
        })
      })
    }
  },
  modules: {
  }
})
