<template>
  <v-container fill-height>
    <v-row class="episode-header">
      <v-col class="text-center display-1">
        <div>{{ header }}</div>
      </v-col>
    </v-row>
    <v-row class="episode-row" v-if="episode">
      <v-col cols="8">
        <v-card>
          <v-card-title>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap class="px-1">
              <v-flex xs4 class="px-2">
                <v-select label="Content Type" v-model="episode.episodeType" :items="episodeTypes" :disabled="!isNew || saving">
                </v-select>
              </v-flex>
              <v-flex xs4></v-flex>
              <v-flex xs4>
                <v-switch v-model="episode.singular" label="Singular Episode" :disabled="saving" class="px-6"></v-switch>
              </v-flex>
            </v-layout>
            <v-text-field label="Name" placeholder="Name" v-model="episode.name" :disabled="saving"></v-text-field>
            <v-text-field label="Description" placeholder="Description" v-model="episode.description" :disabled="saving"></v-text-field>
            <v-layout row wrap class="px-1">
              <v-flex xs3 class="px-2">
                <v-text-field label="Season" placeholder="Season Number" v-model="episode.seasonNumber" :disabled="saving"></v-text-field>
              </v-flex>
              <v-flex xs3 class="px-2">
                <v-text-field label="Episode" placeholder="Episode Number" v-model="episode.episodeNumber" :disabled="saving"></v-text-field>
              </v-flex>
              <v-flex xs2></v-flex>
              <v-flex xs4 class="px-2">
                <v-text-field label="Runtime (seconds)" placeholder="Runtime (seconds)" v-model="episode.runtime" :disabled="saving"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="px-1">
              <v-flex xs4 class="px-2">
                <v-text-field label="Bucket" placeholder="Bucket" v-model="episode.bucket" :disabled="saving"></v-text-field>
              </v-flex>
              <v-flex xs8 class="px-2">
                <v-text-field label="Path" placeholder="Key" v-model="episode.key" :disabled="saving"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout v-for="episodeKey in episode.episodeKeys" :key="episodeKey.id">
              <v-flex xs3 class="pr-2">
                <v-text-field label="quality" v-model="episodeKey.quality" />
              </v-flex>
              <v-flex xs8>
                <v-text-field label="key" v-model="episodeKey.key" />
              </v-flex>
              <v-flex xs1>
                <v-btn tile icon @click="deleteVersion(episodeKey)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-btn tile class="mr-2" @click="addQualityVersion">
              Add version
            </v-btn>
            <v-btn tile class="mr-2"  @click="createDefaults(3)">
              Create Defaults (1080)
            </v-btn>
            <v-btn tile @click="createDefaults(2)">
              Create Defaults (720)
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn tile @click="cancel" :disabled="saving">
              Cancel
            </v-btn>
            <v-btn tile @click="deleteEpisode" :disabled="saving">
              Delete
            </v-btn>
            <v-spacer></v-spacer>
            <v-switch v-model="episode.isPublic" label="Public" :disabled="saving" class="px-6"></v-switch>
            <v-switch v-model="episode.free" label="Free" :disabled="saving" class="px-6"></v-switch>
            <v-btn tile color="primary" @click="save" :disabled="saving">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-row>
          <v-col cols="12">
            <episode :episode="episode" :height="100" @click="function(){}"/>
          </v-col>
        </v-row>
        <v-divider class="pb-6"/>
        <v-row>
          <v-col cols="12">
            <episode :episode="episode" :height="100" :compact="true" @click="function(){}" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <imageUploader ref="showImage" v-model="episode.imageId" :url="episode.image" :disabled="saving" placeholder="/icons/upload-image.png" @change="updateImage" :width="300" :height="168" :aspect="16/9"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <deleteContentDialog endpoint="AdminEpisode/deleteEpisode" @complete="cancel"/>
  </v-container>
</template>
<script>
import { EventBus } from '../../../eventBus';
import { Api } from '../../../js/api';

import deleteContentDialog from './deleteContentDialog';

import episode from '../../content/episode/episode';

import imageUploader from '../../global/imageUploader';

export default {
  name: 'Episode',
  props: [],
  components: {
    deleteContentDialog,
    episode,
    imageUploader
  },
  data: () => ({
    loading: false,
    episode: null,
    episodeTypes: [ 'video', 'audio'],
    saving: false
  }),
  computed: {
    showId () {
      return this.$route.params.showId
    },
    episodeId () {
      return this.$route.params.id
    },
    header () {
      if (this.isNew) {
        return 'Create a new episode';
      }
      return 'Edit episode';
    },
    isNew () {
      return this.episodeId == 'new';
    },
    episodeType () {
      if (this.episode) {
        return this.episode.episodeType;
      }
      return null;
    }
  },
  watch: {
  },
  methods: {
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    updateImage: function (image) {
      this.episode.image = image;
    },
    cancel: function () {
      this.navigate('/admin/episodes/' + this.showId);
    },
    deleteEpisode: function () {
      EventBus.$emit('deleteContentDialog', this.episode);
    },
    save: function () {
      let self = this;
      self.saving = true;

      if (self.$refs.showImage.file) {
        // upload the file first
        self.$refs.showImage.upload().then(function () {
          self.saveEpisode();
        })
      } else {
        self.saveEpisode();
      }
    },
    saveEpisode: function () {
      let scope = this
      let data = scope.episode;
      data.showId = scope.showId;

      if (!scope.isNew) {
        let endpoint = '';
        if (scope.episodeType == 'video') {
          endpoint = 'AdminEpisode/updateVideoEpisode';
        }
        if (scope.episodeType == 'audio') {
          endpoint = 'AdminEpisode/updateAudioEpisode';
        }
        
        Api.post(endpoint, data).then(function (res) {
          scope.saving = false;

          if(res.valid) {
            EventBus.$emit('alert', {
              text: 'Episode updated',
              alertColor: 'green',
              alertIcon: 'success'
            })
          }

          scope.navigate('/admin/episodes');
        })
      } else {
        let endpoint = '';
        if (scope.episodeType == 'video') {
          endpoint = 'AdminEpisode/createVideoEpisode';
        }
        if (scope.episodeType == 'audio') {
          endpoint = 'AdminEpisode/createAudioEpisode';
        }
        
        Api.post(endpoint, data).then(function (res) {
          scope.saving = false;

          if(res.valid) {
            EventBus.$emit('alert', {
              text: 'Episode created',
              alertColor: 'green',
              alertIcon: 'success'
            })
          }

          scope.navigate('/admin/episodes');
        })
      }
    },
    addQualityVersion: function () {
      if (!this.episode.episodeKeys) {
        this.episode.episodeKeys = [];
      }
      this.episode.episodeKeys.push({
        quality: '',
        key: ''
      })
    },
    createDefaults: function (defaultCount) {
      if (this.episodeType == 'video') {
        this.episode.episodeKeys = [];
        let qualities = [
          {
            width: '1920',
            height: '1080',
            label: '-1920x1080'
          },
          {
            width: '1280',
            height: '720',
            label: '-1280x720'
          },
          {
            width: '854',
            height: '480',
            label: '-854x480'
          },
        ]
        qualities.splice(0, 3 - defaultCount);
        
        let key = this.episode.key.split('.')[0]

        let parts = key.split('/');
        let epi = parts[1];

        let extension = 'mp4'
        for(let q = 0; q < qualities.length; q ++) {
          let newKey = 'Converted/' + epi + qualities[q].label + '.' + extension;
          // if (q == 0) {
          //   newKey = epi + '.' + extension;
          // }

          this.episode.episodeKeys.push({
            quality: qualities[q].height,
            key: newKey
          })
        }
        
        this.episode.episodeKeys.push({
          quality: 'audio',
          key: 'Converted/' + epi + '-Audio' + '.' + extension
        })
        this.episode.episodeKeys.push({
          quality: 'mpd',
          key: 'Converted/' + epi + '.mpd'
        })

      } else if (this.episodeType == 'audio') {
        this.episode.episodeKeys = [];
      }
    },
    deleteVersion: function (episodeKey) {
      this.episode.episodeKeys.splice(this.episode.episodeKeys.indexOf(episodeKey), 1);
    }
  },
  mounted () {
    let scope = this;
    if (!scope.isNew) {
      scope.loading = true;
      Api.get('AdminEpisode/getEpisode/' + scope.episodeId).then(function (res) {
        scope.episode = res.data;
        scope.loading = false;

        // scope.updateImage(res.data.image);
      })
    } else {
      scope.loading = false
      scope.episode =  {
        id: null,
        episodeType: 'video',
        name: 'Episode Title',
        description: 'Episode Description',
        runtime: 30,
        image: '',
        seasonNumber: 1,
        episodeNumber: 1,
        bucket: '',
        key: '',
        isPublic: true,
        free: false,
        singular: false,
        episodeKeys: []
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.episode-header {
  height: 64px;
}
.episode-row {
  height: calc(100% - 64px);
}

.episode-title {
  font-size: 16px;
}
.episode-duration {
  font-size: 10px;
  color: grey;
}
.episode-description {
  font-size: 12px;
}
</style>
