<template>
  <v-container fill-height>
    <v-row class="episodes-header">
      <v-col class="text-center display-1">
        <div>{{ header }}</div>
      </v-col>
    </v-row>
    <v-row class="shows-list pa-4" v-if="show == null">
      <div class="show ma-4 cursor-pointer" v-for="(show, n) in shows" :key="show.id" @click="showClicked(show)">
        <v-img
          :src="show.image"
          :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
          :aspect-ratio="aspect"
          :height="height"
          :max-height="height"
          :width="width"
          :max-width="width"
          class="grey lighten-2"/>
        <div class="pa-2">
          {{ show.title }}
        </div>
      </div>
    </v-row>
    <v-row class="episode-list pa-4"  v-else>
      <v-col cols="12" class="season-cell">
        <v-row>
          <v-col cols="2">
            <seasonSwitcher v-model="season" :seasons="seasons"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="filtered-episodes">
        <v-row>
          <div class="episode ma-4 new-episode cursor-pointer" @click="createNewEpisode">
            <v-img
              :src="'/icons/new-show.png'"
              :aspect-ratio="aspect"
              :height="height"
              :max-height="height"
              :width="width"
              :max-width="width"/> 
          </div>
          <div class="episode ma-4 cursor-pointer" v-for="(episode, n) in filteredEpisodes" :key="episode.id">
            <router-link :to="'/admin/episode/' + id + '/' + episode.id" class="episode-link">
              <v-img
                :src="getImage(episode)"
                :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                :aspect-ratio="aspect"
                :height="height"
                :max-height="height"
                :width="width"
                :max-width="width"
                class="grey lighten-2"/>
              <div class="pa-2 white--text">
                {{ episode.name }} - S{{ episode.seasonNumber }} E{{ episode.episodeNumber }}
              </div>
            </router-link>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Api } from '../../../js/api';

import seasonSwitcher from '../../global/seasonSwitcher';
export default {
  name: 'Episodes',
  props: [],
  components: {
    seasonSwitcher
  },
  data: () => ({
    shows: [],
    show: null,
    episodes: [],
    height: 260,
    aspect: 16/9,
    season: 1
  }),
  computed: {
    width () {
      return this.aspect * this.height;
    },
    videoImage () {
      return '/icons/video-placeholder.png';
    },
    audioImage () {
      return '/icons/audio-placeholder.png';
    },
    header () {
      if (this.show) {
        return this.show.title + ' Episodes';
      }
      return 'Select a show';
    },
    id () {
      return this.$route.params.id;
    },
    seasonCount () {
      if (this.show && this.episodes) {
        return Math.max(...this.episodes.map(e => e.seasonNumber));
      }
      return 0;
    },
    seasons () {
      let s = []
      for(let i = 1; i <= this.seasonCount; i++) {
        s.push({
          n: i,
          name: "Season " + i
        });
      }
      return s
    },
    filteredEpisodes () {
      if (this.show) {
        return this.episodes.filter(e => e.seasonNumber == this.season)
      }
      return []
    }
  },
  watch: {
    id: function () {
      this.detectFlow();
    }
  },
  methods: {
    getImage: function (episode) {
      if (episode.image) {
        return episode.image;
      }
      if (episode.episodeType == 'video') {
        return this.videoImage;
      }
      if (episode.episodeType == 'audio') {
        return this.audioImage;
      }
      return '';
    },
    getShows: function () {
      let self = this

      self.loading = true;
      Api.get('AdminShow/all').then(function (res) {
        self.shows = res.data;
        self.loading = false;
      })
    },
    getShow: function (showId) {
      let self = this;

      return new Promise((resolve) => {
        self.loading = true;
        Api.get('AdminShow/getShow/' + showId).then(function (res) {
          self.show = res.data;
          resolve(self.show);
        })
      });
    },
    getEpisodes: function (id) {
      let self = this

      self.loading = true;
      Api.get('AdminEpisode/' + id).then(function (res) {
        self.episodes = res.data;
        self.loading = false;
      })
    },
    showClicked: function (show) {
      this.$router.push('episodes/' + show.id);
      this.viewShow(show.id);
    },
    viewShow: function (id) {
      let self = this;
      self.getShow(id).then(function () {
        self.getEpisodes(id);
      });
    },
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    editEpisode: function (episode) {
      this.navigate('/admin/episode/' + this.id + '/' + episode.id);
    },
    createNewEpisode: function () {
      this.navigate('/admin/episode/' + this.id + '/new');
    },
    detectFlow: function () {
      if (this.id) {
        this.viewShow(this.id)
      } else {
        this.show = null;
        this.getShows();
      }
    }
  },
  mounted () {
    this.detectFlow();
  }
}
</script>
<style lang="scss" scoped>
.episodes-header {
  height: 64px;
}
.shows-list {
  height: calc(100% - 64px);
  overflow-y: auto;
}
.show {
  height: 300px;
}

.season-cell {
  height: 64px;
}
.filtered-episodes {
  height: calc(100% - 64px);
}

.episode-list {
  height: calc(100% - 64px);
  overflow-y: auto;
}
.episode {
  height: 300px;
}

.episode-link {
  text-decoration: none;
}
</style>
