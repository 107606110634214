<template>
  <v-row class="comment-messagebox-row" @click="onClick">
    <v-col class="comment-image-column">
      <v-avatar>
        <v-img :src="profileImage"></v-img>
      </v-avatar>
    </v-col>
    <v-col class="comment-body-column pl-0 pr-5">
      <v-layout class="message-box">
        <v-flex class="message-text-container">
          <v-text-field :height="48" class="message-field elevation-0" flat dense solo :placeholder="chatPlaceHolder" :disabled="!canComment" @focus="focusCommentBox" v-model="message"/>
          <!-- <v-textarea class="" :placeholder="chatPlaceholder" solo v-model="message" flat no-resize :height="'100%'"></v-textarea> -->
        </v-flex>
        <v-flex class="send-button-container">
          <v-btn :height="48" :min-width="48" class="elevation-0" color="#383838" :disabled="!canSend" @click="sendMessage">
            <v-icon color="#707070" size="16">
              mdi-message
            </v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'CommentBox',
  props: ['episode', 'forum', 'disabled'],
  components: {
  },
  data: () => ({
    message: '',
    sending: false
  }),
  computed: {
    canSend () {
      return this.message.length > 0 && !this.disabled;
    },
    currentUser () {
      return this.$store.getters.getUser;
    },
    profileImage () {
      if (this.currentUser) {
        if (this.currentUser.image) {
          return this.currentUser.image;
        }
      }
      return "/icons/user.png";
    },
    chatBanned () {
      if (this.$store.getters.getIsLoggedIn && this.currentUser) {
        if (this.currentUser.chatBanned) {
          return true;
        }
      }
      return false;
    },
    canComment () {
      if (!this.sending && !this.chatBanned && !this.disabled) {
        return true;
      }
      return false;
    },
    chatPlaceHolder () {
      if (this.chatBanned) {
        return 'You are not allowed to comment here.';
      }
      return 'Speak your mind...'
    },
    isIos () {
      return this.$store.getters.getPlatform == 'iOS';
    }
  },
  watch: {
  },
  methods: {
    sendMessage: function () {
      let self = this;

      self.sending = true;

      if (self.episode) {
        let data =  {
          episodeId: self.episode.id, 
          forumId: null,
          text: self.message
        }

        self.$store.dispatch('sendComment', data).then(function () {
          self.$emit('commentSent')
          self.message = '';
          self.sending = false;
        })
      } else if (self.forum) {
        let data =  {
          episodeId: null,
          forumId: self.forum.id, 
          text: self.message
        }

        self.$store.dispatch('sendComment', data).then(function () {
          self.$emit('commentSent')
          self.message = '';
          self.sending = false;
        })
      } 
    },
    focusCommentBox: function () {
    },
    onClick: function (e) {
      this.$emit('click', e);
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.comment-messagebox-row {
  // margin-bottom: 48px;
}
.comment-image-column {
  flex-grow: 0;
  // min-width: 80px;
}
.message-box {
  background-color: #323232;
  height: 48px;
  border-radius: 4px;
}
.message-text-container {
}
.message-field {
  border-radius: 0;
}
.send-button-container {
  width: 48px;
  max-width: 48px;
}
</style>

<style>
.message-field .v-input__slot {
  background-color: transparent !important;
  box-shadow: none !important;
}
</style>
