<template>
  <div ref="mediaPlayer" class="media-player">
    <!-- dash player -->
    <dashPlayer ref="dashPlayer" v-if="useDashPlayer" 
      :mpd="mpd" 
      :qualities="qualities"
      @canplay="updateCanPlay"
      @play="handlePlay"
      @pause="handlePause"
      @timeUpdate="updateTime"
      @ended="handleEnded"
      @blur="handleBlur"
      @loadedMetaData="updateTimeRemaining"
      @playerAction="playerAction"
      />
    <!-- player for audio and legacy video -->
    <vanillaPlayer ref="vanillaPlayer" v-if="!useDashPlayer"
      :mediaFormat="mediaFormat"
      :ios="ios"
      :audioFile="audioFormat"
      :audioOnly="mediaFormat == 'audio'"
      @canplay="updateCanPlay"
      @volumeChange="updateVolume"
      @play="handlePlay"
      @pause="handlePause"
      @timeUpdate="updateTime"
      @ended="handleEnded"
      @blur="handleBlur"
      @loadedMetaData="updateTimeRemaining"
      @playerAction="playerAction"
      />

    <!-- audio background -->
    <div v-show="mediaFormat == 'audio'" class="audio-container">
      <div class="blurred-image" v-bind:style="{ 'background-image': 'url('+backgroundImage+')' }">
      </div>
      <v-img :src="backgroundImage" :width="'100%'" :height="'100%'" :max-width="'100%'" :max-height="'100%'" :aspect-ratio="1" contain>
      </v-img>
    </div>
    <!-- background click for easy play pause -->
    <div v-if="!isMobile" class="media-background" v-bind:class="{ 'media-background-ios': ios && mediaFormat == 'audio' }" @click="backgroundClicked">
    </div>
    <div v-if="isMobile" class="mobile-media-background">
      <div v-bind:class="{ 'hidden-controls': !showControls, 'ios-hidden': ios }" >
        <v-btn icon class="skip-back-button" @click="skipTime(-15)">
          <v-img src="/icons/back-arrow.png" width="96"></v-img>
        </v-btn>
        <v-btn icon class="mobile-pause-button" @click="togglePlay">
          <v-icon v-if="playing" size="64">mdi-pause</v-icon>
          <v-icon v-else size="64">mdi-play</v-icon>
        </v-btn>
        <v-btn icon class="skip-forward-button" @click="skipTime(15)">
          <v-img src="/icons/forward-arrow.png" width="96"></v-img>
        </v-btn>
      </div>
    </div>

    <!-- controls toolbar -->
    <v-toolbar v-if="!isMobile" id="media-controls" class="controls pa-0" v-bind:class="{ 'hidden-controls': !showControls, 'ios-hidden': ios }" data-state="hidden"
      height="36" dense tile>
      <v-btn tile class="play-pause media-button" @click="togglePlay">
        <v-icon v-if="playing" large>mdi-pause</v-icon>
        <v-icon v-else large>mdi-play</v-icon>
      </v-btn>
      <v-progress-linear
        class="media-progress"
        v-model="progress"
        @change="progressChanged"
        :query="true"
        :height="36"
        background-color="black"
        background-opacity="0.4"
      ></v-progress-linear>
      <v-btn tile class="end-buttons media-button">
        {{ timeRemaining }}
      </v-btn>
      <!-- volume slider -->
      <v-menu
        v-model="volumeOpen"
        :min-width="64"
        attach=".volume-button"
        top
        :offset-y="true"
        :nudge-top="0"
        :close-on-content-click="false"
        content-class="volume-menu"
      >
        <template v-slot:activator="{ on }">
          <v-btn tile class="volume-button end-buttons media-button" v-on="on">
            <v-icon v-show="volume > 0">mdi-volume-high</v-icon>
            <v-icon v-show="volume == 0">mdi-volume-off</v-icon>
          </v-btn>    
          </template>
        <v-slider
          class="volume-slider"
          v-model="volume"
          @change="volumeChanged"
          vertical
          :tick-size="8"
          :height="160"
        ></v-slider>
      </v-menu>
      <!-- quality menu -->
      <v-menu
        v-model="settingsOpen"
        v-if="qualities.length > 1"
        attach=".settings-button"
        :min-width="64"
        top
        :offset-y="true"
        :nudge-top="6"
        :nudge-left="4"
        :close-on-content-click="false"
        content-class="quality-menu">
        <template v-slot:activator="{ on }">
          <v-btn tile class="settings-button end-buttons media-button" v-on="on">
            <v-icon>mdi-cog</v-icon>
            <v-icon v-show="isBetterFormat" size="20" color="primary" class="quality-upgrade-icon">mdi-arrow-up-bold</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group color="primary" v-model="selectedQuality">
            <v-list-item v-for="q in selectableQualities" :key="q.quality" @click="changeQuality(q)">
              <v-list-item-content>
                <v-list-item-title>{{q.quality}}{{isNaN(q.quality) ? '' : 'p' }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn tile class="end-buttons media-button" @click="toggleFullscreen">
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
    </v-toolbar>
    <v-toolbar v-if="isMobile" id="media-controls" class="controls pa-0" v-bind:class="{ 'hidden-controls': !showControls, 'ios-hidden': ios }" data-state="hidden"
      height="36" dense tile>
      <v-btn tile class="mobile-time-remaining">
        {{ timeRemaining }}
      </v-btn>
      <v-btn tile class="mobile-fullscreen" @click="toggleFullscreen">
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
      <v-slider
        class="mobile-media-progress"
        @change="progressChanged"
        v-model="progress"
        :height="36"
        
        color="primary"
        track-color="#00000066"

        thumb-color="white"
        :thumb-size="64"
      ></v-slider>
    </v-toolbar>
  </div>
</template>
<script>

import Vue from 'vue'

import dashPlayer from './dashPlayer.vue'
import vanillaPlayer from './vanillaPlayer.vue'

export default {
  name: 'MediaPlayer',
  props: ['episode', 'qualities', 'hiddenControls'],
  components: {
    dashPlayer,
    vanillaPlayer
  },
  data: () => ({
    source: '',
    selected: null,
    mediaFormat: '',
    hideControls: false,
    useDashPlayer: false,
    keepProgress: false,
    progress: 0,
    current: 0,
    duration: 0,
    timeRemaining: '',
    playing: false,
    fullscreen: false,
    volumeOpen: false,
    volume: 50,
    settingsOpen: false,
    selectedQuality: 0,
    fadeTimeout: null,
    fadeGrace: 3000,
    lastViewUpdateTime: 0,
    viewUpdateInterval: 60
  }),
  errorCaptured(err) {
    this.$store.commit('addError', err);
  },
  computed: {
    selectableQualities () {
      if (this.qualities && this.qualities.length > 0) {
        return this.qualities.filter(q => q.quality != 'mpd' && q.quality != 'source');
      }
      return [];
    },
    videoResolutions () {
      if (this.qualities && this.qualities.length > 0) {
        return this.qualities.filter(q => !isNaN(q.quality));
      }
      return [];
    },
    audioFormat () {
      if (this.qualities && this.qualities.length > 0) {
        return this.qualities.find(q => q.quality == "audio");
      }
      return null;
    },
    mpd () {
      if (this.qualities && this.qualities.length > 0) {
        return this.qualities.find(q => q.format == "mpd");
      }
      return null;
    },
    shouldUseDashPlayer () {
      return this.mpd != null && !this.ios;
    },
    backgroundImage () {
      if (this.episode && this.episode.image) {
        return this.episode.image;
      }
      return '/icons/audio-placeholder.png';
    },
    ios () {
      return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    },
    isMobile () {
      return this.$store.getters.getIsMobile;
    },
    isBetterFormat () {
      let selectedIndex = this.qualities.indexOf(this.selected)
      return selectedIndex != 0 && this.qualities.length > 1;
    },
    showControls () {
      return !this.hideControls && !this.hiddenControls;
    }
  },
  watch: {
    qualities: function (val, oldVal) {
      this.loadDefaultQuality();
      if (oldVal) {
        this.selectedChanged();
      }
    },
    selected: function () {
      this.selectedChanged();
    },
    episode: function () {
      this.loadDefaultQuality();
    }
  },
  methods: {
    loadDefaultQuality: function () {
      let bestQuality = this.getBestQuality(this.qualities);
      this.selected = bestQuality;
      this.selectedQuality = this.qualities.indexOf(this.selected);
    },
    changeQuality: function (quality) {
      this.keepProgress = true;
      this.selected = quality;
      this.selectedQuality = this.qualities.indexOf(this.selected);
      // console.log('changing quality');
      // let currentTime = this.video.currentTime;
      // this.changeSource(quality.signedUrl);
      this.$store.commit('setCookie', { key: 'quality', val: quality.quality });
      // this.setTimestamp(currentTime);
    },
    selectedChanged: function () {
      let scope = this;

      if (scope.selected) {
        // if (scope.selected.quality == "audio" || !this.mpd) {
        if (!scope.shouldUseDashPlayer) {
          scope.useDashPlayer = false;
          console.log('vanilla');
          scope.$store.commit('addError', 'vanilla');
        } else {
          scope.useDashPlayer = true;
          console.log('dash');
          scope.$store.commit('addError', 'dash');
        }

        if (scope.selected.quality == 'audio') {
          scope.mediaFormat = 'audio';
        } else {
          scope.mediaFormat = 'video';
        }

        let keepProgress = scope.keepProgress;
        if (scope.useDashPlayer) {
          if (scope.$refs.vanillaPlayer) {
            scope.$refs.vanillaPlayer.stop();
          }
          

          // DASH PLAYER
          Vue.nextTick(function () {
            scope.$refs.dashPlayer.setSource(scope.selected, keepProgress, scope.current);
            scope.volumeChanged(scope.volume);
          })

        } else {
          if (scope.$refs.dashPlayer) {
            scope.$refs.dashPlayer.stop();
          }
          
          // VANILLA PLAYER
          Vue.nextTick(function () {
            scope.$refs.vanillaPlayer.setSource(scope.selected, keepProgress, scope.current);
            scope.volumeChanged(scope.volume);
          })
          

          scope.playerAction();
          scope.newVideoPlaying();
        }
      }
      scope.keepProgress = false;
      scope.$emit('qualityChanged', scope.selected)
    },
    getBestQuality: function (qualities) {
      let scope = this;

      if (this.ios) {
        let source = qualities.find(k => k.quality == 'source');
        if (source) {
          return source;
        }
      }

      // if the user has selected a quality, choose that one
      let previousQuality = scope.$store.getters.getCookie('quality');
      if (previousQuality != null) {
        let foundKey = qualities.find(k => k.quality == previousQuality);
        if (foundKey != null) { // && foundKey.quality != 'audio'
          return foundKey;
        }
      }

      // if there is video available then show the lowest quality video
      let videoQualities = qualities.filter(function (k) {
        let parsed = parseInt(k.quality);
        if (isNaN(parsed)) {
          return false;
        }
        return true;
      })

      if (videoQualities.length > 0) {
        videoQualities.sort(function (a, b) {
          return parseInt(b.quality) - parseInt(a.quality);
        });
        return videoQualities[videoQualities.length - 1];
      }

      return qualities[0];
    },
    togglePlay: function () {
      let scope = this;
      if (scope.useDashPlayer) {
        scope.$refs.dashPlayer.togglePlay();
      } else {
        scope.$refs.vanillaPlayer.togglePlay();
      }
      scope.playerAction();
    },
    getIsPlaying: function () {
      let scope = this;
      if (scope.useDashPlayer && scope.$refs.dashPlayer) {
        return scope.$refs.dashPlayer.isPlaying();
      } else if(scope.$refs.vanillaPlayer) {
        return scope.$refs.vanillaPlayer.isPlaying();
      }
      return false;
    },
    isFullscreen: function () {
      return !!(document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement);
    },
    backgroundClicked: function () {
      this.togglePlay();
    },
    mousemove: function () {
      this.$emit('mousemove');
      this.playerAction(false);
    },
    updateTime: function (data) {
      let scope = this;

      scope.current = data.current;
      scope.duration = data.duration;

      scope.progress = (data.current / data.duration) * 100;
      scope.updateTimeRemaining();

      if (Math.abs(data.current - scope.lastViewUpdateTime) > scope.viewUpdateInterval) {
        scope.lastViewUpdateTime = data.current;

        scope.updateViewData();
      }
    },
    updateTimeRemaining: function () {
      let scope = this;
      if (scope.duration) {
        let remaining = scope.duration - scope.current;
        let minutes = Math.floor(remaining / 60);
        let seconds = remaining - (minutes * 60);
        seconds = Math.floor(seconds);

        minutes = minutes + '';
        seconds = seconds + '';
        if (seconds.length == 1) {
          seconds = '0' + seconds;
        }
        scope.timeRemaining = minutes + ':' + seconds;
        scope.focusVideo();
      }
    },
    updateVolume: function (val) {
      this.volume = val * 100;
    },
    volumeChanged: function (val) {
      let scope = this;
      if (val != null) {
        if (scope.useDashPlayer) {
          scope.$refs.dashPlayer.setVolume(val);
        } else {
          scope.$refs.vanillaPlayer.setVolume(val);
        }
        scope.$store.commit('setCookie', { key: 'volume', val: val });
      }
    },
    toggleFullscreen: function () {
      let mediaContainer = this.$el.parentElement;
			if (this.isFullscreen()) {
        if (document.exitFullscreen) document.exitFullscreen();
        else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
        else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
        else if (document.msExitFullscreen) document.msExitFullscreen();
        this.fullscreen = false;
      } else {
        if (mediaContainer.requestFullscreen) mediaContainer.requestFullscreen();
        else if (mediaContainer.mozRequestFullScreen) mediaContainer.mozRequestFullScreen();
        else if (mediaContainer.webkitRequestFullScreen) {
          // Safari 5.1 only allows proper fullscreen on the video element. This also works fine on other WebKit browsers as the following CSS (set in styles.css) hides the default controls that appear again, and 
          // ensures that our custom controls are visible:
          // figure[data-fullscreen=true] video::-webkit-media-controls { display:none !important; }
          // figure[data-fullscreen=true] .controls { z-index:2147483647; }
          if (this.useDashPlayer) {
            this.$refs.dashPlayer.requestFullscreen();
          } else {
            this.$refs.vanillaPlayer.requestFullscreen();
          }
          // this.video.webkitRequestFullScreen();
        }
        else if (mediaContainer.msRequestFullscreen) mediaContainer.msRequestFullscreen();
        this.fullscreen = true;
      }
      this.playerAction();
    },
    setTimestamp: function (val) {
      let scope = this;
      let percent = val / scope.episode.runtime;
      if (percent > 1.0) {
        percent = 0;
      }

      scope.progress = percent * 100;

      if (scope.useDashPlayer) {
        scope.$refs.dashPlayer.setTimestamp(val);
      } else {
        scope.$refs.vanillaPlayer.setTimestamp(val);
      }
    },
    skipTime: function (t) {
      let scope = this;

      let newTime = scope.current + t;
      scope.setTimestamp(newTime);
    },
    progressChanged: function (val) {
      let scope = this;
      if (scope.useDashPlayer) {
        scope.$refs.dashPlayer.progressChanged(val);
      } else {
        scope.$refs.vanillaPlayer.progressChanged(val);
      }
      scope.showAutoplay = false;
      scope.playerAction();
    },
    fade: function () {
      this.hideControls = true;
      this.$emit('fade');
    },
    unfade: function () {
      this.hideControls = false;
      this.$emit('unfade');
    },
    playerAction: function (emit) {
      let scope = this;

      scope.focusVideo();

      // show the controls
      scope.unfade();

      // cancel the current timeout for hiding the controls
      if (scope.fadeTimeout) {
        clearTimeout(scope.fadeTimeout);
      }

      // start the timeout for hide again
      scope.fadeTimeout = setTimeout(function(){ 
        if (scope.getIsPlaying() && !scope.showEpisodes) {
          scope.fade();
        }
      }, this.fadeGrace);

      // cancel the 
      if (emit == null || emit == true) {
        scope.$emit('playerAction');
      }
    },
    focusVideo: function () {
      let scope = this;
      if (scope.useDashPlayer) {
        if (scope.$refs.dashPlayer) {
          // scope.$refs.dashPlayer.focusVideo();
        }
      } else {
        if (scope.$refs.vanillaPlayer) {
          // scope.$refs.vanillaPlayer.focusVideo();
        }
      }
    },
    handleMouseMove: function () {
      let scope = this;
      scope.mousemove();
    },
    handleMouseLeave: function () {
      this.volumeOpen = false;
    },
    handleEnded: function () {
      let scope = this;
      scope.$emit("ended");
      scope.updateViewData();
    },
    handleBlur: function () {
      let scope = this;
      setTimeout(function () { 
        scope.focusVideo();
      }, 20);
    },
    handlePlay: function () {
      this.playing = true;
      this.$store.commit('setContentPlaying', true);
    },
    handlePause: function () {
      this.playing = false;
      this.$store.commit('setContentPlaying', false);
    },
    newVideoPlaying: function () {
      this.lastViewUpdateTime = 0;
      
      this.updateViewData();
    },
    updateViewData: function () {
      this.$emit('updateView', this.current);
    },
    updateCanPlay: function () {
      this.$emit('canplay');
    },
    createEvents: function () {
      let scope = this;

      scope.$refs.mediaPlayer.addEventListener('mousemove', scope.handleMouseMove);
    }
  },
  beforeDestroy () {
    let scope = this;

    let volumeElement = document.getElementsByClassName('volume-menu')[0];
    if (volumeElement) {
      volumeElement.removeEventListener('mouseleave', scope.handleMouseLeave);
    }
    scope.$refs.mediaPlayer.removeEventListener('mousemove', scope.handleMouseMove);
  },
  mounted () {
    let scope = this;

    let volume = scope.$store.getters.getCookie('volume');
    if (scope.isMobile) {
      volume = 50;
    }
    if (volume) {
      scope.volume = volume;
    }

    scope.createEvents();
    scope.playerAction();

    scope.loadDefaultQuality();

    if (scope.episode) {
      scope.newVideoPlaying();
    }
  }
}
</script>
<style lang="scss" scoped>
.media-player {
  width: 100%;
  height: 100%;
  outline: none !important;
}

.ios-hidden {
  display: none !important;
}

.controls {
	position:absolute;
	bottom: 0;
	width: 100%;
	z-index: 2147483647;
  background: none !important;
}

.play-pause {
  width: 64px;
}
.media-progress {
  width: calc(100% - 64px - 64px - 64px - 64px);
}
.end-buttons {
  width: 64px;
}

.media-button {
  background: none;
  background-color: #00000073 !important;
  box-shadow: inherit !important;
}

.volume-menu {
  box-shadow: inherit !important;
  overflow: visible;
  padding: 4px 0;
  left: 0 !important;
}

.hidden-controls {
  // opacity: 0;
  display: none;
}

.hide-cursor {
  cursor: none;
}

.audio-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 12%;
}

.blurred-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  filter: blur(20px);
}
.quality-upgrade-icon {
  position: absolute;
  right: -2px;
  top: -4px;
}
.quality-menu {
  border-radius: 0;
  left: 0 !important;
}
.quality-menu .v-list {
  padding: 0px !important;
}
.quality-menu .v-list-item {
  border-bottom: 1px solid #6b6b6b21 !important;
  padding: 0px !important;
}
.media-background {
  position:absolute;
  top: 0;
	bottom: 36px;
  left: 0;
  right: 0;
  z-index: 2147483647;
  background: none !important;
}
.media-background-ios {
  bottom: 80px;
}

.mobile-media-background {
  position: absolute;
  top: 50%;
  left: 50%;
}

.skip-back-button {
  position: absolute;
  right: 80px;
  top: -18px;
}
.mobile-pause-button {
  position: absolute;
  left: -18px;
  top: -18px;
}
.skip-forward-button {
  position: absolute;
  left: 80px;
  top: -18px;
}
</style>
<style lang="scss">
.controls .v-toolbar__content {
  padding: 0px;
}
.media-progress .v-progress-linear__determinate {
  opacity: 0.6;
}
.volume-slider .v-slider__track-background {
  background: white !important;
}
.volume-slider .v-slider__track-container {
  width: 12px !important;
  border-radius: 6px;
  overflow: hidden;
}
.volume-slider .v-slider__thumb {
  background-color: gray !important;
  width: 16px;
  height: 16px;
  left: -8px;
}
.v-slider__thumb-container .v-slider__thumb:before {
  display: none !important;
}
.ios-label {
  position: absolute;
  left: 20px;
  top: 20px;
  color: red;
}

.mobile-media-progress .v-slider.v-slider--horizontal {
  margin: 0px !important;
}
.mobile-media-progress .v-slider__track-container {
  height: 8px !important;
}
.mobile-media-progress .v-slider__thumb {
  width: 32px !important;
  height: 32px !important;
}

.mobile-time-remaining {
  position: absolute;
  left: 0;
  bottom: 32px;
  background-color: transparent !important;
  box-shadow: none !important;
}
.mobile-fullscreen{
  position: absolute;
  right: 0;
  bottom: 32px;
  background-color: transparent !important;
  box-shadow: none !important;
}
</style>