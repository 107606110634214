<template>
  <div class="episode-info pa-2 mb-2">
    <v-row v-bind:class="{ 'mobile-episode-info': isMobile }">
      <v-col>
        <div class="episode-title">{{ episode.name }}</div>
        <div class="show-title" v-if="!episode.singularEpisode">
          <span v-if="show">{{ show.title }}</span> <span>S{{ episode.seasonNumber }} E{{ episode.episodeNumber }}</span>
        </div>
      </v-col>
      <v-col class="rating-panel" v-if="!isMobile">
        <rating :content="episode" contentType="episode" :bar="true" :height="24"/>
      </v-col>
      <v-col cols="12 pt-0">
        <div>{{ episode.description }}</div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col v-if="isMobile" cols="6">
        <rating :content="episode" contentType="episode" :bar="true" :height="24"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import rating from '../rating/rating.vue'

export default {
  name: 'EpisodeInfo',
  props: ['episode', 'show'],
  components: {
    rating
  },
  data: () => ({
  }),
  computed: {
    isMobile () {
      return this.$store.getters.getIsMobile;
    }
  },
  watch: {
  },
  methods: {
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.mobile-episode-info {
  text-align: center;
}
.episode-title {
  font-size: 24px;
}
.show-title {
  font-size: 16px;
  color: grey;
}
.episode-number {
  font-size: 14px;
}
.rating-panel {
  flex-grow: 0;
  min-width: 160px;
}
</style>
