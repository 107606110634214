<template>
  <div v-if="popoutType == 'episode'" class="episode-popout" v-bind:style="{ 'margin-top': topMargin + 'px' }" v-bind:class="{ 'minimised-episode': minimised }">
    <episode :minimised="minimised" @setPreviewData="setPreviewData" />
    <div v-show="minimised" class="radial-gradient">
    </div>
    <div v-show="minimised" class="minimised-overlay">
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="9" @click="unminimise()">
            <div class="minimised-title" v-if="episode">
              {{ episode.name }}
            </div>
            <div class="minimised-show" v-if="show">
              {{ show.title }} <span>S{{ episode.seasonNumber }} E{{ episode.episodeNumber }}</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-btn icon @click="toggleEpisodePlay">
              <v-icon v-if="isContentPlaying">mdi-pause</v-icon>
              <v-icon v-else>mdi-play</v-icon>
            </v-btn>
            <v-btn icon @click="closeEpisode">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import episode from '../../views/Episode.vue'

import { EventBus } from '../../eventBus';
export default {
  name: 'Popout',
  props: [],
  components: {
    episode
  },
  data: () => ({
    minimised: false,
    popoutType: '',
    contentId: null,
    pageName: ''
  }),
  computed: {
    isContentPlaying () {
      return this.$store.getters.getIsContentPlaying;
    },
    episode () {
      return this.$store.getters.getPlayingEpisode;
    },
    show () {
      return this.$store.getters.getPlayingShow;
    },
    topMargin () {
      if (this.pageName == 'Show') {
        return 56;
      }
      return 0;
    }
  },
  watch: {
    minimised: function (val) {
      this.$store.commit('setPopoutMinimised', val);
    },
    popoutType: function (val) {
      this.$store.commit('setPopoutOpen', val !== '');
    },
    $route (to) {
      this.pageName = to.name;
    }
  },
  methods: {
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    minimise: function () {
      console.log('do the minimise');
      // this.minimisedEpisode = true;
      this.minimised = true;
      this.$store.commit('setPopoutMinimised', this.minimised);
    },
    unminimise: function (leave) {
      this.minimised = false;
      this.$store.commit('setPopoutMinimised', this.minimised);

      if (!leave && this.popoutType == 'episode') {
        this.navigate('/episode/' + this.contentId);
      }
    },
    toggleEpisodePlay: function () {
      EventBus.$emit('togglePlay');
    },
    closeEpisode: function () {
      this.popoutType = '';
      this.minimised = false;
      this.$store.commit('setPopoutMinimised', this.minimised);
    },
    setPreviewData: function (data) {
      this.previewData = data;
    }
  },
  mounted () {
    let self = this;
    
    EventBus.$on('popout', function (data) {
      self.popoutType = data.contentType;
      self.contentId = data.contentId;
    })

    EventBus.$on('closePopout', function () {
      self.popoutType = '';
      self.contentId = null;
    })

    EventBus.$on('minimisePlayer', function () {
      self.minimise();
    })

    EventBus.$on('unminimisePlayer', function (leave) {
      self.unminimise(leave);
    })
  }
}
</script>
<style lang="scss" scoped>
.episode-popout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 500;
}

.minimised-episode {
  animation: minimise 1s ease;
  top: calc(100vh - 120px);
  min-height: 64px;
  overflow-y: hidden;
  height: 64px;
  background-color: #3a3a3a;
  /* min-height: 20vh; */
}

.minimised-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.minimised-show {
  font-size: 12px;
  color: lightgrey;
}
@keyframes minimise {
  0% {
    top: 0;
    min-height: 100vh;
    height: 100vh;
  }
  100% {
    top: calc(100vh - 120px);
    min-height: 64px;
    height: 64px;
  }
}

.radial-gradient {
  animation: radial 1s ease; 
  -webkit-mask-image: -webkit-radial-gradient(circle at center, #00000000 0%, #000000ff 80%);
  mask-image: radial-gradient(circle at center, #00000000 0%, #000000ff 80%);
  background-color: #282828;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@keyframes radial {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
