<template>
  <v-container fill-height>
    <v-row class="bulletin-title">
      <v-col class="text-center display-1">
        <div>Bulletins</div>
      </v-col>
    </v-row>
    <v-row class="bulletin-list pa-4">
      <v-col cols="5">
        <div>
          <v-row class="pb-2" v-for="(bulletin, i) in bulletins" :key="bulletin.id">
            <bulletinItem :item="bulletin" @click="editBulletin(bulletin)"/>
            <v-spacer />
            <v-col cols="1">
              <v-container fluid class="pa-0">
                <v-row>
                  <v-col class="pa-0">
                    <v-btn icon @click="deleteBulletin(bulletin)">
                      <v-icon size="32" color="red">
                        mdi-close-circle
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="i != 0">
                  <v-col class="pa-0">
                    <v-btn icon @click="moveUp(i)">
                      <v-icon size="32">
                        mdi-chevron-up
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="i != bulletins.length - 1">
                  <v-col class="pa-0">
                    <v-btn icon @click="moveDown(i)">
                      <v-icon size="32">
                        mdi-chevron-down
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <div class="mt-4">
            <v-btn @click="createBulletin" color="green">
              New
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="7">
        <v-card v-if="selectedBulletin">
          <v-card-title>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="selectedBulletin.subtitle" :disabled="saving" placeholder="Subtitle" label="Subtitle" class=""></v-text-field>
            <v-text-field v-model="selectedBulletin.title" :disabled="saving" placeholder="Title" label="Title" class=""></v-text-field>
            <v-text-field v-model="selectedBulletin.buttonText" :disabled="saving" placeholder="Button Text" label="Button Text" class=""></v-text-field>
            <v-text-field v-model="selectedBulletin.buttonLink" :disabled="saving" placeholder="Button Link" label="Button Link" class=""></v-text-field>
            <imageUploader ref="bulletinImage" v-model="selectedBulletin.imageId" :disabled="saving" :url="selectedBulletin.image" placeholder="/icons/upload-image.png" @change="updateImage" :width="200" :height="200" :aspect="1/1"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-switch v-model="selectedBulletin.featured" :disabled="saving" label="Featured" class="px-6"></v-switch>
            <v-btn tile color="primary" @click="save" :disabled="saving">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Api } from '../../../js/api';

import bulletinItem from './../../investors/bulletinItem.vue'
import imageUploader from '../../global/imageUploader';

export default {
  name: 'Bulletins',
  props: [],
  components: {
    bulletinItem,
    imageUploader
  },
  data: () => ({
    bulletins: [],
    selectedBulletin: null,
    saving: false
  }),
  computed: {
    isNew () {
      if(this.selectedBulletin && this.selectedBulletin.id) {
        return false;
      }
      return true;
    },
  },
  watch: {
  },
  methods: {
    getData: function () {
      let scope = this;
      Api.get('AdminInvestorBulletin/all').then(function (res) {
        scope.bulletins = res.data
      })
    },
    selectBulletin: function (bulletin) {
      this.selectedBulletin = bulletin;
    },
    editBulletin: function (bulletin) {
      this.selectBulletin(bulletin);
    },
    updateImage: function (image) {
      this.selectedBulletin.image = image;
    },
    createBulletin: function () {
      this.selectedBulletin = {}
    },
    moveDown: function (i) {
      let scope = this;

      let item1 = scope.bulletins[i]
      let item2 = scope.bulletins[i + 1]

      scope.swapBulletins(item1, item2);
    },
    moveUp: function (i) {
      let scope = this;

      let item1 = scope.bulletins[i]
      let item2 = scope.bulletins[i - 1]

      scope.swapBulletins(item1, item2);
    },
    swapBulletins: function (item1, item2) {
      let scope = this;

      let id1 = item1.id;
      let id2 = item2.id;
      
      let data = {
        bulletinItem1: id1,
        bulletinItem2: id2
      };

      Api.post('AdminInvestorBulletin/swapBulletinItems', data).then(function () {
        scope.getData();
      })
    },
    deleteBulletin: function (bulletin) {
      let scope = this;

      let data = {
        id: bulletin.id
      }

      Api.post('AdminInvestorBulletin/delete', data).then(function () {
        scope.getData();
      })
    },
    save: function () {
      let scope = this;

      if (scope.$refs.bulletinImage.file) {
        // upload the file first
        scope.$refs.bulletinImage.upload().then(function () {
          scope.save();
        })
      } else {
        scope.saveBulletin();
      }
    },
    saveBulletin: function () {
      let scope = this;
      let data = this.selectedBulletin;
      scope.saving = true;

      if (!this.isNew) {
        Api.post('AdminInvestorBulletin/Update', data).then(function () {
          scope.saving = false;
          scope.selectedBulletin = null;
          scope.getData();
        })
      } else {
        Api.post('AdminInvestorBulletin/Create', data).then(function () {
          scope.saving = false;
          scope.selectedBulletin = null;
          scope.getData();
        })
      }
    }
  },
  mounted () {
    this.getData();
  }
}
</script>
<style lang="scss" scoped>
.bulletin-title {
  height: 100px;
}
.bulletin-list {
  height: calc(100% - 100px);
}
</style>
