<template>
  <v-select
    class="season-selector"
    :items="seasons"
    :item-text="'name'"
    :item-value="'n'"
    label="Season"
    v-model="internalValue"
    @change="change"
    no-data-text="No Seasons"
    solo
    flat
  ></v-select>
</template>
<script>
export default {
  name: 'SeasonSwitcher',
  props: ['value', 'seasons'],
  components: {
  },
  data: () => ({
    internalValue: 1
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    change: function () {
      this.$emit('input', this.internalValue)
    }
  },
  mounted () {
    this.internalValue = this.value;
  }
}
</script>
<style lang="scss" scoped>
.season-selector {
  width: 160px;
  height: 32px;
  border-top: 4px white solid;
}
</style>
