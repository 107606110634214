<template>
  <v-card class="" color="#363636" tile flat>
    <v-card-title>
      Episode Views
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div id="views-graph"></div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import c3 from 'c3';

export default {
  name: 'ViewsGraph',
  props: ['data', 'width', 'height'],
  components: {
  },
  data: () => ({
    chartData: null
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    loadChart: function () {
      c3.generate({
        bindto: '#views-graph',
        data: {
          columns: this.chartData,
          type : 'bar',
          xFormat: '%Y-%m-%dT%H:%M:%S',
          x: 'x',
          colors: {
            views: '#00cc44'
          },
          onclick: function (d, i) { console.log("onclick", d, i); },
          onmouseover: function (d, i) { console.log("onmouseover", d, i); },
          onmouseout: function (d, i) { console.log("onmouseout", d, i); }
        },
        bar: {
          width: 20
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: '%m-%d',
              rotate: -90
            }
          },
          y: {
            tick: {
              format: function(x) { return x % 1 === 0 ? x : ''; }
            }
          }
        },
        size: {
          width: this.width,
          height: this.height
        }
      });
    }
  },
  mounted () {
    this.chartData = [
      ['x', ...this.data.dates],
      ['views', ...this.data.views]
    ]
    this.loadChart();
  }
}
</script>
<style lang="scss">
#views-graph .tick,
#views-graph .c3-legend-item {
  fill: white;
}

#views-graph .domain {
  fill: none;
  stroke: white;
}
</style>
