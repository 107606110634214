<template>
  <div class="px-2">
    <v-btn color="yellow" class="shareholder-button px-2" @click="navigate">
      <div class="shareholder-text pr-1"><b>Shareholder</b></div>
      <v-icon color="black">mdi-view-dashboard</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'InvestorBar',
  props: [],
  components: {
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    navigate: function () {
      this.$router.push('/investors').catch(()=>{});
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.shareholder-button {
  border-radius: 10px;
}
.shareholder-text {
  text-transform: initial;
  color: black;
  font-size: 16px;
}
</style>
