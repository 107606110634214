<template>
  <div class="mb-2" v-bind:class="{ 'pb-5': isMobile }">
    <div v-if="!isMobile" class="next-up-text">Next up...</div>
    <div v-bind:class="{ 'pa-4': !isMobile, 'up-next': !isMobile }">
      <episode :episode="episode" :compact="true" :gutterless="isMobile" :upnext="isMobile" />
    </div>
  </div>
</template>
<script>
import episode from './episode.vue'

export default {
  name: 'UpNext',
  props: ['episode'],
  components: {
    episode
  },
  data: () => ({
  }),
  computed: {
    isMobile () {
      return this.$store.getters.getIsMobile;
    },
  },
  watch: {
  },
  methods: {
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.next-up-text {
  margin-bottom: 16px;
}
.up-next {
  background-color: #474747;
}
</style>
