<template>
  <v-hover
    v-slot="{ hover }"
    open-delay="100">
    <v-carousel v-model="activeCarousel" hide-delimiters :show-arrows="false" :height="carouselHeight">
      <carouselPage :ref="'carouselPage'+i" :item="item" :preventNavigate="preventNavigate" :height="carouselHeight" :key="i" v-for="item, i in items"/>
      <div v-show="hover" class="carousel-background"></div>
      <v-btn v-if="hover" @click="pageClicked()" icon class="carousel-play-button">
        <v-icon size="100" class="fa-rotate-90">mdi-triangle</v-icon>
      </v-btn>
      <div class="carousel-delimiters">
        <v-btn @click="activeCarousel=i" :key="i" v-for="item, i in items" icon class="">
          <v-icon v-if="activeCarousel==i" size="10">mdi-checkbox-blank-circle</v-icon>
          <v-icon v-else size="10">mdi-checkbox-blank-circle-outline</v-icon>
        </v-btn>
      </div>
    </v-carousel>
  </v-hover>
</template>

<script>
import carouselPage from './carouselPage'
export default {
  name: 'Carousel',
  props: ['items', 'preventNavigate'],
  components: {
    carouselPage
  },
  data: () => ({
    carouselHeight: 400,
    activeCarousel: 0
  }),
  methods: {
    pageClicked: function () {
      this.$refs['carouselPage'+this.activeCarousel][0].clicked();
    }
  }
}
</script>

<style lang="scss">

.carousel-play-button {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 18px);
}

.carousel-background {
  position: absolute;
  background: #029cf03b;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.left-button {
  position: absolute;
  top: calc(50% - 10px);
  left: 40px;
}
.carousel-delimiters {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.disrupt-mobile .right-button {
  position: absolute;
  bottom: 64px;
  top: inherit;
  left: 45%;
}
</style>
