<template>
  <v-dialog v-model="dialog" width="600" content-class="reset-password-dialog">
    <v-card>
      <v-card-title>
        Reset Password?
      </v-card-title>
      <v-card-text class="reset-password-body">
        Are you sure you want to send this user a password reset email?
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-btn tile class="cancel-button" :loading="saving" @click="cancel()">
          Cancel
        </v-btn>
        <v-spacer/>
        <v-btn tile class="login-button" :loading="saving" @click="resetPassword()" color="primary">
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { EventBus } from '../../../eventBus.js';
import { Api } from '../../../js/api';

export default {
  name: 'ResetPasswordUserDialog',
  components: {
  },
  data: () => ({
    dialog: false,
    saving: false,
    userData: null
  }),
  computed: {
  },
  methods: {
    cancel: function () {
      this.dialog = false;
    },
    resetPassword: function () {
      let scope = this;

      let data = {
        id: this.userData.id
      };
      
      scope.saving = true;
      Api.post('Admin/resetPassword', data).then(function (res) {
        scope.dialog = false;
        scope.saving = false;

        if (res.valid) {
          EventBus.$emit('alert', {
            text: 'Email sent',
            alertColor: 'green',
            alertIcon: 'success'
          })
        }

        scope.$emit('complete');
      })
    }
  },
  mounted () { let scope = this
    EventBus.$on('passwordResetUserDialog', function (user) {
      scope.userData = user;
      scope.dialog = true;
    })
  }
}
</script>
<style lang="scss">
.reset-password-dialog,
.reset-password-dialog .v-card {
  background-color: #202020 !important;
  border-radius: 0px !important;
}
.reset-password-body {
  height: 200px;
}
</style>