<template>
  <v-text-field :label="label" :class="fieldClass" v-model="internalValue" :height="internalHeight" :messages="displayedMessages" :disabled="disabled" 
    @keydown="keydownEvent" :type="inputType" flat solo>
    <template v-slot:append>
      <v-progress-circular v-if="isValueDirty && !acceptAndIsOriginal && !isEmpty" indeterminate :color="textColor"></v-progress-circular>
      <v-icon v-else-if="acceptAndIsOriginal && appendIcon" :color="textColor">
        {{ appendIcon }}
      </v-icon>
      <v-icon v-else-if="showIsInvalid && !acceptAndIsOriginal" large color="red">
        mdi-alert
      </v-icon>
      <v-icon v-else-if="showIsValid && !acceptAndIsOriginal" large color="green">
        mdi-checkbox-marked-circle
      </v-icon>
    </template>
  </v-text-field>
</template>
<script>
import { Api } from '../../js/api';
export default {
  name: 'ServerValidationField',
  components: {
  },
  props: ['value', 'label', 'height', 'acceptOriginalValue', 'disabled', 'fieldClass', 'endpoint', 'keydown',
        'dataFunction', 'responseFunction', 'resolveFunction', 'messages', 'inputDelay', 'password', 'textColor', 'appendIcon'],
  data: () => ({
    internalValue: null,
    originalValue: '',
    isValueValid: false,
    isCheckingValue: false,
    isValueDirty: false,
    valueCounter: 500,
    internalHeight: 80,
    intervalEvent: null,
    internalInputDelay: 500,
    checkInterval: 100,
    internalResponseFunction: null,
    internalResolveFunction: null
  }),
  computed: {
    isEmpty () {
      if (this.internalValue) {
        return this.internalValue.length == 0;
      }
      return true;
    },
    inputType () {
      if (this.password) {
        return 'password';
      }
      return 'text';
    },
    showIsInvalid () {
      if (this.internalValue) {
        return this.internalValue.length > 0 && !this.isValueValid && !this.isCheckingValue;
      }
      return false;
    },
    showIsValid() {
      return this.isValueValid && !this.isOriginal;
    },
    displayedMessages () {
      if (!this.isValueValid) {
        return this.messages;
      }
      return null;
    },
    checkValid () {
      return this.isValueValid || this.isOriginal;
    },
    isOriginal () {
      return this.originalValue.toUpperCase() == this.internalValue.toUpperCase();
    },
    acceptAndIsOriginal () {
      return this.acceptOriginalValue && this.isOriginal;
    }
  },
  watch: {
    internalValue: function (val) {
      this.$emit('input', val);

      this.isValueDirty = true;
      this.isValueValid = false;

      if(this.valueCounter < this.internalInputDelay + 100) {
        this.valueCounter += this.internalInputDelay;
      }
    }
  },
  methods: {
    keydownEvent: function (e) {
      this.$emit('keydown', e);
    },
    recursiveServerCheck: function () {
      let self = this;

      if (self.acceptOriginalValue) {
        if (self.isOriginal) {
          self.isValueValid = true;
          self.isCheckingValue = false;
          self.isValueDirty = false;
          return;
        }
      }
      if (self.internalValue.length > 0) {
        self.isCheckingValue = true;

        let valueToCheck = self.internalValue;
        self.internalResolveFunction(valueToCheck).then(function (result) {
          if (valueToCheck != self.internalValue) {
            self.isValueDirty = true;
          } else {
            self.isValueDirty = false;
          }

          if (self.isValueDirty && self.internalValue.length > 0) {
            self.recursiveServerCheck()
          } else {
            self.isCheckingValue = false;
            self.isValueValid = result;
          }
        })
      }
    },
    checkValueAvailability: function (checkingValue) {
      let self = this;
      return new Promise((resolve) => {
        let data = self.dataFunction(checkingValue);
        Api.post(self.endpoint, data).then(function (res) {
          resolve(self.internalResponseFunction(res));
        })
      })
    },
    dataResponseFunction: function (res) {
      return res.data;
    }
  },
  destroyed () {
    clearInterval(this.intervalEvent);
  },
  mounted () {
    let self = this;

    self.internalValue = self.value;
    self.originalValue = self.internalValue;

    if (self.inputDelay) {
      self.internalInputDelay = self.inputDelay;
    }

    self.internalResponseFunction = self.dataResponseFunction;
    if (self.responseFunction != null) {
      self.internalResponseFunction = self.responseFunction;
    }

    self.internalResolveFunction = self.checkValueAvailability;
    if (self.resolveFunction != null) {
      self.internalResolveFunction = self.resolveFunction;
    }

    if (self.height != null) {
      self.internalHeight = self.height;
    }
    
    self.intervalEvent = setInterval(function(){
      if (self.isValueDirty) {
        if (self.valueCounter < 1) {
          if (!self.isCheckingValue) {
            self.recursiveServerCheck();
          }
        }
        if (self.valueCounter > 0) {
          self.valueCounter -= 50;
        }
      }
    }, this.checkInterval);
  }
}
</script>
<style lang="scss" scoped>
</style>
