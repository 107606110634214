<template>
  <v-container id="episode-page" class="episode-page" v-bind:class="{ 'mobile-episode-page': isMobile }" fluid>
    <v-container class="episode-container" fluid>
      <v-row>
        <v-col cols="12" class="video-panel" id="watch-container" v-bind:style="{ 'height': mobileHeight }">
          <watch ref="watch" :minimised="minimised" />
          <!-- <disruptPlayer ref="disrupt-player" :episode="null" :qualities="[]" :type="'video'" :show="null" :episodes="[]" /> -->
        </v-col>
      </v-row>
      <v-row v-show="!minimised">
        <v-col cols="12" md="9" class="pb-0">
          <v-row class="pa-0">
            <v-col cols="12" class="info-column pb-5">
              <episodeInfo :episode="episode" :show="show" />
            </v-col>
            <v-col cols="12" class="comments-column">
              <comments :episode="episode" :send="true" :showMostLiked="true" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3" class="recommended-column">
          <suggestions v-if="episode" :episode="episode" :amount="5" />
        </v-col>
        <v-col cols="12" class="my-5">
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import Vue from 'vue';

// import { EventBus } from './../eventBus.js';

import episodeInfo from '../components/content/episode/episodeInfo.vue'
// import disruptPlayer from '../components/global/player/disruptPlayer'
import comments from '../components/content/comments/comments.vue'
import suggestions from '../components/content/suggestions/suggestions.vue'
import watch from '../views/Watch.vue'

export default {
  name: 'Episode',
  props: ['minimised'],
  components: {
    episodeInfo,
    comments,
    suggestions,
    watch
    // disruptPlayer
  },  
  data: () => ({
    episode: null,
    show: null
  }),
  computed: {
    nextEpisode () {
      return this.episode;
    },
    episodeId () {
      return this.$route.params.episodeId;
    },
    showId () {
      if (this.episode != null) {
        return this.episode.showId;
      }
      return null;
    },
    isMobile () {
      return this.$store.getters.getIsMobile;
    },
    videoAspect () {
      return 16.0/9.0;
    },
    mobileHeight () {
      if (this.isMobile) {
        if (this.$el) {
          return (this.$el.parentElement.clientWidth / this.videoAspect) + 'px';
        } else {
          return (screen.width / this.videoAspect) + 'px';
        }
      }
      return '';
    }
  },
  watch: {
    episodeId: function () {
      let self = this;
      if (self.episodeId && self.episodeId != self.episode.id) {
        self.getEpisode();
      }
    },
    showId: function () {
      let self = this;
      if (self.showId) {
        self.getShow();
      }
      return null
    },
    mobileHeight: function () {
      let self = this;
      if (self.$refs.watch) {
        Vue.nextTick(function () {
          self.$refs.watch.updateSizing();
        })
      }
    }
  },
  methods: {
    getEpisode: function () {
      let self = this;

      if (self.episodeId) {
        self.$store.dispatch('getEpisode', self.episodeId).then(function (ep) {
          self.episode = ep;
          self.$store.commit('setPlayingEpisode', self.episode);
        })
      }
    },
    getShow: function () {
      let self = this;

      if (self.showId) {
        self.$store.dispatch('getShow', self.showId).then(function(show){
          self.show = show;
          self.$store.commit('setPlayingShow', self.show);
        })
      }
    }
  },
  mounted () {
    let scope = this

    scope.getEpisode();
  }
}
</script>
<style lang="scss" scoped>
.episode-page {
  padding: 0px;
  background-color: #202020;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.episode-container {
  position: absolute;
}

.video-panel {
  height: 85vh;
  padding: 0;
  background-color: black;
}

.mobile-episode-page .comments-column {
  background-color: #2c2c2c;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 4px;
}
.mobile-episode-page .recommended-column {
  background-color: #2c2c2c;
}
</style>
