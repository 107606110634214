<template>
  <v-col cols="auto" class="ma-3" @click="onClick">
    <v-row class="bulletin-item bulletin-left-border-radius bulletin-right-border-radius pa-0" v-bind:style="{'background-image': 'url(' + item.image + ')' }">
      <v-col class="pa-0 bulletin-image-container">
        <v-img :src="item.image" :height="100" :width="100" class="bulletin-image bulletin-left-border-radius d-inline-block ma-0"/>
      </v-col>
      <v-col class="bulletin-content py-0 px-2 d-inline-block">
        <div class="frosted bulletin-background bulletin-right-border-radius"></div>
        <div class="bulletin-item-datetime">
          {{ item.subtitle }}
        </div>
        <div class="bulletin-item-description">
          {{ item.title }}
        </div>
        <div class="ma-0 mt-2">
          <div>
            <v-btn tile @click="buttonClick" small elevation="0">
              {{ item.buttonText }}
            </v-btn>
          </div>
        </div>
        <!-- <v-list-item-subtitle class="sub-title"  v-text="plan.subtitle"></v-list-item-subtitle> -->
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
export default {
  name: 'BulletinItem',
  props: ['item'],
  components: {
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    onClick: function () {
      this.$emit('click');
    },
    buttonClick: function () {
      window.open(this.item.buttonLink, '_blank').focus();
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.bulletin-item {
  background-position: center;
  background-size: cover;
  height: 100px;
}

.bulletin-image-container {
  width: 100px;
  flex: 0;
}
.bulletin-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.bulletin-right-border-radius {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.bulletin-left-border-radius {
  border-bottom-left-radius: 10px !important;
}


.bulletin-content {
  z-index: 1;
  position: relative;
  height: 100%;
  background-color: #202020cc;
}

.bulletin-image {
}

.bulletin-item-datetime {
  font-size: 12px;
  margin-top: 8px;
}

.bulletin-item-description {

}

.frosted {
  backdrop-filter: blur(4px);
}
</style>
