<template>
  <v-container fill-height>
    <v-row class="livestream-header">
      <v-col class="text-center display-1">
        <div>Livestream</div>
      </v-col>
    </v-row>
    <v-row class="livestream" justify="center" v-if="livestream">
      <v-col cols="7" class="fill-height">
        <v-layout wrap>
          <v-flex xs12>
            <v-text-field v-model="livestream.url" placeholder="Url"/>
          </v-flex>
          <v-flex xs12>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-row>
            <v-switch v-model="livestream.online" label="Online" class="px-6"></v-switch>
            <v-col cols="2" offset="10">
              <v-btn color="primary" right tile @click="attemptSave">
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { EventBus } from '../../../eventBus.js';
import { Api } from '../../../js/api';
export default {
  name: 'Livestream',
  props: [],
  components: {
  },
  data: () => ({
    livestream: null,
    saving: false
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    getLivestream: function () {
      let scope = this;
      Api.get('AdminLivestream/livestream').then(function (res) {
        scope.livestream = res.data;
      })
    },
    attemptSave: function () {
      let scope = this;
      let data = scope.livestream;
      scope.saving = true;
      Api.post('AdminLivestream/update', data).then(function (res) {
        if (res.valid) {
          EventBus.$emit('alert', {
            text: 'Livestream updated',
            alertColor: 'green',
            alertIcon: 'success'
          })
        }
        scope.saving = false;
      })
    }
  },
  mounted () {
    this.getLivestream();
  }
}
</script>
<style lang="scss" scoped>
.livestream-header {
  height: 64px;
}
.livestream {
  height: calc(100% - 64px);
}
</style>
