<template>
  <div class="show-banner-container">
    <div class="show-banner">
      <v-img v-if="bannerImage" :src="bannerImage" class="banner-image"></v-img>
      <v-flex xs12 class="show-title-text px-6">
        <v-flex xs12 md6 class="show-subtitle text-center">{{show.subtitle}}</v-flex>
        <v-flex xs12 md6 class="show-title text-center">{{show.title}}</v-flex>
        <v-flex xs12 class="text-center my-4">
          <v-btn v-if="mobile" color="white" class="black--text text-capitalize pa-2" @click="$emit('startWatching')" fab>
            <v-icon class="fa-rotate-90 pa-2" small>mdi-triangle</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs12 md6 class="text-center show-tag-list">{{show.tagList}}</v-flex>
        <v-flex xs12 md6 class="show-description" v-html="show.description"></v-flex>
        <v-btn v-if="!mobile" color="white" class="black--text text-capitalize pa-2" @click="$emit('startWatching')">
          <v-icon class="fa-rotate-90 pa-2" small>mdi-triangle</v-icon>
          Start Watching
        </v-btn>
      </v-flex>
      <v-btn v-if="mobile" class="ma-2 mobile-close-button" icon tile @click="$emit('close')" ><v-icon x-large>mdi-chevron-down</v-icon></v-btn>
      <v-btn v-else class="close-button" icon tile @click="$emit('close')" ><v-icon>mdi-close</v-icon></v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ShowBanner',
  props: ['show'],
  components: {
  },
  data: () => ({
  }),
  computed: {
    mobile () {
      return this.$store.getters.getIsMobile;
    },
    bannerImage () {
      if (this.show.bannerImage) {
        return this.show.bannerImage;
      }
      if (this.show.image) {
        return this.show.image;
      }
      return '';
    }
  },
  watch: {
  },
  methods: {
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>

.show-banner {
  width: 100%;
  position: relative;
  background-color: #202020;
}
.mobile-close-button {
  position: absolute;
  left: 0;
  top: 0;
}
.close-button {
  position: absolute;
  background-color: black;
  right: 0;
  top: 0;
}
.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-mask-image: -webkit-radial-gradient(circle at bottom left, #00000000 30%, #000000ff 100%);
  mask-image: radial-gradient(circle at bottom left, #00000000 30%, #000000ff 100%);
}
.show-title-text {
  padding-top: 30%;
  padding-bottom: 16px;
  position: relative;
}
.show-author {
  font-size: 16px;
}
.show-title {
  font-size: 48px;
}
.show-tag-list {
  font-size: 12px;
  color: grey;
  margin-bottom: 16px;
}
.show-description {
  font-size: 12px;
  margin-bottom: 16px;
  letter-spacing: initial;
}

.disrupt-mobile .show-title-text {
  top: 20%;
}
</style>
