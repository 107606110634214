<template>
  <v-dialog v-model="dialog" width="800">
    <v-card>
      <v-card-title>
      </v-card-title>
      <v-card-text>
        <div class="show-list" v-if="!pickingEpisode">
          <div class="show pa-4 cursor-pointer" v-for="(show, n) in shows" :key="show.id" @click="selectShow(show)"
            v-bind:class="{ 'selected-show': isSelected(show) }">
            <v-img
              :src="show.image"
              :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
              :aspect-ratio="aspect"
              :height="height"
              :max-height="height"
              :width="width"
              :max-width="width"
              class="grey lighten-2"/>
            <div class="pa-2">
              {{ show.title }}
            </div>
          </div>
        </div>
        <div class="show-list" v-else>
          <div class="show pa-4 cursor-pointer" v-for="(episode, n) in episodes" :key="episode.id" @click="selectEpisode(episode)"
            v-bind:class="{ 'selected-show': isSelected(episode) }">
            <v-img
              :src="episode.image"
              :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
              :aspect-ratio="aspect"
              :height="height"
              :max-height="height"
              :width="width"
              :max-width="width"
              class="grey lighten-2"/>
            <div class="pa-2">
              {{ 'S' + episode.seasonNumber + ' E' + episode.episodeNumber }} {{ episode.name }}
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn tile @click="cancel">
          Cancel
        </v-btn>
        <v-btn tile :disabled="!(selectedShow && !pickingEpisode)" @click="pickEpisode">
          Pick Episode
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn tile color="primary" @click="confirm" :disabled="(!selectedShow && !selectedEpisode) || (!pickingEpisode && cantPickShow)">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
    
  </v-dialog>
</template>
<script>
export default {
  name: 'ShowPicker',
  props: ['shows', 'selectableShows'],
  components: {
  },
  data: () => ({
    dialog: false,
    selectedShow: null,
    height: 120,
    aspect: 16/9,
    episodes: [],
    pickingEpisode: false,
    selectedEpisode: null
  }),
  computed: {
    width () {
      return this.aspect * this.height;
    },
    canConfirm () {
      return this.selectedShow != null
    },
    cantPickShow () {
      if (this.selectedShow != null) {
        return this.selectableShows.find(s => s.id == this.selectedShow.id) == null;
      }
      return false;
    }
  },
  watch: {
  },
  methods: {
    reset: function () {
      this.pickingEpisode = false;
      this.selectedShow = null;
      this.selectedEpisode = null;
    },
    selectShow: function (show) {
      this.selectedShow = show;
    },
    selectEpisode: function (episode) {
      this.selectedEpisode = episode
    },
    cancel: function () {
      this.dialog = false;
      this.reset();
    },
    confirm: function () {
      let data = {
        showId: null,
        episodeId: null
      }

      if (this.selectedShow) {
        data.showId = this.selectedShow.id
      }
      if (this.selectedEpisode) {
        data.episodeId = this.selectedEpisode.id
      }
      this.$emit('selected', data)
      this.dialog = false;
      this.reset();
    },
    isSelected: function (item) {
      if (this.selectedShow) {
        if(this.selectedShow.id == item.id) {
          return true;
        }
      }
      if (this.selectedEpisode) {
        if(this.selectedEpisode.id == item.id) {
          return true;
        }
      }
      return false;
    },
    pickEpisode: function () {
      let scope = this;
      scope.pickingEpisode = true;
      scope.$store.dispatch('getEpisodes', scope.selectedShow.id).then(function (episodes) {
        scope.episodes = episodes;
      })
    }
  },
  mounted () {
    this.reset();
  }
}
</script>
<style lang="scss" scoped>
.show-list {
  height: 500px;
  overflow-y: auto;
}
.show {
  height: 180px;
  display: inline-grid;
}
.selected-show {
  background-color: #ffffff22;
}
</style>
