let api = {
  induceDelay: false,
  proxy: false,
  proxyEndpoints: [],
  get: function (path) {
    return this.fetchMiddleWare('GET', path, null);
  },
  post: function (path, data) {
    return this.fetchMiddleWare('POST', path, JSON.stringify(data));
  },
  upload: function (path, file) {
    let formData = new FormData();
    formData.append('files', file)
    return this.fetchMiddleWare('UPLOAD', path, formData);
  },
  fetchMiddleWare: function (method, path, data) {
    let self = this;
    return new Promise((resolve) => {
      if (self.proxy) {
        let found = false;
        self.proxyEndpoints.forEach(function(endpoint) {
          if (path.includes(endpoint.path)) {
            resolve(endpoint.response)
            found = true;
            return;
          }
        })
        if(found) {
          return;
        }
      }

      self.makeRequest(method, path, data).then(function (res) {
        if (self.induceDelay) {
          setTimeout(function () {
            resolve(res)
          }, 1000)
        } else {
          resolve(res);
        }
      })
    })
  },
  makeRequest(method, path, data) {
    return new Promise((resolve, reject) => {
      let url = process.env.VUE_APP_SERVER_URL + path;
      let myRequest = null;

      if (method === 'GET') {
        const myHeaders = new Headers();
        myRequest = new Request(url, {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'include',
        });
      } else if (method === 'POST') {
        myRequest = new Request(url, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: data
        });
      } else if (method === 'UPLOAD') {
        myRequest = new Request(url, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'include',
          body: data
        });
      }

      fetch(myRequest)
      .then(function(response) {
          if (!response.ok) {
            reject(response.statusText);
          } else {
            resolve(response.json());
          }
        }).then(function(response) {
          resolve(response.json());
        }).catch(function(error) {
          reject(error);
        });
    })
  }
};

export const Api = api;