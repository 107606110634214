<template>
  <v-container fill-height>
    <v-row class="keys-title">
      <v-col class="text-center display-1">
        <div>Game Keys</div>
      </v-col>
    </v-row>
    <v-row class="key-stats" v-if="dashboard">
      <v-col cols="12">
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="4">
              <v-card>
                <v-card-title>
                  Available Keys
                </v-card-title>
                <v-card-subtitle>
                  the amount of keys in the system that have not been redeemed
                </v-card-subtitle>
                <v-card-text class="key-stat">
                  {{ dashboard.availableKeys }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3"> 
              <v-card>
                <v-card-title>
                  Redeemed Keys
                </v-card-title>
                <v-card-subtitle>
                  redeemed keys in the last 30 days
                </v-card-subtitle>
                <v-card-text class="key-stat">
                  {{ dashboard.redeemedKeys }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3"> 
              <v-card>
                <v-card-title>
                  Eligible Accounts
                </v-card-title>
                <v-card-subtitle>
                  accounts that have the key redeeming feature
                </v-card-subtitle>
                <v-card-text class="key-stat">
                  {{ dashboard.eligibleAccounts }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="6">
              <v-toolbar class="user-toolbar elevation-0">
                <v-spacer />
                <v-text-field v-model="search" light class="key-search-bar mx-4" placeholder="search" single-line hide-details tile solo/>
                <v-btn @click="showImportDialog" color="primary" tile>
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-spacer />
              </v-toolbar>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="keys-list pa-4 pt-0 mt-0" v-if="dashboard">
      <v-col cols="6">
        Available Keys
        <v-simple-table fixed-header height="450px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Code
                </th>
                <th class="text-left">
                  Created Date
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="key in availableKeys" :key="key.id">
                <td>{{ key.code }}</td>
                <td>{{ moment(key.createdAt).format('YYYY MMM Do') }}</td>
                <td class="text-right">
                  <v-btn icon @click="showDeleteKeyDialog(key)">
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        Redeemed Keys (Last 30 days)
        <v-simple-table fixed-header height="450px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Code
                </th>
                <th class="text-left">
                  Created Date
                </th>
                <th class="text-left">
                  Redeemed Date
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="key in usedKeys" :key="key.id">
                <td>{{ key.code }}</td>
                <td>{{ moment(key.createdAt).format('YYYY MMM Do') }}</td>
                <td>{{ moment(key.redeemedDate).format('YYYY MMM Do') }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-dialog v-model="importKeysDialog" persistent width="600">
      <v-card>
        <v-card-title>
          Import Keys
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 class="px-2">
              <v-textarea
                :height="500"
                :full-width="true"
                hint="1 key per line"
                v-model="importKeysList"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="importKeysDialog = false" :disabled="disabled" color="primary" tile>Cancel</v-btn>
          <v-spacer/>
          <div class="pa-4">{{ importMessage }}</div>
          <v-btn @click="validateKeys" :disabled="disabled || keysValidated" color="primary" tile>Validate</v-btn>
          <v-btn @click="importKeys" :disabled="disabled || !keysValidated" color="primary" tile>Import</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteKeysDialog" persistent width="600">
       <v-card>
        <v-card-title>
          Delete Key
        </v-card-title>
        <v-card-text>
          Are you sure you would like to delete this key?
          {{ deletingKey ? deletingKey.code : '' }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteKeysDialog = false" :disabled="disabled" color="primary" tile>Cancel</v-btn>
          <v-spacer/>
          <v-btn @click="deleteKey" :disabled="disabled" color="primary" tile>Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { Api } from '../../../js/api'
import moment from 'moment'

export default {
  name: 'Keys',
  components: {
  },
  data: () => ({
    disabled: false,
    importKeysDialog: false,
    keysValidated: false,
    dashboard: null,
    keys: [],
    importKeysList: '',
    importMessage: '',
    deleteKeysDialog: false,
    deletingKey: null,
    search: ''
  }),
  computed: {
    moment () {
      return moment;
    },
    availableKeys () {
      return this.dashboard.keysAvailable.filter(k => k.code.toLowerCase().includes(this.search.toLowerCase()));
    },
    usedKeys () {
      return this.dashboard.keysRedeemed.filter(k => k.code.toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  watch: {
    importKeysList: function () {
      this.keysValidated = false;
    }
  },
  methods: {
    getDashboard: function () {
      let scope = this;
      Api.get('AdminRewards/getDashboard').then(function (res) {
        scope.dashboard = res.data;
      })
    },
    showImportDialog: function () {
      let scope = this;
      scope.importKeysDialog = true;
      scope.importKeysList = '';
    },
    showDeleteKeyDialog: function (gameKey) {
      let scope = this;
      scope.deletingKey = gameKey;
      scope.deleteKeysDialog = true;
    },
    deleteKey: function () {
      let scope = this;
      let data = scope.deletingKey.id;
      
      scope.disabled = true;
      
      Api.post('AdminRewards/deleteKey', data).then(function () {
        scope.disabled = false;
        scope.deleteKeysDialog = false;
        scope.getDashboard();
      })
    },
    validateKeys: function () {
      let scope = this;
      scope.disabled = true;
      Api.post('AdminRewards/validateKeys', this.importKeysList).then(function (res) {
        scope.disabled = false;
        if (res.valid) {
          scope.keysValidated = true;
          scope.importMessage = res.data + ' keys found ';
        } else {
          scope.keysValidated = false;
          scope.importMessage = res.data;
        }
      })
    },
    importKeys: function () {
      let scope = this;
      scope.disabled = true;
      Api.post('AdminRewards/importKeys', this.importKeysList).then(function () {
        scope.getDashboard();
        scope.disabled = false;
        scope.importKeysDialog = false;
      })
    }
  },
  mounted () {
    this.getDashboard();
  }
}
</script>
<style lang="scss" scoped>
.keys-title {
  height: 64px;
}
.key-stats {
  height: 128px;
}
.keys-list {
  height: calc(100% - 64px - 64px - 128px -32px);
}
.key-stat {
  font-size: 32px;
  color: white !important;
}
</style>
<style lang="scss">
.key-search-bar {
  max-width: 300px;
}
.key-search-bar .v-input__slot{
  border-radius: 0px !important;
}
</style>