<template>
  <div v-if="showEpisodeComponent" class="desktop-episode-page">
    <episode />
  </div>
</template>
<script>
import episode from '../views/Episode.vue'

import { EventBus } from '../eventBus';

export default {
  name: 'EpisodePage',
  props: [],
  components: {
    episode
  },
  data: () => ({
    showEpisodeComponent: false
  }),
  computed: {
    episodeId () {
      return this.$route.params.episodeId;
    },
    isMobile () {
      return this.$store.getters.getIsMobile;
    }
  },
  watch: {
  },
  methods: {
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    popout: function () {
      // try the popout view
      EventBus.$emit('popout', {
        contentType: 'episode',
        contentId: this.episodeId
      });
    }
  },
  mounted () {
    if (this.isMobile) {
      if (!this.$store.getters.getPopoutOpen) {
        this.popout();
      }
    } else {
      this.showEpisodeComponent = true;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    }
  }
}
</script>
<style lang="scss" scoped>
.desktop-episode-page {
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
