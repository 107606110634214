<template>
  <v-container fill-height>
    <v-row class="promotional-header">
      <v-col class="text-center display-1">
        <div>Promotional</div>
      </v-col>
    </v-row>
    <v-row class="promotional">
      <v-col cols="4" class="fill-height promotional-list">
        <v-list>
          <v-list-item @click="selectPage(carouselPage)" v-for="carouselPage in carouselPages" :key="carouselPage.id" v-bind:class="{ 'selected-page': isSelected(carouselPage) }">
            <v-list-item-title>{{ carouselPage.title }}</v-list-item-title>
            <v-list-item-action>
              <v-btn v-if="carouselPages.indexOf(carouselPage) > 0" icon @click="moveUp(carouselPage)">
                <v-icon>
                  mdi-arrow-up
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn v-if="carouselPages.indexOf(carouselPage) < carouselPages.length - 1" icon @click="moveDown(carouselPage)">
                <v-icon>
                  mdi-arrow-down
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn icon @click="deletePage(carouselPage)">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="createPage">
            <v-list-item-title>New</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="8" class="fill-height" v-if="currentPage">
        <carousel :items="[currentPage]" :preventNavigate="true"/>
        <v-layout wrap>
          <v-flex xs12>
            <v-text-field v-model="currentPage.title" placeholder="title"/>
          </v-flex>
          <v-flex xs4>
            <v-text-field v-model="currentPage.subtitle" placeholder="subtitle"/>
          </v-flex>
          <v-flex xs4 class="px-2">
            <v-text-field v-model="currentPage.banner" placeholder="banner"/>
          </v-flex>
          <v-flex xs4>
            <v-text-field v-model="currentPage.link" placeholder="link"/>
          </v-flex>
          <v-flex>
            <imageUploader v-if="currentPage" ref="carouselImage" v-model="currentPage.imageId" :url="currentPage.image" placeholder="/icons/upload-image.png" @change="updateImage" :width="300" :height="168" :aspect="16/9"/>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-row>
            <v-col cols="2" offset="10">
              <v-btn color="primary" right tile :disabled="saving" @click="attemptSave">
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { EventBus } from '../../../eventBus.js';
import { Api } from '../../../js/api';

import carousel from '../../content/carousel/carousel';
import imageUploader from '../../global/imageUploader';
export default {
  name: 'Promotional',
  components: {
    carousel,
    imageUploader
  },
  data: () => ({
    saving: false,
    carouselPages: [],
    currentPage: null
  }),
  computed: {
    isNew () {
      if (this.currentPage && this.currentPage.id) {
        return false;
      } 
      return true;
    }
  },
  methods: {
    updateImage: function (image) {
      this.currentPage.image = image;
    },
    getCarouselPages: function () {
      let self = this

      self.loading = true;
      Api.get('AdminCarousel/all').then(function (res) {
        self.carouselPages = res.data;

        if (!self.currentPage) {
          self.selectPage(self.carouselPages[0]);
        }
        self.loading = false;
      })
    },
    isSelected: function (page) {
      if (this.currentPage) {
        return this.currentPage.id == page.id;
      }
      return false;
    },
    selectPage: function(page) {
      this.currentPage = page;
    },
    createPage: function () {
      let self = this;
      self.currentPage = {
        title: 'Title',
        subtitle: 'Subtitle',
        banner: 'Banner',
        link: '',
        image: '',
        imageId: null
      }
    },
    deletePage: function (page) {
      let scope = this;
      let data = {
        id: page.id
      };

      Api.post('AdminCarousel/delete', data).then(function (res) {
        scope.dialog = false;

        if (res.valid) {
          EventBus.$emit('alert', {
            text: 'Carousel page deleted',
            alertColor: 'green',
            alertIcon: 'success'
          })
        }

        scope.getCarouselPages();
        scope.$emit('complete');
      })
    },
    moveUp: function (page) {
      let pageIndex = this.carouselPages.indexOf(page);
      let swapIndex = pageIndex - 1;
      let swapPage = this.carouselPages[swapIndex];

      this.swapPages(page, swapPage);
    },
    moveDown: function (page) {
      let pageIndex = this.carouselPages.indexOf(page);
      let swapIndex = pageIndex + 1;
      let swapPage = this.carouselPages[swapIndex];

      this.swapPages(page, swapPage);
    },
    swapPages: function (page1, page2) {
      let self = this;
      let data = {
        page1: page1.id,
        page2: page2.id
      }
      Api.post('AdminCarousel/swapPages', data).then(function () {
        self.getCarouselPages();
      })
    },
    attemptSave: function () {
      let self = this;
      self.saving = true;

      if (self.$refs.carouselImage.file) {
        // upload the file first
        self.$refs.carouselImage.upload().then(function () {
          self.save();
        })
      } else {
        self.save();
      }
    },
    save: function () {
      let scope = this;

      if (scope.currentPage) {
        let data = scope.currentPage;

        if (scope.isNew) {
          Api.post('AdminCarousel/create', data).then(function (res) {
            scope.saving = false;

            if (res.valid) {
              EventBus.$emit('alert', {
                text: 'Carousel created',
                alertColor: 'green',
                alertIcon: 'success'
              })
            }

            scope.$emit('complete', res.data);
            scope.getCarouselPages();
          })
        } else {
          Api.post('AdminCarousel/update', data).then(function (res) {
            scope.saving = false;

            if (res.valid) {
              EventBus.$emit('alert', {
                text: 'Carousel updated',
                alertColor: 'green',
                alertIcon: 'success'
              })
            }

            scope.$emit('complete', res.data);
            scope.getCarouselPages();
          })
        }
      }
    }
  },
  mounted () {
    this.getCarouselPages();
  }
}
</script>
<style lang="scss" scoped>
.promotional-header {
  height: 64px;
}
.promotional {
  height: calc(100% - 64px);
}
</style>
