<template>
  <v-row v-if="content">
    <v-col cols="2">
      <v-avatar>
        <img :src="image">
      </v-avatar>
      {{ content.username }}
    </v-col>
    <v-col cols="8">
      {{ content.text }}
    </v-col>
    <v-col cols="2">
      <v-btn @click="deleteIdea" icon>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { Api } from '../../../js/api';
export default {
  name: 'ContentIdea',
  props: ['contentIdea'],
  components: {
  },
  data: () => ({
    content: null
  }),
  computed: {
    image () {
      if (this.content.userImage) {
        return this.content.userImage;
      }
      return "/icons/user.png";
    }
  },
  watch: {
  },
  methods: {
    deleteIdea: function () {
      let scope = this;

      let data = {
        contentIdeaId: scope.contentIdea.id
      }

      Api.post('AdminInvestorContentIdeas/delete', data).then(function () {
        scope.content = null;
      })
    }
  },
  mounted () {
    this.content = this.contentIdea;
  }
}
</script>
<style lang="scss" scoped>
</style>
