<template>
  <v-container fill-height>
    <v-row class="finance-title">
      <v-col class="text-center display-1">
        <div>Financial Reports</div>
      </v-col>
    </v-row>
    <v-row class="finance-list pa-4">
      <v-col cols="3">
        <v-list>
          <v-list-item @click="selectReport(report)" v-for="report in reports" :key="report.id" v-bind:class="{ 'selected-report': isSelected(report) }">
            <v-list-item-title>{{ report.year }}</v-list-item-title>
            <v-list-item-action>
              <v-btn icon @click="editReport(report)">
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn icon @click="tryDeleteReport(report)">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="createReport">
            <v-list-item-title>New</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="9">
         <v-card v-if="selectedReport">
          <v-card-title>
          </v-card-title>
          <v-card-text>
            <v-text-field type="number" v-model="selectedReport.year" :disabled="saving" placeholder="Year" label="Year" class=""></v-text-field>
            <v-layout>
              <v-flex xs4 class="pr-2">
                <v-text-field type="number" v-model="selectedReport.profit" :disabled="saving" placeholder="Net Profit" label="Net Profit ($)" class=""></v-text-field>
              </v-flex>
              <v-flex xs4 class="px-2">
                <v-text-field type="number" v-model="selectedReport.sharedProfitPercent" :disabled="saving" placeholder="20" label="Shared Profit Percent (%)" class=""></v-text-field>
              </v-flex>
              <v-flex xs4 class="pl-2">
                <v-text-field disabled :value="calculatedDividends" placeholder="Shareholder Dividends" label="Shareholder Dividends ($)" class=""></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex xs4 class="pr-2">
                <v-text-field type="number" v-model="selectedReport.disruptShares" :disabled="saving" placeholder="10000000" label="Disrupt Shares" class=""></v-text-field>
              </v-flex>
              <v-flex xs4 class="px-2">
                <v-text-field type="number" v-model="selectedReport.commonShares" :disabled="saving" placeholder="1000000" label="Common Shares" class=""></v-text-field>
              </v-flex>
              <v-flex xs4 class="pl-2">
                <v-text-field type="number" :disabled="!errorAllocated" :error="errorAllocated" readonly v-model="selectedReport.allocatedShares" placeholder="1000000" label="Allocated Shares" class=""></v-text-field>
              </v-flex>
            </v-layout>

            <v-text-field v-model="selectedReport.link" :disabled="saving" placeholder="Detailed Financial Statement Link" label="Detailed Financial Statement Link" class=""></v-text-field>

            <v-menu :close-on-content-click="false" :close-on-click="true">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="selectedReport.payoutDate ? 'Shareholder Payout Date: ' + formattedDate : 'Shareholder Payout Date'"
                  label="Payout Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-if="selectedReport" v-model="selectedReport.payoutDate"></v-date-picker>
            </v-menu>
            <v-textarea v-model="selectedReport.progressMessage" :disabled="saving" placeholder="In progress update message" class=""></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-switch v-model="selectedReport.inProgress" :disabled="saving" label="In progress year" class="px-6"></v-switch>
            <v-btn tile color="primary" @click="save" :disabled="saving">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="deleteReportDialog" persistent width="600">
      <v-card v-if="reportToDelete">
        <v-card-title>
          Delete Finance Report
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this Finance Report?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteReportDialog = false" :disabled="deletingReport" color="primary" tile>Cancel</v-btn>
          <v-spacer/>
          <v-btn @click="deleteReport(reportToDelete)" :disabled="deletingReport" color="primary" tile>Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

import { Api } from '../../../js/api';
import moment from 'moment'

export default {
  name: 'FinanicalReports',
  props: [],
  components: {
  },
  data: () => ({
    reports: [],
    selectedReport: null,
    dateOpen: false,
    saving: false,
    reportToDelete: null,
    deleteReportDialog: false,
    deletingReport: false
  }),
  computed: {
    isNew () {
      if(this.selectedReport && this.selectedReport.id) {
        return false;
      }
      return true;
    },
    formattedDate () {
      return new moment(this.selectedReport.payoutDate, 'YYYY-MM-DDTHH:mm:ss').format('yyyy-MM-DD')
    },
    calculatedDividends () {
      if (this.selectedReport) {
        return this.selectedReport.profit * (this.selectedReport.sharedProfitPercent / 100.0)
      }
      return null
    },
    errorAllocated () {
      if (this.selectedReport.allocatedShares > this.selectedReport.commonShares) {
        return true;
      }
      return false;
    }
  },
  watch: {
  },
  methods: {
    selectReport: function (financeReport) {
      if (financeReport.payoutDate != null) {
        financeReport.payoutDate = new moment(financeReport.payoutDate, 'YYYY-MM-DDTHH:mm:ss').format('yyyy-MM-DD')
      } else {
        financeReport.payoutDate = null
      }
      this.selectedReport = financeReport;
    },
    editReport: function (financeReport) {
      this.selectReport(financeReport);
    },
    tryDeleteReport: function (financeReport) {
      let scope = this
      scope.reportToDelete = financeReport
      scope.deleteReportDialog = true
    },
    deleteReport: function () {
      let scope = this;
      scope.deletingReport = true;

      let data = {
        financeReportId: scope.reportToDelete.id
      }
      Api.post('AdminInvestorFinance/delete', data).then(function () {
        scope.deleteReportDialog = false;
        scope.deletingReport = false;
        scope.getData();
      })
    },
    createReport: function () {
      this.selectedReport = {}
    },
    isSelected: function (financeReport) {
      if (this.selectedReport && this.selectedReport.id == financeReport.id) {
        return true
      }
      return false
    },
    getData: function () {
      let scope = this;
      Api.get('AdminInvestorFinance/all').then(function (res) {
        scope.reports = res.data
      })
    },
    save: function () {
      let scope = this;
      let data = this.selectedReport;

      data.dividends = scope.calculatedDividends;

      scope.saving = true;

      if (!this.isNew) {
        Api.post('AdminInvestorFinance/Update', data).then(function () {
          scope.saving = false;
          scope.getData();
        })
      } else {
        Api.post('AdminInvestorFinance/Create', data).then(function () {
          scope.saving = false;
          scope.getData();
        })
      }
    }
  },
  mounted () {
    let scope = this;
    scope.getData();
  }
}
</script>
<style lang="scss" scoped>
.finance-title {
  height: 100px;
}
.finance-list {
  height: calc(100% - 100px);
}
</style>
