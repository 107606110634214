<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="pa-4">
          <v-flex xs2>
            <div>
              Financial Report
            </div>
          </v-flex>
          <v-flex xs2>
            <v-select v-model="selectedFinancialReport" return-object item-text="year" :items="financeReports"></v-select>
          </v-flex>
        </div> 
      </v-col>
      <v-col cols="2" v-if="selectedFinancialReport">
        <div class="px-4">
          <v-text-field readonly type="number"  label="Common Shares" :value="selectedFinancialReport.commonShares"></v-text-field>
          <v-text-field readonly type="number" :error="errorAllocated" label="Allocated Shares" :value="selectedFinancialReport.allocatedShares"></v-text-field>
        </div>
      </v-col>
      <v-col cols="5" v-if="selectedFinancialReport">
        <div class="px-4">
          <v-layout row wrap>
            <v-flex xs2>
              <v-text-field readonly label="Profit" :value="'$' + selectedFinancialReport.profit"></v-text-field>
            </v-flex>
            <v-flex xs1 class="pt-4 text-center">x</v-flex>
            <v-flex xs3>
              <v-text-field readonly label="Shared Profit Percent" :value="selectedFinancialReport.sharedProfitPercent + '%'"></v-text-field>
            </v-flex>
            <v-flex xs1 class="pt-4 text-center">=</v-flex>
            <v-flex xs4>
              <v-text-field readonly label="Shareholder Dividends" :value="'$' + totalShareholderDividends"></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs3>
              <v-text-field readonly label="Single Share Dividend" :value="'$' + shareWorthInDividends"></v-text-field>
            </v-flex>
            <v-flex xs1 class="pt-4 text-center">x</v-flex>
            <v-flex xs2>
              <v-text-field readonly type="number" label="Allocated Shares" :value="selectedFinancialReport.allocatedShares"></v-text-field>
            </v-flex>
            <v-flex xs1 class="pt-4 text-center">=</v-flex>
            <v-flex xs3>
              <v-text-field readonly label="Payable Dividends" :value="'$' + (selectedFinancialReport.allocatedShares * shareWorthInDividends).toFixed(2)"></v-text-field>
            </v-flex>
          </v-layout>
        </div>
      </v-col>
    </v-row>
    <v-toolbar class="user-toolbar elevation-0">
      <div class="montserrat display-1">Shareholders</div>
      <v-spacer/>
      <v-text-field v-model="search" light class="user-search-bar mx-4" placeholder="search" single-line hide-details tile solo/>
    </v-toolbar>
    <v-data-table 
      :headers="headers"
      :items="users"
      :search="search"
      item-key="id"
      :options="options"
      :footer-props="footerOptions"
      class="users-table elevation-1"
      :loading="loading"
    >
      <template v-slot:[`item.shares`]="{ item }">
        {{ getSharesField(item) }}
      </template>
      <template v-slot:[`item.dividend`]="{ item }">
        {{ getDividendField(item) }}
      </template>
      <!-- <template v-slot:[`item.payoutMethod`]="{ item }">
        {{ item.paymentPreferences ? item.paymentPreferences.paymentMethod : '' }}
      </template>
      <template v-slot:[`item.payoutDetails`]="{ item }">
        {{ item.paymentPreferences ? item.paymentPreferences.paymentDetails : '' }}
      </template> -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="editUserShares(item)">
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit Shares</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <shareholderDialog :financeReports="financeReports"/>
  </v-container>
</template>
<script>

import { EventBus } from '../../../eventBus.js';
import { Api } from '../../../js/api';

import shareholderDialog from './shareholderDialog.vue'

export default {
  name: 'Shareholders',
  props: [],
  components: {
    shareholderDialog
  },
  data: () => ({
    selectedFinancialReport: null,
    financeReports: [],
    users: [],
    search: '',
    loading: false,
    options: {
      itemsPerPage: 14
    },
    footerOptions: {
      'items-per-page-options': [14, 50, 100]
    },
    headers: [
      { text: 'Name', align: 'start', value: 'username' },
      { text: 'Email', value: 'email' },
      { text: 'Shares', value: 'shares' },
      { text: 'Year Dividend', value: 'dividend' },
      { text: 'Payout Method', value: 'paymentPreferences.paymentMethod' },
      { text: 'Payout Details', value: 'paymentPreferences.paymentDetails' },
      { text: 'Actions', value: 'actions', sortable: false, width: 200 }
    ],
  }),
  computed: {
    totalShareholderDividends () {
      if (this.selectedFinancialReport) {
        return this.selectedFinancialReport.profit * (this.selectedFinancialReport.sharedProfitPercent / 100)
      }
      return 0;
    },
    shareWorthInDividends () {
      if (this.selectedFinancialReport) {
        if (this.selectedFinancialReport.commonShares > 0) {
          return this.totalShareholderDividends / this.selectedFinancialReport.commonShares;
        }
      }
      return 0;
    },
    errorAllocated () {
      if (this.selectedFinancialReport.allocatedShares > this.selectedFinancialReport.commonShares) {
        return true;
      }
      return false;
    }
  },
  watch: {
  },
  methods: {
    getSharesField: function (item) {
      if (this.selectedFinancialReport) {
        let data = item.investorDatas.find(d => d.financeReportId == this.selectedFinancialReport.id);
        if (data) {
          return data.shares;
        }
      }
      return ''
    },
    getDividendField: function (item) {
      if (this.selectedFinancialReport) {
        let data = item.investorDatas.find(d => d.financeReportId == this.selectedFinancialReport.id);
        if (data) {
          return '$' + data.dividend.toFixed(2);
        }
      }
      return ''
    },
    addInvestor: function () {

    },
    editUserShares: function (user) {
      // let scope = this;

      EventBus.$emit('editUserSharesDialog', user)
    },
    getFinanceReports: function () {
      let scope = this;

      Api.get('AdminInvestorFinance/all').then(function (res) {
        scope.financeReports = res.data
        if (scope.financeReports.length > 0) {
          scope.selectedFinancialReport = scope.financeReports[0]
        }
      })
    },
    getUsers: function () {
      let scope = this;

      Api.get('AdminInvestorUser/all').then(function (res) {
        scope.users = res.data;
      })
    }
  },
  mounted () {
    this.getFinanceReports()
    this.getUsers()
  }
}
</script>
<style lang="scss" scoped>
</style>
