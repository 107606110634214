<template>
  <v-container fill-height>
    <v-row class="coupon-title">
      <v-col class="text-center display-1">
        <div>Coupons</div>
      </v-col>
    </v-row>
    <v-row class="coupon-actions pa-4 text-right">
      <v-col cols="12">
        <v-btn @click="showCreateCouponDialog" color="primary" tile>
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="coupon-list pa-4">
      <v-col cols="6">
        Multi-use Coupons
        <v-simple-table
          fixed-header
          height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Code
                </th>
                <th class="text-left">
                  Expiry Date
                </th>
                <th class="text-left">
                  Days
                </th>
                <th class="text-left">
                  Uses
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="coupon in multiUseCoupons" :key="coupon.id" @click="couponClicked(coupon)">
                <td>{{ coupon.code }}</td>
                <td>{{ moment(coupon.expiryDate).format('YYYY MMM Do') }}</td>
                <td>{{ coupon.days }}</td>
                <td>{{ coupon.uses }}</td>
                <td class="text-right">
                  <v-btn icon @click="showDeleteCouponDialog(coupon)">
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        Single-use Coupons
        <v-simple-table
          fixed-header
          height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Code
                </th>
                <th class="text-left">
                  Expiry Date
                </th>
                <th class="text-left">
                  Days
                </th>
                <th class="text-left">
                  Used
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="coupon in singleUseCoupons" :key="coupon.id" @click="couponClicked(coupon)">
                <td>{{ coupon.code }}</td>
                <td>{{ moment(coupon.expiryDate).format('YYYY MMM Do') }}</td>
                <td>{{ coupon.days }}</td>
                <td>{{ coupon.uses == 1 }}</td>
                <td class="text-right">
                  <v-btn icon @click="showDeleteCouponDialog(coupon)">
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-dialog v-model="createCouponDialog" persistent width="600">
      <v-card v-if="newCoupon">
        <v-card-title>
          Create Coupon
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs6 class="px-2">
              <v-text-field label="Code" v-model="newCoupon.code" />
            </v-flex>
            <v-flex xs6 class="px-2">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  Last Use By Date: 
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    text
                  >
                    {{ moment(newCoupon.expiryDate).format('YYYY MMM Do') }}
                  </v-btn>
                </template>
                <v-date-picker v-model="newCoupon.expiryDate"></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs6 class="px-2">
              <v-text-field label="Total Days" v-model="newCoupon.days" />
            </v-flex>
            <v-flex xs6 class="px-2">
              <v-checkbox v-model="newCoupon.singleUse" label="Single Use" />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="createCouponDialog = false" :disabled="savingCoupon" color="primary" tile>Cancel</v-btn>
          <v-spacer/>
          <v-btn @click="createCoupon" :disabled="savingCoupon" color="primary" tile>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteCouponDialog" persistent width="600">
      <v-card v-if="couponToDelete">
        <v-card-title>
          Delete Coupon
        </v-card-title>
        <v-card-text>
        Are you sure you want to delete coupon {{ couponToDelete.code }}?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteCouponDialog = false" :disabled="deletingCoupon" color="primary" tile>Cancel</v-btn>
          <v-spacer/>
          <v-btn @click="deleteCoupon(couponToDelete)" :disabled="deletingCoupon" color="primary" tile>Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { Api } from '../../../js/api';
import moment from 'moment'

export default {
  name: 'Coupons',
  components: {
  },
  data: () => ({
    createCouponDialog: false,
    savingCoupon: false,
    newCoupon: {},
    deleteCouponDialog: false,
    deletingCoupon: false,
    couponToDelete: null,
    coupons: []
  }),
  computed: {
    moment () {
      return moment;
    },
    multiUseCoupons () {
      return this.coupons.filter(c => !c.singleUse);
    },
    singleUseCoupons () {
      return this.coupons.filter(c => c.singleUse);
    }
  },
  methods: {
    getCoupons: function () {
      let scope = this;
      Api.get('AdminCoupon/all').then(function (res) {
        scope.coupons = res.data;
      })
    },
    showDeleteCouponDialog: function (coupon) {
      let scope = this;
      scope.couponToDelete = coupon;
      scope.deleteCouponDialog = true;
    },
    deleteCoupon: function(coupon) {
      let scope = this;
      scope.deletingCoupon = true;
      Api.post('AdminCoupon/delete/' + coupon.id).then(function () {
        scope.getCoupons();
        scope.deletingCoupon = false;
        scope.deleteCouponDialog = false;
      })
    },
    showCreateCouponDialog: function () {
      this.createCouponDialog = true;
      let randomCode = ''
      let codeLength = 6;
      
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      var charactersLength = characters.length;
      for (let i = 0; i < codeLength; i++) {
        randomCode = randomCode + characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      this.newCoupon = {
        code: randomCode,
        expiryDate: new moment().add(31, "days").format('YYYY-MM-DD'),
        singleUse: true,
        days: 31
      }
    },
    createCoupon: function() {
      let scope = this;
      scope.savingCoupon = true;

      let data = scope.newCoupon;
      Api.post('AdminCoupon/create', data).then(function () {
        scope.getCoupons();
        scope.savingCoupon = false;
        scope.createCouponDialog = false;
      })
    }
  },
  mounted () {
    this.getCoupons();
  }
}
</script>
<style lang="scss" scoped>
.coupon-title {
  height: 100px;
}
.coupon-actions {
  height: 64px;
}
.coupon-list {
  height: calc(100% - 164px);
}
</style>
