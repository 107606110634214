import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'

import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

Vue.config.productionTip = false

window.addEventListener('error', function(error) {
  store.dispatch('logError', error);
  return false;
})
window.onerror = function (error) {
  store.dispatch('logError', error);
  return false;
}
Vue.config.errorHandler = function (error) {
  store.dispatch('logError', error);
  return false;
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

store.dispatch('updateLoggedIn').then(function (val) {
  if (val) {
    store.dispatch('getUser').then(function (user) {
      if (user) {
        resolveRoute(true);
      } else {
        clearAndResolve();
      }
    }).catch(function () {
      clearAndResolve();
    });
  } else {
    store.dispatch('setLoaded', true);
    resolveRoute(false);
  }
});

function clear () {
  return new Promise((resolve => {
    store.dispatch('clearLoggedIn').then(function () {
      store.dispatch('setLoaded', true).then(function () {
        resolve();
      });
    })
  }))
}

function resolveRoute (loggedIn) {
  let matched = router.match(window.location.pathname);
  if (!loggedIn && !matched.meta.dontRequireLogin) {
    router.push('/');
  }
  store.dispatch('setLoaded', true);
}

function clearAndResolve () {
  clear().then(function() {
    resolveRoute(false)
  });
}
