<template>
  <div>
    <v-row class="episode video-episode cursor-pointer" v-bind:class="{'compact-episode': compact}" @click="clicked">
      <v-col :cols="lcols" class="image-column" v-bind:class="{ 'pa-0': gutterless }">
        <v-hover v-slot="{ hover }" open-delay="100">
          <v-responsive :aspect-ratio="aspect" :height="episodeHeight" :width="episodeWidth" max-width="100%" contain class="episode-image-container" v-bind:class="{ 'up-next-image': upnext }">
            <v-img class="episode-image" :src="image" :lazy-src="lazyImage" :height="episodeHeight" :max-height="episodeHeight" :width="episodeWidth" :max-width="episodeWidth" cover>
              <v-img v-if="hover" class="hover-image" :src="hoverImage" width="64px" height="100%" aspect-ratio="1" contain></v-img>
            </v-img>
            <v-progress-linear v-if="progress > 0.01" color="primary" background-color="rgb(0,0,0,0.5)" :value="progress * 100" height="8" class="progress"></v-progress-linear>
          </v-responsive>
        </v-hover>
        <div v-if="!episode.free && !premium" class="locked-episode-icon"><v-icon>mdi-lock</v-icon></div>
        <div v-if="episode.free && !premium" class="free-episode-banner">Free</div>
      </v-col>
      <v-col :cols="rcols" v-if="!compact" class="text-column">
        <div class="episode-title">{{episode.episodeNumber}}. {{episode.name}}</div>
        <div class="episode-duration">{{runtime}}</div>
        <div v-if="!mobile" class="episode-description">{{episode.description}}</div>
      </v-col>
      <v-col v-if="compact" class="compact-text" v-bind:class="{ 'pt-0': !gutterless }" v-bind:style="{ width: episodeWidth + 'px' }">
        <div class="next-up-text" v-if="upnext">
          Next up...
        </div>
        <div class="episode-name">
          {{ episode.name }}
        </div>
        <div class="episode-sequence">
          <span class="show-title">{{ show ? show.title : '' }}</span><span v-if="!episode.singular">S{{ episode.seasonNumber }} E{{ episode.episodeNumber }}</span>
        </div>
      </v-col>
      <v-col cols="12" v-if="mobile && !compact">
        <div class="episode-description">{{episode.description}}</div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  export default {
    name: 'episode',
    props: ['episode', 'height', 'compact', 'gutterless', 'upnext', 'click'],
    data: () => ({
    }),
    computed: {
      lcols () {
        if (this.compact) {
          return 12;
        } else if (this.mobile) {
          return 7;
        }
        return 3;
      },
      rcols () {
        if (this.compact) {
          return 12;
        } else if (this.mobile) {
          return 5;
        }
        return 9;
      },
      aspect () {
        if (this.episode.episodeType == 'video') {
          return 16/9;
        }
        if (this.episode.episodeType == 'audio') {
          return 1;
        }
        return 1;
      },
      lazyImage () {
        if (this.episode.episodeType == 'audio') {
          return '/backgrounds/loading-thumbnail-square.jpg';
        }
        return '/backgrounds/loading-thumbnail.jpg';
      },
      mobile () {
        return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
      },
      episodeHeight () {
        if (this.compact) {
          return this.height - 56;
        }
        return this.height;
      },
      episodeWidth () {
        return this.episodeHeight * this.aspect;
      },
      image () {
        if (this.episode && this.episode.image && this.episode.image != '') {
          return this.episode.image;
        }
        return '/icons/video-placeholder.png';
      },
      hoverImage () {
        return '/icons/play-btn.png';
      },
      runtime () {
        if (this.episode) {
          let duration = Math.ceil(this.episode.runtime / 60.0);
          let label = duration + ' Minute';
          if (duration > 1) {
            label = label + 's';
          }
          return label;
        }
        return 0;
      },
      progress () {
        if (this.episode) {
          let view = this.$store.getters.getEpisodeView(this.episode.id)
          if (view) {
            return Math.max(view.progress / view.max, 0.02);
          }
        }
        return 0;
      },
      user () {
        return this.$store.getters.getUser;
      },
      activeSubscription () {
        if (this.user && this.user.subscriptions) {
          return this.user.subscriptions.find(s => s.active);
        }
        return null;
      },
      premium () {
        if (this.activeSubscription && this.activeSubscription.premium) {
          return true;
        }
        return false;
      },
      show () {
        if (this.episode) {
          return this.$store.getters.getShow(this.episode.showId);
        }
        return null;  
      }
    },
    methods: {
      clicked: function () {
        if (this.click) {
          this.click(this.episode);
        } else {
          this.viewEpisode();
        }
      },
      navigate: function (path) {
        this.$router.push(path).catch(()=>{});
      },
      viewEpisode: function () {
        this.navigate('/episode/' + this.episode.id);
        // this.navigate('/watch/' + this.episode.id);
      }
    }
  }
</script>
<style lang="scss" scoped>
.episode {
  height: 100%;
}

.compact-episode {
  display: block;
}

.next-up-text {
  color: grey;
  font-size: 12px;
  padding-top: 8px;
}

.episode-name {
  padding-top: 4px;
  font-size: 16px;

  text-overflow: ellipsis;
  display: flow-root;
  height: 26px;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
}
.episode-sequence {
  color: grey;
  font-size: 12px;
  height: 18px;
  overflow: hidden;
}
.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.episode-title {
  font-size: 16px;
}
.episode-duration {
  font-size: 10px;
  color: grey;
}
.episode-description {
  font-size: 12px;
}
.compact-text {
  margin-top: -8px;
  min-width: 100%;
}

.image-column {
  max-width: none !important;
  position: relative;
}
.text-column {
  flex-basis: 0;
  flex-grow: 1;
  max-width: none !important;
}

.free-episode-banner {
  position: absolute;
  left: 8px;
  top: 16px;
  padding: 0px 8px;
  // z-index: 10;
  background-color: var(--v-primary-base);
  letter-spacing: 1px;
  font-size: 0.9em;
}

.locked-episode-icon {
  position: absolute;
  left: 16px;
  top: 16px;
  // z-index: 1;
}

.hover-image {
  margin: auto;
}

.show-title {
  min-width: 80px;
  display: inline-block;
}

.up-next-image {
  padding: 6px;
  background-color: #474747;
}
</style>
<style lang="scss">
.video-episode .episode-image .v-responsive__content {
  padding: 15%;
}
</style>
