<template>
  <v-container fill-height>
    <v-row class="schedule-title">
      <v-col class="text-center display-1">
        <div>Schedule</div>
      </v-col>
    </v-row>
    <v-row class="schedule-list pa-4">
      <v-col cols="5" class="schedule-item-list">
        <div>
          <v-row class="pa-0" v-for="(scheduleItem, i) in scheduleItems" :key="scheduleItem.id">
            <scheduleItem :item="scheduleItem" :size="11" @click="editScheduleItem(scheduleItem)" class="cursor-pointer"/>
            <v-col cols="1">
              <v-container fluid class="pa-0">
                <v-row>
                  <v-col>
                    <v-btn icon @click="deleteScheduleItem(scheduleItem)">
                      <v-icon size="32" color="red">
                        mdi-close-circle
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="i != 0">
                  <v-col>
                    <v-btn icon @click="moveUp(i)">
                      <v-icon size="32">
                        mdi-chevron-up
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="i != scheduleItems.length - 1">
                  <v-col>
                    <v-btn icon @click="moveDown(i)">
                      <v-icon size="32">
                        mdi-chevron-down
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <div class="mt-4 pt-4">
          <v-btn @click="createScheduleItem" color="green">
            New
          </v-btn>
        </div>
      </v-col>
      <v-col cols="7">
        <v-card v-if="selectedScheduleItem">
          <v-card-title>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="selectedScheduleItem.occurence" :disabled="saving" placeholder="Occurence" label="Occurence" class=""></v-text-field>
            <v-text-field v-model="selectedScheduleItem.title" :disabled="saving" placeholder="Title" label="Title" class=""></v-text-field>
            <v-textarea v-model="selectedScheduleItem.text" :disabled="saving" placeholder="Text" class=""></v-textarea>
            
            <imageUploader ref="scheduleItemImage" v-model="selectedScheduleItem.imageId" :disabled="saving" :url="selectedScheduleItem.image" placeholder="/icons/upload-image.png" @change="updateImage" :width="200" :height="200" :aspect="1/1"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-switch v-model="selectedBulletin.featured" :disabled="saving" label="Featured" class="px-6"></v-switch> -->
            <v-btn tile color="primary" @click="save" :disabled="saving">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Api } from '../../../js/api';

import scheduleItem from './../../investors/scheduleItem.vue'
import imageUploader from '../../global/imageUploader';

export default {
  name: 'Schedule',
  props: [],
  components: {
    imageUploader,
    scheduleItem
  },
  data: () => ({
    scheduleItems: [],
    selectedScheduleItem: null,
    saving: false
  }),
  computed: {
    isNew () {
      if(this.selectedScheduleItem && this.selectedScheduleItem.id) {
        return false;
      }
      return true;
    },
  },
  watch: {
  },
  methods: {
    getData: function () {
      let scope = this;
      Api.get('AdminInvestorSchedule/all').then(function (res) {
        scope.scheduleItems = res.data
      })
    },
    selectScheduleItem: function (schedule) {
      this.selectedScheduleItem = schedule;
    },
    editScheduleItem: function (schedule) {
      this.selectScheduleItem(schedule);
    },
    updateImage: function (image) {
      this.selectedScheduleItem.image = image;
    },
    createScheduleItem: function () {
      this.selectedScheduleItem = {}
    },
    save: function () {
      let scope = this;

      if (scope.$refs.scheduleItemImage.file) {
        // upload the file first
        scope.$refs.scheduleItemImage.upload().then(function () {
          scope.save();
        })
      } else {
        scope.saveScheduleItem();
      }
    },
    moveDown: function (i) {
      let scope = this;

      let item1 = scope.scheduleItems[i]
      let item2 = scope.scheduleItems[i + 1]

      scope.swapScheduleItems(item1, item2);
    },
    moveUp: function (i) {
      let scope = this;

      let item1 = scope.scheduleItems[i]
      let item2 = scope.scheduleItems[i - 1]

      scope.swapScheduleItems(item1, item2);
    },
    swapScheduleItems: function (item1, item2) {
      let scope = this;

      let id1 = item1.id;
      let id2 = item2.id;
      
      let data = {
        scheduleItem1: id1,
        scheduleItem2: id2
      };

      Api.post('AdminInvestorSchedule/swapScheduleItems', data).then(function () {
        scope.getData();
      })
    },
    deleteScheduleItem: function (scheduleItem) {
      let scope = this;

      let data = {
        id: scheduleItem.id
      }

      Api.post('AdminInvestorSchedule/delete', data).then(function () {
        scope.getData();
      })
    },
    saveScheduleItem: function () {
      let scope = this;
      let data = this.selectedScheduleItem;
      scope.saving = true;

      if (!this.isNew) {
        Api.post('AdminInvestorSchedule/Update', data).then(function () {
          scope.saving = false;
          scope.selectedScheduleItem = null;
          scope.getData();
        })
      } else {
        Api.post('AdminInvestorSchedule/Create', data).then(function () {
          scope.saving = false;
          scope.selectedScheduleItem = null;
          scope.getData();
        })
      }
    }
  },
  mounted () {
    this.getData();
  }
}
</script>
<style lang="scss" scoped>
.schedule-title {
  height: 100px;
}
.schedule-list {
  height: calc(100% - 100px);
}

.schedule-item-list {
  overflow-y: auto;
  height: 100%;
}
</style>
