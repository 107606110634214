<template>
  <v-toolbar v-show="visible" dark :absolute="absolute" class="disrupt-toolbar" v-bind:class="{ 'frosted': !isTop }" flat color="rgba(0,0,0,0)" width="100%">
    <router-link to="/">
      <v-img :src="url+'icons/logo-d+.png'" :height="32" :max-width="200" contain />
    </router-link>
    <v-select attach=".discover-menu" v-show="discoverValue != null" class="discover-menu" :menu-props="{ 'content-class': 'discover-menu-content' }" :items="discoverItems" :value="discoverValue" @change="discoverChanged" :height="38" dense solo>
      <template v-slot:selection="props">
        <v-icon v-if="props.item.icon && !props.item.activeText" size="16" :color="props.item.color" class="pr-4">
          {{ props.item.icon }}
        </v-icon>
        {{ props.item.activeText ? props.item.activeText : props.item.text }}
      </template>
      <template v-slot:item="props">
        <v-icon v-if="props.item.icon" size="16" :color="props.item.color" class="pr-4">
          {{ props.item.icon }}
        </v-icon>
        {{ props.item.text }}
      </template>
    </v-select>
    <v-spacer></v-spacer>
    <investorBar v-if="investor" />
    <accountBar v-if="isLoggedIn"/>
    <loginBar v-else/>
  </v-toolbar>
</template>
<script>
import accountBar from './accountBar'
import loginBar from './loginBar'
import investorBar from '../investors/investorBar'
export default {
  name: 'toolbar',
  props: ['absolute', 'fadeIn'],
  components: {
    accountBar,
    loginBar,
    investorBar
  },
  data: () => ({
    loginMenu: false,
    isTop: true,
    fadeScrolled: false,
    topValue: 0,
    items: [
      { text: "Home", activeText: 'Discover', value: 'Content', link: "/", icon: 'mdi-home', color: 'primary' },
      { text: "Livestream", value: 'Livestream', link: "/livestream", icon: 'mdi-circle', color: 'red' },
      { text: "Rewards", value: 'Rewards', link: "/rewards", icon: 'mdi-gift', color: 'yellow' },
      { text: "Apparel", value: 'Apparel', link: "https://disrupt.sale/", absoluteLink: true , icon: 'mdi-tshirt-crew', color: 'green'},
      { text: "Blog", value: 'Blog', link: "https://disrupt.page/", absoluteLink: true, icon: 'mdi-wifi', color: 'grey' },
    ] 
  }),
  computed: {
    isLoggedIn () {
      return this.$store.getters.getIsLoggedIn;
    },
    url () {
      return process.env.VUE_APP_CLIENT_PROTOCOL + process.env.VUE_APP_CLIENT_URL;
    },
    blurAmount () {
      if (this.topValue == 0) {
        return 0;
      } else if (this.topValue > 100) {
        return 12;
      }
      return this.topValue / 100.0;
    },
    discoverValue () {
      return this.discoverItems.find(i => i.value == this.$route.name)
    },
    discoverItems () {
      return this.items; // .filter(i => i.value != this.$route.name);
    },
    visible () {
      return !this.fadeIn || (this.fadeIn && this.fadeScrolled);
    },
    investor () {
      return this.$store.getters.hasKey('Investor');
    }
  },
  methods: {
    discoverChanged: function (item) {
      let discoverItem = this.items.find(i => i.value == item);
      if (discoverItem.absoluteLink) {
        window.location.href = discoverItem.link;
      } else {
        this.navigate(discoverItem.link)
      }
    },
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    scrollCheck: function () {
      if (window.scrollY == 0) {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
      this.topValue = window.scrollY;

      this.updateFadeIn();
    },
    updateFadeIn: function () {
      if (this.topValue > 10) {
        this.fadeScrolled = true;
      } else {
        this.fadeScrolled = false;
      }
    }
  },
  mounted () {
    document.addEventListener('scroll', this.scrollCheck);
  }
}
</script>
<style lang="scss" scoped>
.v-btn {
  text-transform:none !important;
}
.livestream i {
  color: red !important;
}
.login-button {
  margin: 0px 16px;
}
.login-menu {
  border-radius: 0px !important;
}
.disrupt-toolbar {
  height: 64px;
  max-height: 64px;
  position: sticky !important;
  z-index: 1000 !important;
}

.frosted {
  backdrop-filter: blur(12px);
}
</style>
<style lang="scss">
.discover-menu {
  height: 38px;
  padding: 13px;
}
.discover-menu .v-input__slot {
  width: 180px;
  background-color: transparent !important;
  box-shadow: none !important;
}
.discover-menu-content {
  margin-top: 48px;
}
.discover-menu-content .v-list-item--active {
  min-height: 0px !important;
  display: none;
}
.discover-menu-content .v-list {
  background-color: #202020cf !important;
}
</style>
