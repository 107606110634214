/* eslint-disable */
let webNotifications = {
  initialized: false,
  initializeOneSignal: function () {
    let self = this;
    window.OneSignal = window.OneSignal || [];

    OneSignal.push(function() {
      OneSignal.init({
        appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
        notifyButton: {
          enable: false,
        },
        promptOptions: {
          slidedown: {
            prompts: [
              {
                type: "push",
                autoPrompt: true,
                text: {
                  actionMessage: "We'd like to show you notifications for the latest news and updates.",
                  acceptButton: "Allow",
                  cancelButton: "Cancel"
                }
              }
            ]
          }
        }
      });
    });

    self.initialized = true;
  },
  promptForNotifications: function () {
    let self = this;
    return new Promise((resolve) => {
      window.OneSignal = window.OneSignal || [];

      if (!self.initialized) {
        self.initializeOneSignal();
      }

      OneSignal.push(function() {
        OneSignal.showSlidedownPrompt({force: true});
      });
      
      OneSignal.setSubscription(true);
      
      resolve();
    })
  },
  cancelNotifications: function () {
    let self = this;
    return new Promise((resolve) => {
      window.OneSignal = window.OneSignal || [];

      if (!self.initialized) {
        self.initializeOneSignal();
      }

      OneSignal.setSubscription(false);
      
      resolve();
    })
  }
}
export default webNotifications;