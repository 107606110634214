<template>
  <v-dialog v-model="dialog" width="800" content-class="create-user-dialog">
    <v-card>
      <v-card-title>
        Create User
      </v-card-title>
      <v-card-text>
        <serverValidationField ref="usernameField" v-model="userData.username" label="Username" fieldClass="create-user-fields" :height="80" :disabled="saving" :acceptOriginalValue="true"
          endpoint="Auth/usernameAvailable" :dataFunction="usernameDataFunction" :responseFunction="usernameResponseFunction" :messages="usernameFailReason"/>
        
        <serverValidationField ref="passwordField" v-model="userData.password" label="Password" fieldClass="create-user-fields" :height="80" 
          :resolveFunction="validatePassword" :messages="passwordFailReason" :inputDelay="10"/>

        <serverValidationField ref="emailField" v-model="userData.email" label="Email" fieldClass="create-user-fields" :height="80" :disabled="saving" :acceptOriginalValue="true"
          :resolveFunction="validateEmail" :messages="emailFailReason" :inputDelay="10"/>

        <v-select :items="roleItems" item-text="name" class="create-user-fields" v-model="userData.role" :disabled="saving" flat solo>
        </v-select>
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-btn tile class="cancel-button" @click="cancel()">
          Cancel
        </v-btn>
        <v-spacer/>
        <v-btn tile class="login-button" @click="createUser()" color="primary">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { EventBus } from '../../../eventBus';
import { Api } from '../../../js/api';

import serverValidationField from '../../global/serverValidationField'
export default {
  name: 'CreateUserDialog',
  components: {
    serverValidationField 
  },
  data: () => ({
    dialog: false,
    saving: false,
    usernameFailReason: null,
    passwordFailReason: null,
    emailFailReason: null,
    userData: {
      username: '',
      password: '',
      email: '',
      role: 'User'
    }
  }),
  computed: {
    currentUser () {
      return this.$store.getters.getUser;
    },
    availableRoles () {
      let allRoles = ['SuperAdmin', 'Admin', 'Moderator', 'Investor', 'User'];

      let currentRole = this.currentUser.role;
      let currentIndex = allRoles.indexOf(currentRole);

      return allRoles.slice(currentIndex+1);
    },
    roleItems () {
      let items = []
      this.availableRoles.forEach(a => {
        items.push({
          name: a
        })
      })
      return items
    }
  },
  methods: {
    usernameDataFunction: function (username) {
      let data = {
        username: username
      }
      return data;
    },
    usernameResponseFunction: function (res) {
      if (res.data === true) {
        this.usernameFailReason = '';
      } else {
        this.usernameFailReason = res.data;
      }
      return res.valid;
    },
    validatePassword: function (pass) {
      return new Promise((resolve) => {
        // length
        if (pass.length < 9) {
          this.passwordFailReason = 'Must be longer than 8 characters'
          resolve(false);
          return;
        }

        // // lowercase
        // if (!pass.match(/[a-z]/g)) {
        //   this.passwordFailReason = 'Must contain a lowercase letter';
        //   resolve(false);
        //   return;
        // }
        // // uppercase
        // if (!pass.match(/[A-Z]/g)) {
        //   this.passwordFailReason = 'Must contain an uppercase letter';
        //   resolve(false);
        //   return;
        // }
        // // numbers
        // if (!pass.match(/[0-9]/g)) {
        //   this.passwordFailReason = 'Must contain a number';
        //   resolve(false);
        //   return;
        // }

        // // symbols
        // if (!pass.match(/[^a-zA-Z\d\s:]/g)) {
        //   this.passwordFailReason = 'Must contain a symbol';
        //   resolve(false);
        //   return;
        // }

        this.passwordFailReason = null;
        resolve(true);
      })
    },
    validateEmail: function (email) {
      return new Promise((resolve) => {
        const validation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email.toLowerCase().match(validation)) {
          this.emailFailReason = 'Incorrect email format';
          resolve(false);
          return;
        }

        this.emailFailReason = null;
        resolve(true);
      })
    },
    cancel: function () {
      this.dialog = false;
    },
    createUser: function () {
      let scope = this;

      if (!this.$refs.usernameField.checkValid) {
        return;
      }
      if (!this.$refs.emailField.checkValid) {
        return;
      }
      if (!this.$refs.passwordField.checkValid) {
        return;
      }
      let data = this.userData;

      scope.saving = true;
      Api.post('Admin/createUser', data).then(function (res) {
        scope.dialog = false;
        scope.saving = false;

        if(res.valid) {
          EventBus.$emit('alert', {
            text: 'User created',
            alertColor: 'green',
            alertIcon: 'success'
          })
        }

        scope.$emit('complete');
      })
    }
  },
  mounted () {
    let scope = this;
    EventBus.$on('createUserDialog', function () {
      scope.dialog = true;
    })
  }
}
</script>
<style lang="scss">
.create-user-dialog,
.create-user-dialog .v-card {
  background-color: #202020 !important;
  border-radius: 0px !important;
}
.create-user-fields .v-input__slot{
  background-color: #282828 !important;
  border-radius: 0px !important;
}
.create-user-fields .v-label {
  color: white !important;
  padding-left: 16px; 
}
</style>