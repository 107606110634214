<template>
  <v-container fill-height>
    <v-row class="investor-info-title">
      <v-col class="text-center display-1">
        <div>Investor Info</div>
      </v-col>
    </v-row>
    <v-row class="investor-info-list">
      <v-col />
      <v-col cols="6">
        <v-card v-if="investorInfo">
          <v-card-title>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="investorInfo.discord" :disabled="saving" placeholder="Discord Link" label="Discord Link" class=""></v-text-field>
            <v-text-field v-model="investorInfo.email" :disabled="saving" placeholder="Contact Email" label="Contact Email" class=""></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" @click="save" :disabled="saving">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col />
    </v-row>
  </v-container>
</template>
<script>
import { Api } from '../../../js/api';

export default {
  name: 'InvestorInfo',
  props: [],
  components: {
  },
  data: () => ({
    investorInfo: null,
    loading: false,
    saving: false
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    getData: function () {
      let scope = this;

      scope.loading = true;
      Api.get('AdminInvestorDashboardInfo').then(function (res) {
        scope.investorInfo = res.data;
        scope.loading = false;
      })
    },
    save: function () {
      let scope = this;

      let data = this.investorInfo;

      scope.saving = true;
      Api.post('AdminInvestorDashboardInfo', data).then(function () {
        scope.saving = false;
      })
    }
  },
  mounted () {
    this.getData();
  }
}
</script>
<style lang="scss" scoped>
.investor-info-title {
  height: 100px;
}
.investor-info-list {
  height: calc(100% - 100px);
}
</style>
