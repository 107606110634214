<template>
  <v-dialog v-model="dialog" width="400" content-class="edit-category-dialog">
    <v-card v-if="category">
      <v-card-title>
        {{ header }}
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="category.name">
        </v-text-field>
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-btn tile class="cancel-button" @click="cancel()" :disabled="saving">
          Cancel
        </v-btn>
        <v-spacer/>
        <v-btn tile class="login-button" @click="save()" :disabled="saving" color="primary">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { EventBus } from '../../../eventBus.js';
import { Api } from '../../../js/api';

export default {
  name: 'EditCategoryDialog',
  components: {
  },
  data: () => ({
    dialog: false,
    saving: false,
    category: {
      name: ''
    }
  }),
  watch: {
    dialog: function(val) {
      if (!val) {
        this.category = null;
      }
    }
  },
  computed: {
    isNew () {
      if (this.category && this.category.id) {
        return false;
      } 
      return true;
    },
    header () {
      if (this.isNew) {
        return 'Create Category';
      }
      return 'Edit Category';
    }
  },
  methods: {
    cancel: function () {
      this.dialog = false;
    },
    save: function () {
      let scope = this;
      let data = this.category;

      scope.saving = true;
      if (scope.isNew) {
        Api.post('AdminCategory/createCategory', data).then(function (res) {
          scope.dialog = false;
          scope.saving = false;

          if (res.valid) {
            EventBus.$emit('alert', {
              text: 'Category created',
              alertColor: 'green',
              alertIcon: 'success'
            })
          }

          scope.$emit('complete', res.data);
        })
      } else {
        Api.post('AdminCategory/updateCategory', data).then(function (res) {
          scope.dialog = false;
          scope.saving = false;

          if (res.valid) {
            EventBus.$emit('alert', {
              text: 'Category updated',
              alertColor: 'green',
              alertIcon: 'success'
            })
          }

          scope.$emit('complete', res.data);
        })
      }
    }
  },
  mounted () { let scope = this
    EventBus.$on('editCategoryDialog', function (category) {
      scope.category = Object.assign({}, category);
      scope.dialog = true;
    })
    EventBus.$on('createCategoryDialog', function () {
      scope.category = {
        name: ''
      };
      scope.dialog = true;
    })
  }
}
</script>
<style lang="scss">
.edit-category-dialog,
.edit-category-dialog .v-card {
  background-color: #202020 !important;
  border-radius: 0px !important;
}
.edit-category-fields .v-input__slot{
  background-color: #282828 !important;
  border-radius: 0px !important;
}
.edit-category-fields .v-label {
  color: white !important;
  padding-left: 0px; 
}
</style>