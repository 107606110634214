<template>
  <v-app v-if="!loaded">
    <v-container fill-height>
      <div class="disrupt-loader">
        <v-img src="/icons/loop.gif" width="100%" height="100%" contain />
      </div>
    </v-container>
  </v-app>
  <v-app v-else-if="showMaintenance">
    <v-container fill-height>
      <div class="maintenance text-center">
        <v-img :src="url+'icons/logo-d+.png'" height="64px" contain />
        <br>
        we are currently changing the nuclear reactor <br>
        please check back later
      </div>
    </v-container>
  </v-app>
  <v-app v-else dark v-bind:style="{ 'background-color': background + ' !important' }" v-bind:class="{ 'disrupt-mobile': mobile }">
    <!-- <div class="site-errors">
      <div v-for="(error, n) in errors" :key="n">
        {{ error }}
      </div>
    </div> -->
    <popout />
    <toolbar v-if="showToolbar && !mobile" :absolute="absoluteToolbar" :fadeIn="fadeInToolbar"/>
    <mobileNavigation v-if="showToolbar && mobile && !hideMobileToolbar"/>
    <router-view v-bind:class="{ 'offset-content': showToolbar && !mobile && absoluteToolbar }"/>
    <alert-box/>
  </v-app>
</template>

<script>
import alertBox from './components/global/alertBox'
import toolbar from './components/global/toolbar'
import mobileNavigation from './components/global/mobileNavigation'

import { EventBus } from './eventBus';
import popout from './components/global/popout.vue'

export default {
  name: 'app',
  components: {
    toolbar,
    alertBox,
    mobileNavigation,
    popout
  },
  data: () => ({
    showToolbar: false,
    hideMobileToolbar: false,
    absoluteToolbar: true,
    background: false,
    fadeInToolbar: false
  }),
  methods: {
    updateMetas: function (r) {
      this.showToolbar = r.meta.toolbar;
      this.hideMobileToolbar = r.meta.hideMobileToolbar == true;
      this.background = !r.meta.background ? 'initial' : r.meta.background;
      this.absoluteToolbar = r.meta.absoluteToolbar;
      this.fadeInToolbar = r.meta.fadeInToolbar == true;

      if (r.params && r.params.episodeId) {
        this.$store.commit('setPlayerEpisodeId', r.params.episodeId);
      }
    }
  },
  computed: {
    loaded () {
      return this.$store.getters.getLoaded;
    },
    mobile () {
      return this.$store.getters.getIsMobile;
    },
    maintenance () {
      return this.$store.getters.getMaintenance;
    },
    showMaintenance () {
      if (this.maintenance && !(this.$route.meta.skipMaintenance || this.isAdmin)) {
        return true;
      }
      return false;
    },
    isAdmin () {
      if (this.currentUser) {
        if (['Dev', 'SuperAdmin', 'Admin'].indexOf(this.currentUser.role) > -1) {
          return true;
        }
      }
      return false;
    },
    currentUser () {
      return this.$store.getters.getUser;
    },
    url () {
      return process.env.VUE_APP_CLIENT_PROTOCOL + process.env.VUE_APP_CLIENT_URL;
    },
    errors () {
      return this.$store.getters.getErrors;
    },
    minimised () {
      return this.$store.getters.getPopoutMinimised;
    }
  },
  watch: {
    $route (to, from) {
      this.updateMetas(to);
      
      if (to.name == 'Episode') {
        EventBus.$emit('unminimisePlayer', true);
      } else if (from.name == 'Episode' && !this.minimised) {
        EventBus.$emit('closePopout');
      }
    }
  },
  mounted () {
    this.$store.dispatch('browserDetect', this.$browserDetect);

    this.updateMetas(this.$router.currentRoute)
  }
}
</script>
<style lang="scss">
html,
.v-application {
  background-color: #202020 !important;
  font-family: "Montserrat" !important;
}
html {
  overflow-y: auto !important;
}
.v-application .montserrat {
  font-family: "Montserrat" !important;
}
.dark-background {
  background-color: #0f0f0f !important;
}

.site-loader {
  margin: 0 auto;
}

.disrupt-loader {
  position: absolute;
  top: 40%;
  right: 40%;
  bottom: 40%;
  left: 40%;
}

* {
  font-family: "Montserrat" !important;
}
.montserrat {
  font-family: "Montserrat" !important;
}
::-webkit-scrollbar{
	width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track-piece{
	background-color: rgba(0, 0, 0, 0.137);
}

::-webkit-scrollbar-thumb{
	background-color: #ffffff54;
  border-radius: 5px;
	outline: none;// 2px solid #FFF;
	border: none;//.1px solid #B7B7B7;
}

::-webkit-scrollbar-thumb:hover{
	background-color: #ffffff91;
}

.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-negative-90 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.square-fields .v-input__slot{
  background-color: #282828 !important;
  border-radius: 0px !important;
}
.square-fields .v-label {
  color: white !important;
  padding-left: 16px; 
}
.square-fields .v-messages__message {
  color: red;
}

.max-height {
  height: 100%;
}

.offset-content {
  margin-top: -64px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.maintenance {
  width: 100%;
  font-size: 2em;
}

.site-errors {
  position: absolute;
  left: 20px;
  top: 20px;
  color: red;
}
</style>