var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-toolbar',{staticClass:"user-toolbar elevation-0"},[_c('div',{staticClass:"montserrat display-1"},[_vm._v("Users")]),_c('v-spacer'),(_vm.isDev)?_c('v-btn',{on:{"click":_vm.updateNotificationEmails}},[_vm._v(" Update Email Settings ")]):_vm._e(),(_vm.isDev)?_c('v-btn',{on:{"click":_vm.checkSubscriptions}},[_vm._v(" Check Subscriptions ")]):_vm._e(),_c('v-text-field',{staticClass:"user-search-bar mx-4",attrs:{"light":"","placeholder":"search","single-line":"","hide-details":"","tile":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"add-user-button elevation-0",attrs:{"color":"#363636","tile":""},on:{"click":function($event){return _vm.createUser()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-data-table',{staticClass:"users-table elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"item-key":"id","options":_vm.options,"footer-props":_vm.footerOptions,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.subscriptions[0].premium",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subscriptions[0].premium ? "Premium" : "Free")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.canEditUser(item)),expression:"canEditUser(item)"}],attrs:{"icon":""},on:{"click":function($event){return _vm.editUser(item)}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit User")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.canDeleteUser(item)),expression:"canDeleteUser(item)"}],attrs:{"icon":""},on:{"click":function($event){return _vm.deleteUser(item)}}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete User")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.canSendPasswordReset(item)),expression:"canSendPasswordReset(item)"}],attrs:{"icon":""},on:{"click":function($event){return _vm.sendResetEmail(item)}}},on),[_c('v-icon',[_vm._v(" mdi-email ")])],1)]}}],null,true)},[_c('span',[_vm._v("Send Reset Email")])])]}}],null,true)}),_c('createUserDialog',{on:{"complete":_vm.getUsers}}),_c('editUserDialog',{on:{"complete":_vm.getUsers}}),_c('deleteUserDialog',{on:{"complete":_vm.getUsers}}),_c('resetPasswordDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }