<template>
  <v-container fill-height fluid class="pa-0 watch-container">
    <v-container v-if="loading" fill-height class="pa-0">
      <div class="disrupt-loader">
        <v-img src="/icons/loop.gif" :aspect-ratio="1" contain height="100%" width="100%" />
      </div>
    </v-container>
    <v-container v-show="!loading && !needsUpgrade" fill-height fluid class="pa-0">
      <disruptPlayer ref="disrupt-player" :hiddenControls="minimised" :minimised="minimised" :mini="!isFullscreen" :episode="episode" :qualities="keys" :type="episodeType" :width="width" :height="height" :show="show" :episodes="episodes" @updateView="updateView"/>
    </v-container>
    <v-container v-show="!loading && needsUpgrade" fill-height fluid class="pa-0">
      <v-row justify="center">
        <v-col cols="12" md="6" class="text-center" v-if="!isMobile">
          <v-img src="/icons/open-padlock.png" height="80" width="80" style="margin: 0 auto"/>
          <div class="upgrade-text pa-4">
            <span class="primary--text">Upgrade</span> to unlock this content
          </div>
          <v-btn tile color="primary" x-large class="start-button" @click="upgradeAccount">
            Start
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="text-center" v-else>
          <v-img src="/icons/open-padlock.png" height="40" width="40" style="margin: 0 auto"/>
          <div class="mobile-upgrade-text pa-4">
            <span class="primary--text">Upgrade</span> to unlock this content
          </div>
          <v-btn tile color="primary" large class="start-button" @click="upgradeAccount">
            Start
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { Api } from '../js/api';

import disruptPlayer from '../components/global/player/disruptPlayer'

export default {
  name: 'Watch',
  props: ['minimised'],
  components: {
    disruptPlayer
  },
  data: () => ({
    loading: true,
    key: '',
    keys: [],
    showId: null,
    show: null,
    episodes: [],
    episode: null,
    view: null,
    width: null,
    height: null,
    upgradeRoute: '',
    needsUpgrade: false
  }),
  computed: {
    episodeId () {
      if (this.$route.params.episodeId) {
        return this.$route.params.episodeId;
      }
      if (this.$store.getters.getPlayerEpisodeId) {
        return this.$store.getters.getPlayerEpisodeId;
      }
      return null;
    },
    episodeType () {
      if (this.episode) {
        return this.episode.episodeType;
      }
      return '';
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
    user () {
      return this.$store.getters.getUser;
    },
    isFullscreen () {
      return this.$route.name == 'Watch';
    },
    isMobile () {
      return this.$store.getters.getIsMobile;
    }
  },
  watch: {
    episodeId: function () {
      // the episode has changed
      this.loadEpisode();
    },
    view: function () {
      if (this.view) {
        this.continueView(this.view);
      }
    },
    minimised: function () {
      // if (val) {
      //   this.width = '100vw';
      // } else {
      //   this.updateSizing();        
      // }
    }
  },
  methods: {
    updateSizing: function () {
      console.log('watch: update sizing');
      if (this.isFullscreen) {
        this.width = '100vw';
      } else {
        this.width = document.getElementById('watch-container').clientWidth;
      }

      if (this.isFullscreen) {
        this.height = '100vh';
      } else {
        this.height = document.getElementById('watch-container').clientHeight;
      }

      console.log('watch: update sizing');
      console.log(this.width);
      console.log(this.height);
    },
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    getEpisode: function () {
      let scope = this;
      return new Promise((resolve) => {
        scope.$store.dispatch('getEpisode', scope.episodeId).then(function (episode) {
          scope.episode = episode;
          if (episode && scope.episode.season) {
            scope.showId = scope.episode.season.showId;
          }
          resolve(episode)
        })
      })
    },
    getView: function () {
      let scope = this;
      return new Promise((resolve) => {
        if (scope.isLoggedIn) {
          scope.$store.dispatch('getEpisodeView', this.episodeId).then(function (view) {
            scope.view = view;
            resolve()
          })
        } else {
          resolve();
        }
      })
    },
    getShow: function (showId) {
      let scope = this;
      return new Promise((resolve) => {
        scope.$store.dispatch('getShow', showId).then(function (show) {
          scope.show = show;
          resolve()
        })
      })
    },
    getEpisodes: function (showId) {
      let scope = this;
      return new Promise((resolve) => {
        scope.$store.dispatch('getEpisodes', showId).then(function (episodes) {
          scope.episodes = episodes;
          resolve()
        })
      })
    },
    getContentData: function () {
      let scope = this;
      
      return new Promise((resolve) => {
        scope.getEpisode().then(function (episode) {
          if (episode) {
            scope.getView().then(function () {
              scope.getShow(scope.showId).then(function () {
                scope.getEpisodes(scope.showId).then(function () {
                  resolve();
                });
              });
            })
          } else {
            // episode is not found
            scope.navigate('/');
          }
        });
      });
    },
    getContentKeys: function () {
      let scope = this;
      return new Promise((resolve) => {
        Api.get('Content/key/' + this.episodeId).then(function (res) {
          if (res.data) {
            scope.keys = res.data;

            resolve(true);
          } else {
            resolve(false);
          }
        })
      })
    },
    loadEpisode: function () {
      let scope = this;
      return new Promise((resolve) => {
        scope.key = '';
        scope.episode = null;

        scope.getContentData().then(function () {
          scope.getContentKeys().then(function (valid) {
            if (valid) {
              if (scope.view) {
                scope.continueView(scope.view);   
              }
              resolve()
            } else {
              resolve();

              scope.needsUpgrade = true;
              if (scope.user) {
                scope.upgradeRoute = '/upgrade';
              } else {
                scope.upgradeRoute = '/register';
              }
            }
          });
        })
      })
    },
    upgradeAccount: function () {
      let scope = this;
      scope.navigate(scope.upgradeRoute);
    },
    continueView: function (view) {
      let newTimestamp = view.progress;
      if (Math.abs(view.max - newTimestamp) < 10) {
        newTimestamp = 0;
      }
      this.$refs["disrupt-player"].setTimestamp(newTimestamp);
    },
    checkWindowSize: function () {
      this.updateSizing();
    },
    updateView: function (progress) {
      if (this.isLoggedIn) {
        Api.post('View/set/' + this.episodeId, progress)
      }
    }
  },
  created () {
    window.addEventListener("resize", this.checkWindowSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkWindowSize);
  },
  mounted () {
    let scope = this;
    scope.loading = true;
    
    scope.updateSizing();
    scope.loadEpisode().then(function () {
      scope.loading = false;
      scope.updateSizing();
    });
  }
}
</script>
<style lang="scss" scoped>
.upgrade-text {
  font-size: 32px;
}
.mobile-upgrade-text {
  font-size: 24px;
}
.start-button {
  font-size: 24px;
  text-transform: none;
}
</style>
