<template>
	<v-container class="rating text-center" fill-height fluid>
		<v-row align-content="center">
			<v-col cols="6" class="pa-0">
				<v-btn icon class="rating-button mx-4" @click="onLike" :disabled="!isLoggedIn">
					<v-icon v-if="hasLiked" :size="iconSize" class="pr-2">mdi-thumb-up</v-icon>
					<v-icon v-else :size="iconSize" class="pr-2">mdi-thumb-up-outline</v-icon>
					{{content.upvotes}}
				</v-btn>
			</v-col>
			<v-col cols="6" class="pa-0">
				<v-btn icon class="rating-button mx-4" @click="onDislike" :disabled="!isLoggedIn">
					<v-icon v-if="hasDisliked" :size="iconSize" class="pr-2">mdi-thumb-down</v-icon>
					<v-icon v-else :size="iconSize" class="pr-2">mdi-thumb-down-outline</v-icon>
					{{content.downvotes}} 
				</v-btn>
			</v-col>
			<v-col v-if="bar" cols="12" class="pa-0">
				<v-progress-linear
					:value="likePercent"
				></v-progress-linear>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
export default {
  name: 'Rating',
  props: ['content', 'contentType', 'bar', 'height'],
  components: {
  },
  data: () => ({
  }),
  computed: {
    hasLiked () {
			return this.content.userRating > 0;
    },
    hasDisliked () {
			return this.content.userRating < 0;
    },
    likePercent () {
			return (this.content.upvotes / (this.content.upvotes + this.content.downvotes)) * 100;
    },
    iconSize () {
			if (this.height != null) {
				if (this.bar) {
					return this.height + 4;
				}
				return this.height;
			}
			if (this.bar) {
				return 24;
			}
			return 24 + 4;
    },
		isLoggedIn () {
			return this.$store.getters.getIsLoggedIn;
		}
  },
  watch: {
  },
  methods: {
		onLike: function () {
			let self = this;

			if (self.isLoggedIn) {
				let intendedRating = 1;
				if (self.hasLiked) {
					intendedRating = 0;
				}
				self.rateContent(intendedRating);
			}
		},
		onDislike: function () {
			let self = this;

			if (self.isLoggedIn) {
				let intendedRating = -1;
				if (self.hasDisliked) {
					intendedRating = 0;
				}
				self.rateContent(intendedRating);
			}
		},
		rateContent: function (rating) {
			let self = this;
			let data = null;
			let endpoint = '';
			if (self.contentType == 'episode') {
				data = {
					episodeId: self.content.id,
					rating: rating
				};

				endpoint = 'rateEpisode';
			} else if (self.contentType == 'comment') {
				data = {
					commentId: self.content.id,
					rating: rating
				};

				endpoint = 'rateComment';
			}

			self.$store.dispatch(endpoint, data).then(function () {
				if (rating > 0) {
					self.content.upvotes += 1;
				} else if (rating < 0) {
					self.content.downvotes += 1;
				}

				if (self.content.userRating == 1) {
					self.content.upvotes -= 1;
				} else if (self.content.userRating == -1) {
					self.content.downvotes -= 1;
				}

				self.content.userRating = rating;
			});
		}
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
</style>

<style>
.rating-button.v-btn.v-btn--disabled {
	color: inherit !important;
}
.rating-button.v-btn.v-btn--disabled .v-icon {
	color: inherit !important;
}
</style>
