<template>
  <v-container fill-height>
    <v-row class="analytics-header">
      <v-col class="text-center display-1">
        <div>{{ header }}</div>
      </v-col>
    </v-row>
    <v-row class="analytics-list pa-4" v-if="episode == null">
      <div class="episode ma-4" v-for="(episode, n) in filteredEpisodes" :key="episode.id" @click="selectEpisode(episode)">
        <v-img
          :src="getImage(episode)"
          :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
          :aspect-ratio="aspect"
          :height="height"
          :max-height="height"
          :width="width"
          :max-width="width"
          class="grey lighten-2"/>
        <div class="pa-2">
          {{ episode.name }} - S{{ episode.seasonNumber }} E{{ episode.episodeNumber }}
        </div>
      </div>
    </v-row>
    <v-row class="analytics-list pa-4" v-else>
      <v-col cols="6">
        <v-responsive>
          <div class="analytic-episode ma-4">
            <v-img
              :src="getImage(episode)"
              :lazy-src="`https://picsum.photos/10/6?image=${ Math.floor(Math.random() * 10) * 5 + 10}`"
              :aspect-ratio="aspect"
              :height="height"
              :max-height="height"
              :width="width"
              :max-width="width"
              class="grey lighten-2"/>
            <div class="pa-2">
              {{ episode.name }} - S{{ episode.seasonNumber }} E{{ episode.episodeNumber }}
            </div>
          </div>
        </v-responsive>
      </v-col>
      <v-col cols="6">
        <episodeStats :data="analytics.episodeStats" :height="250" />
      </v-col>
      <v-col cols="12">
        <viewsGraph :data="analytics.episodeViews" :height="250" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Api } from '../../../js/api';

import viewsGraph from './viewsGraph'
import episodeStats from './episodeStats'

export default {
  name: 'Analytics',
  props: [],
  components: {
    viewsGraph,
    episodeStats
  },
  data: () => ({
    episode: null,
    episodes: [],
    height: 260,
    aspect: 16/9,
    analytics: null
  }),
  computed: {
    id () {
      return this.$route.params.id;
    },
    width () {
      return this.aspect * this.height;
    },
    header () {
      return 'Analytics';
    },
    filteredEpisodes () {
      return this.episodes;
    }
  },
  watch: {
    id: function () {
      this.detectFlow();
    }
  },
  methods: {
    selectEpisode: function (episode) {
      this.$router.push('analytics/' + episode.id);
    },
    viewEpisode: function (id) {
      let scope = this;
      scope.analytics = null;
      scope.getEpisode(id).then(function () {
        scope.getAnalytics(id)
      })
    },
    getImage: function (episode) {
      if (episode.image) {  
        return episode.image;
      }
      return '';
    },
    getEpisode: function (episodeId) {
      let self = this;
      return new Promise((resolve) => {
        self.loading = true;
        Api.get('AdminEpisode/getEpisode/' + episodeId).then(function (res) {
          self.episode = res.data;
          resolve(self.episode);
        })
      });
    },
    getAnalytics: function (episodeId) {
      let self = this;
      return new Promise((resolve) => {
        self.loading = true;
        Api.get('AdminAnalytics/episode/' + episodeId).then(function (res) {
          self.analytics = res.data;
          resolve(self.analytics);
        })
      }); 
    },
    getEpisodes: function () {
      let self = this

      self.loading = true;
      Api.get('AdminEpisode/all').then(function (res) {
        self.episodes = res.data;
        self.loading = false;
      })
    },
    detectFlow: function () {
      if (this.id) {
        this.viewEpisode(this.id)
      } else {
        this.episode = null;
        this.getEpisodes();
      }
    }
  },
  mounted () {
    this.detectFlow();
  }
}
</script>

<style lang="scss" scoped>
.analytics-header {
  height: 64px;
}
.analytics-list {
  height: calc(100% - 64px);
  overflow-y: auto;
}
.show {
  height: 300px;
}

.season-cell {
  height: 64px;
}
.filtered-episodes {
  height: calc(100% - 64px);
}

.episode-list {
  height: calc(100% - 64px);
  overflow-y: auto;
}
.episode {
  height: 300px;
}

.analytic-episode {
  height: 300px;
  width: 480px;
  margin: auto !important;
}
</style>
