<template>
  <div>
    <v-btn @click="showNotificationDialog = true" class="notification-button mr-2" tile :color="redNotification ? '#f30001' : 'transparent'">
      <v-icon>mdi-bell</v-icon>
    </v-btn>
    <v-menu
      v-model="logoutMenu"
      content-class="login-menu"
      :close-on-content-click="false"
      offset-y>
      <template v-slot:activator="{ on }">
        <v-avatar v-on="on" tile size="36" class="user-avatar cursor-pointer">
          <img :src="profileImage">
        </v-avatar>
      </template>
      <v-list>
        <v-list-item @click="navigate('/account')">
          <v-list-item-title>Your Account</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isAdmin" @click="navigate('/admin/')">
          <v-list-item-title>Admin</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <notificaionDialog :dialog="showNotificationDialog" @close="showNotificationDialog = false" />
  </div>
</template>
<script>
import { Api } from '../../js/api';
import notificaionDialog from './notificationDialog.vue'

export default {
  name: 'AccountBar',
  components: {
    notificaionDialog
  },
  data: () => ({
    logoutMenu: false,
    showNotificationDialog: false
  }),
  computed: {
    user () {
      return this.$store.getters.getUser;
    },
    isAdmin () {
      if (this.user) {
        if (["Dev", "SuperAdmin", "Admin"].includes(this.user.role)) {
          return true
        }
      }
      return false
    },
    profileImage () {
      if (this.user) {
        if (this.user.image) {
          return this.user.image;
        }
      }
      return "/icons/user.png";
    },
    redNotification () {
      if (this.user) {
        if (this.user.createdNotificationSettings) {
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    navigate: function (path) {
      this.logoutMenu = false;
      this.$router.push(path).catch(()=>{});
    },
    logout: function () {
      let self = this
      Api.post('Auth/logout', null).then(function () {
        self.$store.commit('setUser', null);
        self.$store.commit('setIsLoggedIn', false);
        self.navigate('/');
      })
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss">
.login-menu {
  border-radius: 0 !important;
}
.notification-button {
  width: 36px !important;
  min-width: 0 !important;
  height: 36px !important;
  background-color: transparent;
  box-shadow: none;
}
.user-avatar {
  border: 4px var(--v-primary-base) solid;
  box-sizing: content-box;
}
</style>
