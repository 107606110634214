<template>
  <div class="pa-2" v-scroll:#episode-page="onPageScroll">
    <div v-if="!isMobile">
      <commentBox v-if="send && currentUser" :episode="episode" :forum="forum" @commentSent="onCommentSent" />
      <v-row v-if="!loading">
        <v-col cols="12">
          <comment v-if="mostLikedComment" :comment="mostLikedComment" :mostLiked="true" :isMod="isModerator" @deleteComment="deleteComment" @banUser="banUser"/>
        </v-col>
        <v-col cols="12">
          <comment :comment="comment" v-for="comment in comments" :key="comment.id" :isMod="isModerator" @deleteComment="deleteComment" @banUser="banUser"/>
        </v-col>
      </v-row>
      <v-row v-if="loading || loadingMoreComments" align="center" class="text-center comments-loader">
        <v-col>
          <v-progress-circular indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div class="expand-icon-container">
        <v-btn icon x-small class="expand-icon" @click="popoutOpen = true">
          <v-img src="/icons/expand-icon.png" width="16"/>
        </v-btn>
      </div>
      <comment v-if="mostLikedComment" @click="topCommentClicked" :comment="mostLikedComment" :preview="true" :mostLiked="false" :isMod="isModerator" @deleteComment="deleteComment" @banUser="banUser"/>
      <commentBox v-if="!mostLikedComment && send" @click="topCommentClicked" :episode="episode" :forum="forum" :disabled="true" />
      <commentsPopout :open="popoutOpen" @scroll="onScroll" @close="popoutOpen = false">
        <commentBox v-if="send && currentUser" :episode="episode" :forum="forum" @commentSent="onCommentSent" />
        <comment v-if="mostLikedComment" :comment="mostLikedComment" :mostLiked="false" :separator="true" :isMod="isModerator" @deleteComment="deleteComment" @banUser="banUser"/>
        <comment :comment="comment" v-for="comment in comments" :key="comment.id" :separator="true" :isMod="isModerator" @deleteComment="deleteComment" @banUser="banUser"/>
      </commentsPopout>
    </div>
    <!-- <v-row>
      <v-col class="pa-4">
        <v-divider></v-divider>
      </v-col>
    </v-row> -->
  </div>
</template>
<script>
import commentBox from './commentBox.vue'
import comment from './comment.vue'

import commentsPopout from './commentsPopout.vue'

import { Api } from '../../../js/api';

export default {
  name: 'Comments',
  props: ['episode', 'forum', 'send', 'showMostLiked'],
  components: {
    commentBox,
    comment,
    commentsPopout
  },
  data: () => ({
    loading: false,
    loadingMoreComments: false,
    canLoadMore: true,
    popoutOpen: false
  }),
  computed: {
    isMobile () {
      return this.$store.getters.getIsMobile;
    },
    currentUser () {
      return this.$store.getters.getUser;
    },
    mostLikedComment () {
      if (this.showMostLiked) {
        return this.$store.getters.getMostLikedComment;
      }
      return null;
    },
    comments () {
      if (this.mostLikedComment) {
        return this.$store.getters.getComments.filter(c => c.id != this.mostLikedComment.id);  
      }
      return this.$store.getters.getComments;
    },
    isModerator () {
      return this.$store.getters.hasKey('Moderation');
    }
  },
  watch: {
    episode () {
      this.getComments();
    }
  },
  methods: {
    onPageScroll: function (e) {
      if(!this.isMobile) {
        this.onScroll(e);
      }
    },
    onScroll: function (e) {
      let self = this;
      let margin = 10;
      if(!self.loadingMoreComments && e.target.scrollTop + e.target.clientHeight + margin >= e.target.scrollHeight) {
        self.tryGetMoreComments()
      }
    },
    topCommentClicked: function () {
      if (!this.popoutOpen) {
        this.popoutOpen = true;
      }
    },
    getComments: function () {
      let self = this;

      if (self.episode) {
        self.loading = true;
        self.$store.dispatch('getEpisodeComments', self.episode.id).then(function () {
          self.loading = false;
        })
      } else if (self.forum) {
        self.loading = true;
        self.$store.dispatch('getForumComments', self.forum.id).then(function () {
          self.loading = false;
        })
      }
    },
    tryGetMoreComments: function () {
      let self = this;
      if (self.canLoadMore) {
        self.getMoreComments();
      }
    },
    getMoreComments: function () {
      let self = this;
      if (self.episode) {
        self.loadingMoreComments = true
        let data = {
          episodeId: self.episode.id,
          skip: self.comments.length + 1
        }
        
        self.$store.dispatch('getMoreEpisodeComments', data).then(function (res) {
          self.loadingMoreComments = false;
          if (res.length == 0) {
            self.canLoadMore = false;
          }
        })
      } else if (self.forum) {
        self.loadingMoreComments = true
        let data = {
          forumId: self.forum.id,
          skip: self.comments.length + 1
        }
        
        self.$store.dispatch('getMoreForumComments', data).then(function (res) {
          self.loadingMoreComments = false;
          if (res.length == 0) {
            self.canLoadMore = false;
          }
        })
      }
    },
    onCommentSent: function () {
      this.getComments();
    },
    deleteComment: function (comment) {
      let self = this;

      let data = comment.id;
      Api.post('Comment/delete', data).then(function () {
        self.$store.commit('removeComment', comment);
      })
    },
    banUser: function (comment) {
      let self = this;

      let data = comment.id;
      Api.post('Comment/ban', data).then(function () {
        self.getComments();
      })
    }
  },
  mounted () {
    this.getComments();
  }
}
</script>
<style lang="scss" scoped>
.comments-loader {
  height: 400px;
}

.expand-icon-container {
  position: relative;
}

.expand-icon {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
