<template>
  <v-container fill-height>
    <v-row class="show-header">
      <v-col class="text-center display-1">
        <div>{{ header }}</div>
      </v-col>
    </v-row>
    <v-row class="show-row" v-if="show">
      <v-col cols="5">
        <v-card>
          <v-card-title>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap class="px-1">
              <v-flex xs4 class="px-2">
                <v-select label="Content Type" v-model="show.showType" :items="showTypes" :disabled="saving">
                </v-select>
              </v-flex>
              <v-flex xs8 class="px-2">
                <v-switch v-model="show.descending" :disabled="saving" :label="show.descending ? 'Episode Order: Descending' : 'Episode Order: Ascending'" class="px-6"></v-switch>
                <v-switch v-model="show.shareholdersOnly" :disabled="saving" label="Shareholder's Only" class="mt-0 pt-0 px-6"></v-switch>
              </v-flex>
            </v-layout>
            <v-text-field v-model="show.subtitle" :disabled="saving" placeholder="Subtitle" class="show-subtitle"></v-text-field>
            <v-text-field v-model="show.title" :disabled="saving" placeholder="Title" class="show-title"></v-text-field>
            <v-text-field v-model="show.tagList" :disabled="saving" placeholder="Tag List" class="show-tag-list"></v-text-field>
            <v-textarea v-model="show.description" :disabled="saving" placeholder="Description" class="show-description"></v-textarea>
            <v-layout wrap>
              <v-flex xs6>
                <imageUploader ref="showImage" v-model="show.imageId" :disabled="saving" :url="show.image" placeholder="/icons/upload-image.png" @change="updateImage" :width="300" :height="168" :aspect="16/9"/>
              </v-flex>
              <v-flex xs6>
                <imageUploader ref="bannerImage" v-model="show.bannerImageId" :disabled="saving" :url="show.bannerImage" placeholder="/icons/upload-image.png" @change="updateBannerImage" :width="300" :height="168" :aspect="16/9"/>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn tile @click="cancel" :disabled="saving">
              Cancel
            </v-btn>
            <v-btn tile @click="deleteShow" :disabled="saving">
              Delete
            </v-btn>
            <v-spacer></v-spacer>
            <v-switch v-model="show.isPublic" :disabled="saving" label="Public" class="px-6"></v-switch>
            <v-btn tile color="primary" @click="save" :disabled="saving">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="7">
        <div class="show-banner-container">
          <div class="show-banner">
            <showBanner :show="show"/>
          </div>
          <show :show="show" :height="150"/>
        </div>
      </v-col>
      <v-col></v-col>
    </v-row>
    <deleteContentDialog endpoint="AdminShow/deleteShow" @complete="cancel"/>
  </v-container>
</template>
<script>
import { EventBus } from '../../../eventBus';
import { Api } from '../../../js/api';

import deleteContentDialog from './deleteContentDialog';

import show from '../../content/show/show';
import showBanner from '../../global/showBanner';
import imageUploader from '../../global/imageUploader';
export default {
  name: 'Show',
  props: [],
  components: {
    deleteContentDialog,
    show,
    showBanner,
    imageUploader
  },
  data: () => ({
    loading: true,
    saving: false,
    show: null,
    displayedImage: '',
    showTypes: [ 'video', 'audio']
  }),
  computed: {
    showId () {
      return this.$route.params.id
    },
    header () {
      if (this.isNew) {
        return 'Create a new show'
      }
      return 'Edit show'
    },
    isNew () {
      return this.showId == 'new';
    }
  },
  watch: {
  },
  methods: {
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    updateImage: function (image) {
      this.show.image = image;
    },
    updateBannerImage: function (image) {
      this.show.bannerImage = image;
    },
    cancel: function () {
      this.navigate('/admin/shows');
    },
    deleteShow: function () {
      EventBus.$emit('deleteContentDialog', this.show);
    },
    save: function () {
      let self = this;
      self.saving = true;

      if (self.$refs.showImage.file) {
        // upload the file first
        self.$refs.showImage.upload().then(function () {
          self.save();
        })
      } else if (self.$refs.bannerImage.file) {
        // upload the file first
        self.$refs.bannerImage.upload().then(function () {
          self.save();
        })
      } else {
        self.saveShow();
      }
    },  
    saveShow: function () {
      let scope = this
      let data = scope.show;

      if (!scope.isNew) {
        Api.post('AdminShow/updateShow', data).then(function (res) {
          scope.saving = false;

          if(res.valid) {
            EventBus.$emit('alert', {
              text: 'Show updated',
              alertColor: 'green',
              alertIcon: 'success'
            })
          }

          scope.navigate('/admin/shows');
        })
      } else {
        Api.post('AdminShow/createShow', data).then(function (res) {
          scope.saving = false;

          if(res.valid) {
            EventBus.$emit('alert', {
              text: 'Show created',
              alertColor: 'green',
              alertIcon: 'success'
            })
          }

          scope.navigate('/admin/shows');
        })
      }
    }
  },
  mounted () {
    let scope = this;
    if (!scope.isNew) {
      scope.loading = true;
      Api.get('AdminShow/getShow/' + scope.showId).then(function (res) {
        scope.show = res.data;
        scope.loading = false;

        scope.updateImage(res.data.image);
      })
    } else {
      scope.loading = false
      scope.show =  {
        id: null,
        showType: 'video',
        title: 'Show Title',
        subtitle: 'a Disrupt+ video series',
        tagList: 'Documentary - Animation - Philosophy',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dui arcu, fermentum et accumsan sed, lobortis vitae felis. Ut viverra, erat ac tempus pretium, erat dui feugiat erat, non dapibus erat mi ac nulla.',
        descending: false,
        shareholdersOnly: false,
        image: '',
        isPublic: true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.show-header {
  height: 64px;
}
.show-row {
  height: calc(100% - 64px);
}
.show-banner-container {
  padding: 16px;
  background-color: black;
}
.show-banner {
  width: 800px;
}
</style>
