<template>
  <v-container>
    <v-toolbar class="user-toolbar elevation-0">
      <div class="montserrat display-1">Users</div>
      <v-spacer/>
      <v-btn @click="updateNotificationEmails" v-if="isDev">
        Update Email Settings
      </v-btn>
      <v-btn @click="checkSubscriptions" v-if="isDev">
        Check Subscriptions
      </v-btn>
      <v-text-field v-model="search" light class="user-search-bar mx-4" placeholder="search" single-line hide-details tile solo/>
      <v-btn @click="createUser()" class="add-user-button elevation-0" color="#363636" tile>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table 
      :headers="headers"
      :items="users"
      :search="search"
      item-key="id"
      :options="options"
      :footer-props="footerOptions"
      class="users-table elevation-1"
      :loading="loading"
    >
      <template v-slot:[`item.subscriptions[0].premium`]="{ item }">
        {{ item.subscriptions[0].premium ? "Premium" : "Free" }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-show="canEditUser(item)" icon @click="editUser(item)">
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit User</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-show="canDeleteUser(item)" icon @click="deleteUser(item)">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Delete User</span>
        </v-tooltip>
         <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-show="canSendPasswordReset(item)" icon @click="sendResetEmail(item)">
              <v-icon>
                mdi-email
              </v-icon>
            </v-btn>
          </template>
          <span>Send Reset Email</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <createUserDialog @complete="getUsers"/>
    <editUserDialog @complete="getUsers"/>
    <deleteUserDialog @complete="getUsers"/>
    <resetPasswordDialog />
  </v-container>
</template>
<script>
import { EventBus } from '../../../eventBus.js';
import { Api } from '../../../js/api';

import createUserDialog from './createUserDialog'
import editUserDialog from './editUserDialog'
import deleteUserDialog from './deleteUserDialog'
import resetPasswordDialog from './resetPasswordDialog'

export default {
  name: 'Users',
  components: {
    createUserDialog,
    editUserDialog,
    deleteUserDialog,
    resetPasswordDialog
  },
  data: () => ({
    loading: false,
    users: [],
    search: '',
    options: {
      itemsPerPage: 14
    },
    footerOptions: {
      'items-per-page-options': [14, 50, 100]
    },
    headers: [
      { text: 'Name', align: 'start', value: 'username' },
      { text: 'Email', value: 'email' },
      { text: 'Premium', value: 'subscriptions[0].premium' },
      { text: 'Payment', value: 'subscriptions[0].paymentType' },
      { text: 'Role', value: 'role' },
      { text: 'Actions', value: 'actions', sortable: false, width: 200 }
    ],
  }),
  computed: {
    currentUser () {
      return this.$store.getters.getUser;
    },
    availableRoles () {
      let allRoles = ['Dev', 'SuperAdmin', 'Admin', 'Moderator', 'Investor', 'User'];

      let currentRole = this.currentUser.role;
      let currentIndex = allRoles.indexOf(currentRole);

      if (currentRole == 'Dev') {
        return allRoles;
      }

      return allRoles.slice(currentIndex+1);
    },
    isDev () {
      return this.$store.getters.hasKey('Dev');
    }
  },
  methods: {
    checkSubscriptions: function () {
      Api.post('Subscription/updateSubscriptionStatuses').then(function (res) {
        console.log(res);
      })
    },
    updateNotificationEmails: function () {
      Api.post('AdminNotification/batchUpdate').then(function (res) {
        console.log(res);
      })
    },
    getUsers: function () {
      let self = this

      self.loading = true;
      Api.get('User/all').then(function (res) {
        self.users = res.data;
        self.loading = false;
      })
    },
    createUser: function () {
      EventBus.$emit('createUserDialog', null)
    },
    editUser: function (item) {
      EventBus.$emit('editUserDialog', item)
    },
    deleteUser: function (item) {
      EventBus.$emit('deleteUserDialog', item)
    },
    sendResetEmail: function (item) {
      EventBus.$emit('passwordResetUserDialog', item)
    },
    canEditUser: function (item) {
      if (this.availableRoles.includes(item.role)) {
        return true;
      }
      return false;
    },
    canDeleteUser: function (item) {
      if (this.availableRoles.includes(item.role)) {
        return true;
      }
      return false;
    },
    canSendPasswordReset: function (item) {
      if (this.availableRoles.includes(item.role)) {
        return true;
      }
      return false;
    }
  },
  mounted () {
    this.getUsers();
    this.$store.dispatch('hasKey', 'Dev');
  }
}
</script>
<style lang="scss">
.user-toolbar {
  background-color: inherit !important;
}
.users-table .v-data-table__wrapper {
  height: calc(100vh - 220px);
  overflow-y: auto !important;
}
.user-search-bar {
  max-width: 300px;
}
.user-search-bar .v-input__slot{
  border-radius: 0px !important;
}
// .user-search-bar .v-input__slot {
//   background-color: white !important;
//   color: black !important;
// }
.add-user-button {
  height: 48px !important;
}
</style>
