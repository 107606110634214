<template>
  <v-col :cols="colSize" @click="onClick">
    <div class="schedule-item">
      <div class="schedule-item-occurence">
        {{ item.occurence }}
      </div>
      <div>
        <v-row>
          <v-col cols="3" class="pr-0">
            <div class="schedule-item-image pl-2 pt-2 mt-4">
              <v-img :src="item.image" />
            </div>
          </v-col>
          <v-col>
            <div class="schedule-item-title pt-2">
              {{ item.title }}
            </div>
            <div class="schedule-item-text">
              {{ item.text }}
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-col>
</template>
<script>
export default {
  name: 'ScheduleItem',
  props: ['item', 'size'],
  components: {
  },
  data: () => ({
  }),
  computed: {
    colSize () {
      if (this.size) {
        return this.size;
      }
      return 4;
    }
  },
  watch: {
  },
  methods: {
    onClick: function () {
      this.$emit('click');
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.schedule-item {
  border-radius: 15px;
  background-color: #2e2e2e;
}
.schedule-item-occurence {
  background-color: #e5ca54;
  text-align: center;
  color: black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.schedule-item-image {

}
.schedule-item-title {

}
.schedule-item-text {
  font-size: 12px;
  color: #c7c7c7;
}
</style>