<template>
  <v-alert v-model="showAlert"
      :type="alertIcon" dense :color="alertColor" transition="fade-transition"
      elevation="8"
      class="alert-box">
      {{ alertText }}
  </v-alert>
</template>
<script>
import { EventBus } from '../../eventBus';
export default {
  name: 'AlertBox',
  components: {
  },
  data: () => ({
    showAlert: false,
    alertText: '',
    alertColor: '',
    alertIcon: 'error'
  }),
  computed: {
  },
  methods: {
    showMessage: function (message) {
      let self = this;
      
      self.showAlert = true;

      self.alertText = message.text;

      if (message.alertColor) {
        self.alertColor = message.alertColor;
      } else {
        self.alertColor = 'primary';
      }

      if (message.alertIcon) {
        self.alertIcon = message.alertIcon;
      } else {
        self.alertIcon = 'error';
      }

      let duration = 2000;
      if (message.duration) {
        duration = message.duration;
      }
      setTimeout(() => {
        self.showAlert = false;
      }, duration);
    }
  },
  mounted () {
    let scope = this
    EventBus.$on('alert', function (message) {
      scope.showMessage(message);
    })
  }
}
</script>
<style lang="scss" scoped>
.alert-box {
  position: fixed !important;
  bottom: 8px !important;
  right: 8px !important;
  margin: 0px !important;
  border-radius: 0 !important;
  z-index: 100 !important;
}
</style>
