<template>
  <div class="episode-group">
    <div class="title-bar">
      <slot></slot>
    </div>
    <div class="episode-list">
      <div class="episode-container" :key="title+'-'+episode.id" v-for="episode in episodes">
        <episode :compact="true" :episode="episode" :height="200"/>
      </div>
    </div>
  </div>
</template>
<script>
  import episode from './episode'
  export default {
    name: 'EpisodeGroup',
    components: {
      episode,
    },
    props: ['title', 'episodes'],
    data: () => ({
    }),
  }
</script>
<style lang="scss" scoped>
  .episode-group {
    margin-bottom: 16px !important;
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .disrupt-mobile .episode-group {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .title-bar {
    height: 40px;
    font-size: 16px;
    padding: 8px 0;
    letter-spacing: 2px;
  }
  .episode-list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 2px;
  }
  .episode-container {
    display: inline-block;
    min-height: 150px;
    width: min-content;
  }
  .episode-container:not(:first-child) {
    margin-left: 8px;
  }
</style>