<template>
  <div class="suggestions" v-bind:class="{ 'pa-2': !isMobile }">
    <upNext v-if="nextEpisode" :episode="nextEpisode" />
    <div v-bind:class="{ 'pa-4': !isMobile }">
      <div v-for="episode in suggestions" :key="episode.id" class="suggestion mb-4">
        <episode :episode="episode" :compact="true" :gutterless="isMobile"/>
      </div>
    </div>
  </div>
</template>
<script>
import episode from '../episode/episode.vue'
import upNext from '../episode/upNext.vue'

export default {
  name: 'Suggestions',
  props: ['episode', 'amount'],
  components: {
    upNext,
    episode
  },
  data: () => ({
    loading: false,
    nextEpisode: null
  }),
  computed: {
    suggestions () {
      return this.$store.getters.getVideoSuggestions;
    },
    isMobile () {
      return this.$store.getters.getIsMobile;
    },
  },
  watch: {
  },
  methods: {
    getSuggestions: function () {
      let self = this;

      self.loading = true;
      let data = {
        episodeId: null,
        amount: self.amount
      }

      if (self.episode) {
        data.episodeId = self.episode.id
      }

      self.$store.dispatch('getVideoSuggestions', data).then(function (res) {
        self.nextEpisode = res.upNext;
        self.loading = false;
      })
    }
  },
  mounted () {
    this.getSuggestions();
  }
}
</script>
<style lang="scss" scoped>
.suggestion {
  // padding: 16px;
}
</style>
