<template>
  <v-container fill-height>
    <v-row class="content-ideas-title">
      <v-col class="text-center display-1">
        <div>Content Ideas</div>
      </v-col>
    </v-row>
    <v-row class="content-ideas-list">
      <v-col>
        <v-container>
          <contentIdea :contentIdea="contentIdea" v-for="contentIdea in contentIdeas" :key="contentIdea.id" />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Api } from '../../../js/api';
import contentIdea from './contentIdea.vue'

export default {
  name: 'ContentIdeas',
  props: [],
  components: {
    contentIdea
  },
  data: () => ({
    contentIdeas: [],
    loading: false,
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    getData: function () {
      let scope = this;

      scope.loading = true;
      Api.get('AdminInvestorContentIdeas').then(function (res) {
        scope.contentIdeas = res.data;
        scope.loading = false;
      })
    }
  },
  mounted () {
    this.getData();
  }
}
</script>
<style lang="scss" scoped>
.content-ideas-title {
  height: 100px;
}
.content-ideas-list {
  height: calc(100% - 100px);
  overflow-y: auto;
}
</style>
