import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Content from '../views/Content.vue'

// import EpisodeComponent from '../views/Episode.vue'
import EpisodePage from '../views/EpisodePage.vue'

import AdminDashboard from '../components/admin/dashboard/dashboard.vue'
import AdminAnalytics from '../components/admin/analytics/analytics.vue'
import AdminShows from '../components/admin/content/shows.vue'
import AdminShow from '../components/admin/content/show.vue'
import AdminEpisode from '../components/admin/content/episode.vue'
import AdminEpisodes from '../components/admin/content/episodes.vue'
import AdminCategories from '../components/admin/categories/categories.vue'
import AdminPromotional from '../components/admin/promotional/promotional.vue'
import AdminNotifications from '../components/admin/notifications/notifications.vue'
import AdminLivestream from '../components/admin/livestream/livestream.vue'
import AdminUsers from '../components/admin/users/users.vue'
import AdminKeys from '../components/admin/keys/keys.vue'
import AdminCoupons from '../components/admin/coupons/coupons.vue'

import Shareholders from '../components/admin/investors/shareholders.vue'
import ContentIdeas from '../components/admin/investors/contentIdeas.vue'
import InvestorInfo from '../components/admin/investors/investorInfo.vue'
import FinancialReports from '../components/admin/investors/financialReports.vue'
import Bulletins from '../components/admin/investors/bulletins.vue'
import Schedule from '../components/admin/investors/schedule.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Content',
    meta: {
      toolbar: true,
      absoluteToolbar: true,
      dontRequireLogin: true
    },
    component: Content,
    children: [
      {
        path: 'show/:showId',
        name: 'Show',
        meta: {
          toolbar: true,
          hideMobileToolbar: true,
          absoluteToolbar: true,
          dontRequireLogin: true
        }
      },
      {
        path: 'episode/:episodeId',
        name: 'Episode',
        meta: {
          toolbar: true,
          hideMobileToolbar: true,
          absoluteToolbar: true,
          fadeInToolbar: true,
          dontRequireLogin: true
        },
        component: EpisodePage
      }
    ]
  },
  {
    path: '/dev',
    name: 'Dev',
    meta: {
      toolbar: false,
      dontRequireLogin: true
    },
    component: () => import('../views/Dev.vue')
  },
  {
    path: '/watch/:episodeId',
    name: 'Watch',
    meta: {
      toolbar: false,
      background: '#000000',
      dontRequireLogin: true
    },
    component: () => import('../views/Watch.vue')
  },
  {
    path: '/livestream',
    name: 'Livestream',
    meta: {
      toolbar: true,
      dontRequireLogin: true
    },
    component: () => import('../views/Livestream.vue')
  },
  {
    path: '/rewards',
    name: 'Rewards',
    meta: {
      toolbar: true,
      absoluteToolbar: true,
      dontRequireLogin: true
    },
    component: () => import('../views/Rewards.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      toolbar: false,
      dontRequireLogin: true
    },
    component: () => import('../views/Register.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      toolbar: true,
      skipMaintenance: true,
      dontRequireLogin: true
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/legacy',
    name: 'Legacy',
    meta: {
      toolbar: false,
      dontRequireLogin: true
    },
    component: () => import('../views/Legacy.vue')
  },
  {
    path: '/upgrade',
    name: 'Upgrade',
    meta: {
      toolbar: false
    },
    component: () => import('../views/Upgrade.vue')
  },
  {
    path: '/account',
    name: 'Account',
    meta: {
      toolbar: true,
      background: '#0f0f0f'
    },
    component: () => import('../views/Account.vue')
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    meta: {
      toolbar: true,
      dontRequireLogin: true
    },
    component: () => import('../views/ForgotPassword.vue')
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    meta: {
      toolbar: true,
      dontRequireLogin: true
    },
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/paypalComplete/',
    name: 'PaypalComplete',
    meta: {
      toolbar: false,
      background: '#000000',
      dontRequireLogin: true
    },
    component: () => import('../views/PaypalComplete.vue')
  },
  {
    path: '/investors',
    name: 'Investors',
    meta: {
      toolbar: true,
      absoluteToolbar: true,
      dontRequireLogin: true
      // background: '#000000',
    },
    component: () => import('../views/Investors.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      toolbar: true,
      key: 'Admin'
    },
    component: () => import('../views/Admin.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'AdminDashboard',
        meta: {
          toolbar: true,
          key: 'Dashboard'
        },
        component: AdminDashboard,
      },
      {
        path: 'analytics',
        name: 'AdminAnalytics',
        meta: {
          toolbar: true,
          key: 'Analytics'
        },
        component: AdminAnalytics,
      },
      {
        path: 'analytics/:id',
        name: 'AdminAnalyticsEpisode',
        meta: {
          toolbar: true,
          key: 'Analytics'
        },
        component: AdminAnalytics,
      },
      {
        path: 'show/:id',
        name: 'AdminShow',
        meta: {
          toolbar: true,
          key: 'ShowManagement'
        },
        component: AdminShow,
      },
      {
        path: 'shows',
        name: 'AdminShows',
        meta: {
          toolbar: true,
          key: 'ShowManagement'
        },
        component: AdminShows,
      },
      {
        path: 'episode/:showId/:id',
        name: 'AdminEpisode',
        meta: {
          toolbar: true,
          key: 'EpisodeManagement'
        },
        component: AdminEpisode,
      },
      {
        path: 'episodes/:id',
        name: 'AdminShowEpisodes',
        meta: {
          toolbar: true,
          key: 'EpisodeManagement'
        },
        component: AdminEpisodes,
      },
      {
        path: 'episodes',
        name: 'AdminEpisodes',
        meta: {
          toolbar: true,
          key: 'EpisodeManagement'
        },
        component: AdminEpisodes,
      },
      {
        path: 'categories',
        name: 'AdminCategories',
        meta: {
          toolbar: true,
          key: 'CategoryManagement'
        },
        component: AdminCategories,
      },
      {
        path: 'promotional',
        name: 'AdminPromotional',
        meta: {
          toolbar: true,
          key: 'CarouselManagement'
        },
        component: AdminPromotional,
      },
      {
        path: 'notifications',
        name: 'AdminNotifications',
        meta: {
          toolbar: true,
          key: 'Notifications'
        },
        component: AdminNotifications,
      },
      {
        path: 'livestream',
        name: 'AdminLivestream',
        meta: {
          toolbar: true,
          key: 'LivestreamManagement'
        },
        component: AdminLivestream,
      },
      {
        path: 'users',
        name: 'AdminUsers',
        meta: {
          toolbar: true,
          key: 'UserManagement'
        },
        component: AdminUsers,
      },
      {
        path: 'coupons',
        name: 'AdminCoupons',
        meta: {
          toolbar: true,
          key: 'CouponManagement'
        },
        component: AdminCoupons,
      },
      {
        path: 'keys',
        name: 'AdminKeys',
        meta: {
          toolbar: true,
          key: 'RewardsManagement'
        },
        component: AdminKeys,
      },
      {
        path: 'shareholders',
        name: 'Shareholders',
        meta: {
          toolbar: true,
          key: 'ShareholderManagement'
        },
        component: Shareholders,
      },
      {
        path: 'contentIdeas',
        name: 'ContentIdeas',
        meta: {
          toolbar: true,
          key: 'ContentIdeasManagement'
        },
        component: ContentIdeas,
      },
      {
        path: 'investorInfo',
        name: 'InvestorInfo',
        meta: {
          toolbar: true,
          key: 'InvestorInfoManagement'
        },
        component: InvestorInfo,
      },
      {
        path: 'financialReports',
        name: 'FinancialReports',
        meta: {
          toolbar: true,
          key: 'FinanceManagement'
        },
        component: FinancialReports,
      },
      {
        path: 'bulletins',
        name: 'Bulletins',
        meta: {
          toolbar: true,
          key: 'BulletinManagement'
        },
        component: Bulletins,
      },
      {
        path: 'schedule',
        name: 'Schedule',
        meta: {
          toolbar: true,
          key: 'ScheduleManagement'
        },
        component: Schedule,
      },    
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.key) {
    next();
  } else {
    let key = to.meta.key;
    store.dispatch('hasKey', key).then(function (authorized) {
      if (authorized) {
        next();
      } else {
        next('/');
      }
    })
  }
})

export default router
