<template>
  <div class="content">
    <div v-show="!popoutOpen || popoutMinimised">
      <carousel v-if="carouselPages && carouselPages.length > 0" :items="carouselPages"/>
      <div style="height: 64px;" v-else></div>

      <episodeGroup v-if="isLoggedIn && continueWatchingEpisodes && continueWatchingEpisodes.length > 0" :title="'continue'" :episodes="continueWatchingEpisodes">
        <div class="queue-title">
          <div class="d-inline">
            Continue for {{ currentUser.username }}
          </div>
          <div class="d-inline" v-if="continueWatchingEpisodes.length > 2">
            <span v-if="!clearingQueue" @click="clearingQueue = true" class="clear-queue cursor-pointer">Clear Queue</span>
            <span v-if="clearingQueue" class="clear-confirm mx-2">Are you sure?</span>
            <span v-if="clearingQueue" class="clear-dump cursor-pointer mx-2 pa-1" @click="clearQueue">Dump it.</span>
            <span v-if="clearingQueue" class="clear-cancel cursor-pointer pa-1" @click="clearingQueue = false">Nevermind</span>
          </div>
        </div>
      </episodeGroup>
      <category v-if="investor && investorShows.length > 0" :height="150" title="Shareholder's Only" :items="investorShows"/>
      <category :title="category.name" :height="getHeightForCategory(category)" :items="getItemsForCategory(category)" v-for="category in categories" :key="category.id"/>
      <showDialog/>
    </div>
    <router-view>
    </router-view>
  </div>
</template>

<script>
// import moment from 'moment'

import carousel from '../components/content/carousel/carousel'
import episodeGroup from '../components/content/episode/episodeGroup'
import category from '../components/content/category/category'

import showDialog from '../components/content/dialog/showDialog'

import { EventBus } from '../eventBus';

export default {
  name: 'Home',
  components: {
    carousel,
    episodeGroup,
    category,
    showDialog
  },
  data: () => ({
    continueWatchingEpisodes: [],
    clearingQueue: false,
    minimisedEpisode: false
  }),
  watch: {
    unfinishedViews: function () {
      this.updateContinues();
    },
    $route (to, from) { 
      if (from.name == 'Show') {
        this.$store.commit('setShowDialog', false);
      }
      if (to.name == 'Show') {
        this.$store.commit('setShowDialog', true);
      }
    }
  },
  methods: {
    getData: function () {
      let scope = this;
      return new Promise((resolve) => {
        scope.$store.dispatch('getHomeData').then(function () {
          if (scope.$store.getters.getIsLoggedIn) {
            scope.$store.dispatch('getUserViews').then(function () {
              resolve();
            })
          } else {
            resolve();
          }
        })
      })
    },
    getShow: function (showId) {
      let scope = this;
      return new Promise((resolve) => {
        scope.$store.dispatch('getShow', showId).then(function (show) {
          resolve(show)
        })
      })
    },
    getEpisode: function (episodeId) {
      let scope = this;
      return new Promise((resolve) => {
        scope.$store.dispatch('getEpisode', episodeId).then(function (episode) {
          resolve(episode)
        })
      })
    },
    getShowsForCategory: function (category) {
      if (this.shows && category.categoryShows) {
        return this.shows.filter(s => category.categoryShows.some(cs => cs.showId == s.id));
      }
      return [];
    },
    getInvestorItems: function () {
      let scope = this;
      scope.$store.dispatch('getInvestorShows');
    },
    getItemsForCategory: function (category) {
      let categoryItems = [];
      if (this.shows && category.categoryShows) {
        category.categoryShows.forEach(categoryShow => {
          let item = this.shows.find(s => s.id == categoryShow.showId);
          if (item) {
            categoryItems.push({
              createdAt: categoryShow.createdAt,
              order: categoryShow.order,
              item: item
            });
          }
        })
      }
      if (category.categoryEpisodes) {
        category.categoryEpisodes.forEach(categoryEpisode => {
          categoryItems.push({
            createdAt: categoryEpisode.createdAt,
            order: categoryEpisode.order,
            item: categoryEpisode.episode
          });
        })
      }

      categoryItems.sort(function (a, b) {
        if (a.order > b.order) return 1;
        else if (a.order < b.order) return -1;
        else return 0;
        // var momentA = moment(a.createdAt,"YYYY-MM-DD HH:mm:ss");
        // var momentB = moment(b.createdAt,"YYYY-MM-DD HH:mm:ss");
        // if (momentA > momentB) return 1;
        // else if (momentA < momentB) return -1;
        // else return 0;
      })  

      return categoryItems.map(c => c.item);
    },
    getHeightForCategory: function (category) {
      if (category.categoryShows && category.categoryShows.length > 0) {
        return 150;
      }
      return 200;
    },
    updateContinues: function () {
      let scope = this;
      scope.continueWatchingEpisodes = scope.unfinishedViews.map(uv => uv.episodeViewModel);
    },
    clearQueue: function () {
      let scope = this;
      scope.clearingQueue = false;
      scope.$store.dispatch('clearUserViews');
    },
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.getIsLoggedIn;
    },
    currentUser () {
      return this.$store.getters.getUser;
    },
    carouselPages () {
      return this.$store.getters.getCarouselPages;
    },
    shows () {
      return this.$store.getters.getShows;
    },
    categories () {
      return this.$store.getters.getCategories;
    },
    unfinishedViews () {
      return this.views.filter(v => (v.max - v.progress) > 10.0).sort(function (a, b) {
        return a.createdAt - b.createdAt;
      });
    },
    views () {
      return this.$store.getters.getUserViews;
    },
    popoutOpen () {
      return this.$store.getters.getPopoutOpen;
    },
    popoutMinimised () {
      return this.$store.getters.getPopoutMinimised;
    },
    isMobile () {
      return this.$store.getters.getIsMobile;
    },
    investor () {
      return this.$store.getters.hasKey('Investor');
    },
    investorShows () {
      return this.$store.getters.getInvestorShows;
    }
  },
  mounted () {
    let self = this;

    self.getData().then(function () {
      if (self.$route.params.showId) {
        self.getShow(self.$route.params.showId).then(function(show) {
          EventBus.$emit('viewShow', show)
        })
      } else if (self.$route.params.episodeId) {
        self.getEpisode(self.$route.params.episodeId).then(function (episode) {
          EventBus.$emit('viewEpisode', episode)
        })
      } else if(self.investor) {
        self.getInvestorItems();
      }
    });
  }
}
</script>
<style lang="scss" scoped>
.disrupt-mobile .content {
  margin-bottom: 54px;
}
.queue-title span {
  font-size: 12px;
  letter-spacing: 0px;
}

.clear-queue {
  font-style: italic;
  color: grey;
}

.clear-confirm {

}
.clear-dump {
  background-color: #a70000;
}
.clear-cancel {
  background-color: #404040;
}


</style>

<style>
/* .radial-gradient {
  -webkit-mask-image: -webkit-radial-gradient(circle at center, #000000ff 0%, #00000000 80%);
  mask-image: radial-gradient(circle at center, #000000ff 0%, #00000000 80%);
} */



</style>