<template>
  <v-card class="login-panel" v-bind:class="{ 'elevation-0': !menu }" :width="width" max-width="100%">
    <v-card-text>
      <v-text-field ref="username" label="Username / Email" v-model="username" name="username" class="login-fields" light solo @keydown="usernameKeydown" :error-messages="userMessage"></v-text-field>
      <v-text-field ref="password" label="Password" v-model="password" name="password" class="login-fields" type="password" light solo @keydown="passwordKeydown" :error-messages="passwordMessage"></v-text-field>
      <v-btn @click="navigate('./forgotPassword')" text class="px-0">
        Forgot Password
      </v-btn>
      <v-btn v-if="legacy" @click="navigate('./legacy')" text class="px-0">
        <v-icon small class="mr-2">mdi-information</v-icon>
        Legacy Accounts
      </v-btn>
    </v-card-text>
    <v-card-actions class="pa-0" v-if="mobile">
      <v-layout row class="mt-4">
        <v-flex xs12 class="pb-2">
          <v-btn :disabled="!canLogin" :loading="loading" @click="login()" tile elevation="0" height="48" class="mobile-login-button" color="primary">
            Login
          </v-btn>
        </v-flex>
        <v-flex xs12>
          <v-btn tile elevation="0" height="48" @click="navigate('register')" class="mobile-register-button">
            Register
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-actions>
    <v-card-actions class="pa-0" v-else>
      <v-spacer/>
      <v-btn :disabled="!canLogin" :loading="loading" @click="login()" tile class="login-button" color="primary">
        Login
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { Api } from '../../js/api';
import { EventBus } from '../../eventBus';
export default {
  name: 'login',
  props: ['menu'],
  data: () => ({
    username: '',
    password: '',
    userMessage: '',
    passwordMessage: '',
    loading: false
  }),
  computed: {
    mobile () {
      return this.$store.getters.getIsMobile;
    },
    width () {
      if (this.menu) {
        return 300;
      }
      return 500;
    },
    height () {
      if (this.menu) {
        return 250;
      }
      return 600;
    },
    canLogin () {
      if (this.username != '' && this.password != '') {
        return true;
      }
      return false;
    },
    legacy () {
      return this.$store.getters.getlegacy;
    }
  },
  methods: {
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    usernameKeydown: function (e) {
      if (e.key == 'Enter') {
        this.$refs.password.$el.getElementsByTagName('input')[0].focus();
      }
    },
    passwordKeydown: function (e) {
      if (e.key == 'Enter') {
        this.login();
      }
    },
    login: function () {
      let self = this;
      self.loading = true;

      let data = {
        username: this.username,
        password: this.password
      }

      Api.post('Auth/login', data).then(function (res) {
        if (res.data == true) {
          self.$store.dispatch('getUser');
          self.$store.commit('setIsLoggedIn', true);
          self.navigate('/')

          self.loading = false;
        } else {
          if (res.data == 2) {
            self.userMessage = "USER NOT FOUND";
          } else if (res.data == 3) {
            self.passwordMessage = "WRONG PASS";
          }
          EventBus.$emit('alert', {
            text: 'Incorrect username or password.',
            alertColor: '#a70505'
          })
          self.loading = false;
        }
      })
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.login-panel {
  background-color: #202020 !important;
  border-radius: 0px !important;
}
.forgot-password {
  margin-top: -24px;
  margin-bottom: 16px;
}
</style>
<style lang="scss">
.login-fields .v-input__slot{
  border-radius: 0px !important;
}
.login-fields .v-messages__message {
  color: red !important;
  text-align: right;
}

.mobile-login-button,
.mobile-register-button {
  width: 100%;
}
</style>
