<template>
  <v-container fill-height>
    <v-row justify="top" class="shows-header">
      <v-col class="text-center display-1">
        <div>Shows</div>
      </v-col>
    </v-row>
    <v-row class="shows-list pa-4">
      <div class="show ma-4 new-show cursor-pointer" @click="createNewShow">
        <v-img
          :src="'/icons/new-show.png'"
          :aspect-ratio="aspect"
          :height="height"
          :max-height="height"
          :width="width"
          :max-width="width"/>        
      </div>
      <div class="show ma-4 cursor-pointer" v-for="(show, n) in shows" :key="show.id" @click="editShow(show)">
        <v-img
          :src="show.image"
          :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
          :aspect-ratio="aspect"
          :height="height"
          :max-height="height"
          :width="width"
          :max-width="width"
          class="grey lighten-2"/>
        <div class="pa-2">
          {{ show.title }}
        </div>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import { Api } from '../../../js/api';

export default {
  name: 'Shows',
  props: [],
  components: {
  },
  data: () => ({
    shows: [],
    height: 260,
    aspect: 16/9
  }),
  computed: {
    width () {
      return this.aspect * this.height;
    }
  },
  watch: {
  },
  methods: {
    getShows: function () {
      let self = this

      self.loading = true;
      Api.get('AdminShow/all').then(function (res) {
        self.shows = res.data;
        self.loading = false;
      })
    },
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    editShow: function (show) {
      this.navigate('/admin/show/' + show.id);
    },
    createNewShow: function () {
      this.navigate('/admin/show/new');
    }
  },
  mounted () {
    this.getShows();
  }
}
</script>
<style lang="scss" scoped>
.shows-header {
  height: 64px;
}
.shows-list {
  height: calc(100% - 64px);
  overflow-y: auto;
}
.show {
  height: 300px;
}
</style>
