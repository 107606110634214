<template>
  <v-container fill-height>
    <v-row class="categories-header">
      <v-col class="text-center display-1">
        <div>Categories</div>
      </v-col>
    </v-row>
    <v-row class="categories">
      <v-col cols="4" class="fill-height categories-list">
        <v-list>
          <v-list-item @click="selectCategory(category)" v-for="category in categories" :key="category.id" v-bind:class="{ 'selected-category': isSelected(category) }">
            <v-list-item-title>{{ category.name }}</v-list-item-title>
            <v-list-item-action>
              <v-btn v-if="categories.indexOf(category) > 0" icon @click="moveUp(category)">
                <v-icon>
                  mdi-arrow-up
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn v-if="categories.indexOf(category) < categories.length - 1" icon @click="moveDown(category)">
                <v-icon>
                  mdi-arrow-down
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn icon @click="editCategory(category)">
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn icon @click="deleteCategory(category)">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="createCategory">
            <v-list-item-title>New</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="8" class="fill-height">
        <div class="assigned-shows-list fill-height" v-if="category != null">
          <div class="show ma-4 cursor-pointer" v-for="(categoryItem, n) in items" :key="categoryItem.id">
            <v-hover
              v-slot="{ hover }"
              open-delay="100"
            >
              <v-img
                :src="categoryItem.item.image"
                :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                :aspect-ratio="aspect"
                :height="height"
                :max-height="height"
                :width="width"
                :max-width="width"
                class="grey lighten-2">
                <div class="category-hover-container" v-if="hover">
                  <v-layout fill-height>
                    <v-row justify="center" align-content="center" class="text-center">
                      <v-col>
                        <v-btn v-if="n > 0" icon @click="changeOrder(categoryItem, -1)">
                          <v-icon size="60">mdi-arrow-left</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn icon @click="unassignItem(categoryItem)">
                          <v-icon size="60">mdi-delete</v-icon>
                        </v-btn>
                        <!-- <v-img :src="hoverImage"></v-img> -->
                      </v-col>
                      <v-col>
                        <v-btn v-if="n < items.length - 1" icon @click="changeOrder(categoryItem, 1)">
                          <v-icon size="60">mdi-arrow-right</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-layout>
                </div>
              </v-img>
            </v-hover>
            <div class="pa-2" v-if="categoryItem.item.episodeType">
              {{ categoryItem.item.name }}
            </div>
            <div class="pa-2" v-else>
              {{ categoryItem.item.title }}
            </div>
          </div>
          <div class="show ma-4 new-show cursor-pointer" @click="assignNewItem">
            <v-img
              :src="'/icons/new-show.png'"
              :aspect-ratio="aspect"
              :height="height"
              :max-height="height"
              :width="width"
              :max-width="width"/>        
          </div>
        </div>
      </v-col>
    </v-row>
    <showPicker ref="showPicker" :shows="shows" :selectableShows="selectableShows" @selected="assignItem" @complete="getCategories"/>
    <deleteCategoryDialog @complete="getCategories" />
    <editCategoryDialog @complete="getCategories" />
    <unassignCategoryShowDialog @complete="showUnassigned"/>
  </v-container>
</template>
<script>
import { EventBus } from '../../../eventBus.js';
import { Api } from '../../../js/api';

// import moment from 'moment'

import showPicker from '../../global/showPicker';
import deleteCategoryDialog from './deleteCategoryDialog';
import editCategoryDialog from './editCategoryDialog';
import unassignCategoryShowDialog from './unassignCategoryShowDialog';
export default {
  name: 'Categories',
  components: {
    showPicker,
    deleteCategoryDialog,
    editCategoryDialog,
    unassignCategoryShowDialog
  },
  data: () => ({
    loading: false,
    category: null,
    categories: [],
    shows: [],
    height: 260,
    aspect: 16/9,
    items: []
  }),
  computed: {
    hoverImage () {
      return '/icons/delete-image.png';
    },
    width () {
      return this.aspect * this.height;
    },
    filteredShows () {
      if (this.category && this.category.categoryShows) {
        // get all shows
        // where current assigned shows share id
        return this.shows.filter(s => this.category.categoryShows.some(cs => cs.showId == s.id));
      }
      return [];
    },
    selectableShows () {
      let self = this;
      if (self.category && self.category.categoryShows) {
        return self.shows.filter(s => !self.filteredShows.some(fs => fs.id == s.id));
      }
      return [];
    }
  },
  methods: {
    assignItem: function (item) {
      let self = this;

      Api.post('AdminCategory/assign/' + self.category.id, item).then(function () {
        //self.items.push(res.data);
        self.getCategories()
      })
    },
    changeOrder: function (item, change) {
      let self = this;

      let swapIndex = self.items.indexOf(item) + change;
      let swapItem = self.items[swapIndex];

      let data = {
        categoryItem1: item.id,
        categoryItem2: swapItem.id
      }

      self.loading = true;
      Api.post('AdminCategory/swapCategoryItems', data).then(function () {
        self.loading = false;

        let tempOrder = item.order;
        item.order = swapItem.order;
        swapItem.order = tempOrder;

        self.sortCategoryItems(self.items)
      })
    },
    getCategories: function () {
      let self = this;

      self.loading = true;
      Api.get('AdminCategory/all').then(function (res) {
        self.categories = res.data;
        self.loading = false;

        if (self.category == null && self.categories.length > 0) {
          self.selectCategory(self.categories[0]);
        } else if (self.category != null) {
          self.selectCategory(self.categories.find(c => c.id == self.category.id));
        }
      })
    },
    getShows: function () {
      let self = this

      return new Promise((resolve) => {
        Api.get('AdminShow/all').then(function (res) {
          self.shows = res.data;
          resolve();
        })
      })
    },
    selectCategory: function (category) {
      this.category = category;
      this.items = this.getItems(category);
    },
    getItems: function (category) {
      let categoryItems = [];

      if (this.shows && category.categoryShows) {
        category.categoryShows.forEach(categoryShow => {
          categoryItems.push({
            id: categoryShow.id,
            order: categoryShow.order,
            createdAt: categoryShow.createdAt,
            item: this.shows.find(s => s.id == categoryShow.showId)
          });
        })
      }
      if (category.categoryEpisodes) {
        category.categoryEpisodes.forEach(categoryEpisode => {
          categoryItems.push({
            id: categoryEpisode.id,
            order: categoryEpisode.order,
            createdAt: categoryEpisode.createdAt,
            item: categoryEpisode.episode
          });
        })
      }
      
      return this.sortCategoryItems(categoryItems)
    },
    sortCategoryItems: function (categoryItems) {
      categoryItems.sort(function (a, b) {
        if (a.order > b.order) return 1;
        else if (a.order < b.order) return -1;
        else return 0;
        // var momentA = moment(a.createdAt,"YYYY-MM-DD HH:mm:ss");
        // var momentB = moment(b.createdAt,"YYYY-MM-DD HH:mm:ss");
        // if (momentA > momentB) return 1;
        // else if (momentA < momentB) return -1;
        // else return 0;
      })
      return categoryItems;
    },
    isSelected: function (category) {
      if (this.category) {
        return this.category.id == category.id;
      }
      return false;
    },
    assignNewItem: function () {
      this.$refs.showPicker.dialog = true;
    },
    unassignItem: function (categoryItem) {
      let data = {
        categoryId: this.category.id,
        showId: null,
        episodeId: null
      }

      if (categoryItem.item.episodeType) {
        data.episodeId = categoryItem.item.id;
      } else {
        data.showId = categoryItem.item.id;
      }

      EventBus.$emit('unassignCategoryShowDialog', data)
    },
    showUnassigned: function (data) {
      let item = this.items.find(i => i.item.id == data.showId || i.item.id == data.episodeId);
      if (item != null) {
        this.items.splice(this.items.indexOf(item), 1); 
      }
    },
    createCategory: function () {
      EventBus.$emit('createCategoryDialog');
    },
    editCategory: function (category) {
      EventBus.$emit('editCategoryDialog', category);
    },
    deleteCategory: function (category) {
      EventBus.$emit('deleteCategoryDialog', category);
    },
    moveUp: function (category) {
      let order = category.order;
      let newOrder = order - 1;

      let swapCategory = this.categories.find(c => c.order == newOrder);

      this.swapCategories(category, swapCategory);
    },
    moveDown: function (category) {
      let order = category.order;
      let newOrder = order + 1;
      
      let swapCategory = this.categories.find(c => c.order == newOrder);

      this.swapCategories(category, swapCategory);
    },
    swapCategories: function (category1, category2) {
      let self = this;
      let data = {
        category1: category1.id,
        category2: category2.id
      }
      Api.post('AdminCategory/swapCategories', data).then(function () {
        self.getCategories();
      })
    }
  },
  mounted () {
    let scope = this;
    scope.getShows().then(function () {
      scope.getCategories();
    })
  }
}
</script>
<style lang="scss" scoped>
.categories-header {
  height: 64px;
}
.categories {
  height: calc(100% - 64px);
}
.categories-list {
  overflow-y: auto;
}
.fill-height {
  height: 100%;
}
.assigned-shows-list {
  overflow-y: auto;
}


.category-hover-container {
  width: 100%;
  height: 100%;
}

.selected-category {
  background-color: #ffffff22;
}
.show {
  height: 300px;
  display: inline-grid;
}
</style>
