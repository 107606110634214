<template>
  <div class="pa-4">
    <v-row align="center" justify="center">
      <v-col class="col-spacer"></v-col>
      <v-col class="image-col">
        <v-img content-class="image-uploader" :src="imageUrl" :height="internalHeight" :width="internalWidth" contain :aspect-ratio="internalAspect" @click="edit">
          <v-btn icon small class="clear-icon" v-if="hasFile" @click="clear($event)">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
          <v-btn icon small class="edit-icon" @click="edit($event)">
            <v-icon>
              mdi-square-edit-outline
            </v-icon>
          </v-btn>
          <v-file-input
            ref="file-input"
            class="d-none"
            v-model="file"
            :disabled="disabled"
            hide-input
            @change="fileChanged"
            @click:append="fileChanged"
            accept="image/*"
            truncate-length="15"
          ></v-file-input>
        </v-img>
      </v-col>
      <v-col class="col-spacer"></v-col>
    </v-row>
  </div>
</template>
<script>
import { Api } from '../../js/api';
import { EventBus } from '../../eventBus';
export default {
  name: 'ImageUploader',
  props: ['value', 'disabled', 'url', 'placeholder', 'width', 'height', 'aspect', 'maxSize'],
  components: {
  },
  data: () => ({
    internalUrl: '',
    internalWidth: '',
    internalHeight: 200,
    internalAspect: 1,
    hasFile: false,
    file: null
  }),
  computed: {
    imageUrl () {
      if (this.internalUrl && this.internalUrl.length > 0)
        return this.internalUrl;
      return this.placeholder;
    }
  },
  watch: {
    value: function () {
      this.internalUrl = this.url;
      this.file = null;
      this.hasFile = false;
    },
    url: function () {
      if (this.url !== this.internalUrl) {
        this.internalUrl = this.url;
      }
    }
  },
  methods: {
    fileChanged: function (newfile) {
      let self = this
      if (newfile) {
        if (self.maxSize != null && newfile.size > self.maxSize) {
          // file is too big
          self.internalUrl = self.url;
          self.hasFile = true;
          self.file = null;
          self.$emit('change', self.internalUrl);

          EventBus.$emit('alert', {
            text: 'File cannot be larger than ' + (self.maxSize / (1024 * 1024)).toFixed(2) + 'MB',
            alertColor: '#a70505'
          })
          return;
        }
        var reader = new FileReader();
      
        reader.onload = function(e) {
          self.internalUrl = e.target.result;
          self.hasFile = true;
          self.$emit('change', self.internalUrl);
        }
        reader.readAsDataURL(newfile);
      } else {
        self.internalUrl = self.url;
        self.hasFile = true;
        self.$emit('change', self.internalUrl);
      }
    },
    upload: function () {
      let self = this;
      return new Promise((resolve) => {
        Api.upload('File/upload', self.file).then(function (res) {
          let imageId = res[0].id;
          self.$emit('input', imageId);
          self.file = null;
          resolve(imageId);
        })
      })
    },
    edit: function (e) {
      e.stopPropagation();

      this.$refs['file-input'].$refs.input.click();
    },
    clear: function (e) {
      e.stopPropagation();

      this.internalUrl = '';
      this.file = null;
      this.hasFile = false;
      this.$emit('input', null);
      this.$emit('change', self.internalUrl);
    }
  },
  mounted () {
    this.internalUrl = this.url;

    if (this.width) {
      this.internalWidth = this.width;
    }
    if (this.height) {
      this.internalHeight = this.height;
    }

    if (this.aspect) {
      this.internalAspect = this.aspect;
    }

    if (this.value) {
      this.hasFile = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.image-uploader {
  position: relative;
}
.edit-icon {
  position: absolute;
  right: 4px;
  bottom: 4px;
  background-color: #232323;
  border-radius: 32px;
  padding: 4px;
}
.clear-icon {
  position: absolute;
  right: 4px;
  top: 4px;
  background-color: #232323;
  border-radius: 32px;
  padding: 4px;
}
.col-spacer {
  padding: 0px !important;
}
</style>
<style lang="scss">
.edit-icon .v-input__prepend-outer {
  background-color: #232323;
  border-radius: 32px;
  padding: 4px;
}
</style>
