<template>
  <div class="max-size">
    <video v-show="show && useVideo" ref="video" id="videoPlayer" class="max-size"></video>
    <audio v-show="show && !useVideo" ref="audio" id="audioPlayer" class="max-size"></audio>
  </div>
</template>
<script>
import dashjs from 'dashjs';

export default {
  name: 'DashPlayer',
  props: ['qualities'],
  components: {
  },
  data: () => ({
    videoPlayer: null,
    audioPlayer: null,
    video: null,
    bitrates: [],
    cfg: null,
    selected: null,
    useVideo: true,
    show: false,
    seekTime: null
  }),
  errorCaptured(err) {
    this.$store.commit('addError', err);
  },
  computed: {
    availableMpd () {
      return this.qualities.find(q => q.format == 'mpd');
    },
    player () {
      if (this.useVideo) {
        return this.videoPlayer;
      } else {
        return this.audioPlayer;
      }
    }
  },
  watch: {
    bitrates: function (newVal, oldVal) {
      if (!oldVal) {
        this.checkSourceChange();
      }
    },
    availableMpd: function () {
      this.createCorrectPlayer();
    }
  },
  methods: {
    isPlaying: function () {
      let scope = this;
      if (scope.player && scope.player.isReady()) {
        return !scope.player.isPaused();
      }
      return false;
    },
    setSource: function (selected, keepProgress, time) {
      let scope = this;

      if (keepProgress) {
        scope.seekTime = time;
      }

      scope.selected = selected;
      scope.checkSourceChange();
    },
    requestFullscreen: function () {

    },
    stop: function () {
      let scope = this;
      if (scope.player && scope.player.stop) {
        scope.player.stop();
      }
    },
    checkSourceChange: function () {
      let scope = this;

      if (scope.bitrates && scope.bitrates.length > 0) {
        let bitrate = scope.bitrates.find(b => b.height == scope.selected.quality);
        if (!bitrate) {
          if (scope.selected.quality == "audio") {
            bitrate = scope.bitrates.find(b => b.mediaType == "audio");
          }
        }
        let bitrateIndex = scope.bitrates.indexOf(bitrate);
        
        let shouldUseVideo = bitrate.mediaType == 'video';

        if (scope.useVideo != shouldUseVideo) {
          scope.useVideo = shouldUseVideo;

          if (scope.seekTime == null) {
            scope.seekTime = scope.player.time();
          }
          
          scope.createCorrectPlayer();
        }

        var cfg = {
          'streaming': {
            'abr': {
              'autoSwitchBitrate': {}
            }
          }
        };

        if (shouldUseVideo) {
          cfg.streaming.abr.autoSwitchBitrate['video'] = false;
          scope.player.updateSettings(cfg);
          scope.player.setQualityFor('video', bitrateIndex, true)
        }

        scope.cfg = scope.player.getSettings();
      }
    },
    togglePlay: function () {
      let scope = this;
      if (scope.isPlaying()) {
        scope.player.pause();
      }
      else {
        scope.player.play()
      }
    },
    setVolume: function (val) {
      let scope = this;
      if (scope.player) {
        scope.player.setVolume(val / 100.0);
      }
    },
    setTimestamp: function (val) {
      let scope = this;
      if (scope.player) {
        if(scope.player.isPaused() == null) {
          scope.seekTime = val;
        } else {
          scope.player.seek(val);
        }
      }
    },
    progressChanged: function (val) {
      let scope = this;
      scope.player.seek((val / 100.0) * scope.player.duration());
    },
    focusVideo: function () {
    },
    // events
    handlePause: function () {
      this.$emit('playerAction');
      this.$emit('pause');
    },
    handlePlay: function () {
      this.$emit('play');
    },
    handleTimeUpdate: function () {
      let scope = this;
      if (scope.player) {
        let data = {
          current: scope.player.time(),
          duration: scope.player.duration()
        }
        scope.$emit('timeUpdate', data);
      }
    },
    handleLoadedMetaData: function () {
      this.$emit('loadedMetaData')
    },
    handleVolumeChange: function () {
      this.$emit('volumeChange', this.video.volume);
    },
    handleEnded: function () {
      this.$emit('ended');
    },
    handleBlur: function () {
      this.$emit('blur');
    },
    handleCanplay: function () {
      let scope = this;
      if (scope.bitrates.length == 0) {
        scope.getBitrates();
        if (scope.selected.quality == 'audio') {
          scope.useVideo = false;
          scope.createCorrectPlayer();
          return;
        }
        scope.show = true;
      }
      if (scope.seekTime != null) {
        scope.player.seek(scope.seekTime);
        scope.seekTime = null;
      }
      scope.$emit('canplay');
    },
    setupPlayer: function () {
      let scope = this;

      let player = dashjs.MediaPlayer().create();
      player.extend("RequestModifier", function () {
        return {
            modifyRequestHeader: function (xhr) {
                /* Add custom header. Requires to set up Access-Control-Allow-Headers in your */
                /* response header in the server side. Reference: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/setRequestHeader */
                /* xhr.setRequestHeader('DASH-CUSTOM-HEADER', 'MyValue'); */
                return xhr;
            },
            modifyRequestURL: function (url) {
                /* Modify url adding a custom query string parameter */
                let replaced = url.replaceAll("%20", "+");
                let quality = scope.qualities.find(f => f.signedUrl.includes(replaced));
                if (quality) {
                  return quality.signedUrl;
                }
                return url;                
                // return url;// + '&customQuery=value';
            }
        };
      });
      return player;
    },
    initalizePlayer: function (player, selector) {
      let scope = this;
      if (scope.availableMpd) {
        player.initialize(document.querySelector(selector), scope.availableMpd.signedUrl, true);
      }
    },
    createCorrectPlayer: function () {
      let scope = this;
      if (scope.useVideo) {
        if (scope.audioPlayer) {
          scope.destroyAudioPlayer();
        }
      } else {
        if (scope.videoPlayer) {
          scope.destroyVideoPlayer();
        }
      }

      if (scope.useVideo) {
        scope.createVideoPlayer();
      } else {
        scope.createAudioPlayer();
      }
    },
    createVideoPlayer: function () {
      let scope = this;
      if (!scope.videoPlayer) {
        scope.videoPlayer = scope.setupPlayer();
        scope.createPlayerEvents(scope.videoPlayer);
      }
      scope.initalizePlayer(scope.videoPlayer, '#videoPlayer');
    },
    destroyVideoPlayer: function () {
      let scope = this;
      scope.videoPlayer.destroy();
      scope.videoPlayer = null;
    },
    createAudioPlayer: function () {
      let scope = this;
      if (!scope.audioPlayer) {
        scope.audioPlayer = scope.setupPlayer();
        scope.createPlayerEvents(scope.audioPlayer);
      }
      scope.initalizePlayer(scope.audioPlayer, '#audioPlayer');
    },
    destroyAudioPlayer: function () {
      let scope = this;
      scope.audioPlayer.destroy();
      scope.audioPlayer = null;
    },
    getBitrates: function () {
      let scope = this;
      let videoBitrates = scope.player.getBitrateInfoListFor('video');
      let audioBitrates = scope.player.getBitrateInfoListFor('audio');
      let foundBitrates = videoBitrates.concat(audioBitrates);
      scope.bitrates = foundBitrates;
    },
    createPlayerEvents: function (player) {
      let scope = this;

      player.on("playbackPaused", scope.handlePause);
      player.on("playbackPlaying", scope.handlePlay);
      player.on("playbackTimeUpdated", scope.handleTimeUpdate);
      player.on("playbackMetaDataLoaded", scope.handleLoadedMetaData);
      player.on("playbackEnded", scope.handleEnded);
      player.on("canPlay", scope.handleCanplay);
    },
    createEvents: function () {
      // let scope = this;

      // scope.video.addEventListener('blur', scope.handleBlur);
    },
  },
  beforeDestroy () {
    // let scope = this;

    // scope.video.removeEventListener('pause', scope.handlePause, false);
    // scope.video.removeEventListener('play', scope.handlePlay, false);
    // scope.video.removeEventListener('timeupdate', scope.handleTimeUpdate);
    // scope.video.removeEventListener('loadedmetadata', scope.handleLoadedMetaData);
    // scope.video.removeEventListener('volumechange', scope.handleVolumeChange, false);
    // scope.video.removeEventListener('ended', scope.handleEnded, false);
    // scope.video.removeEventListener('blur', scope.handleBlur);
    
    console.log('dash player destroyed')
  },
  mounted () {
    let scope = this;

    scope.video = scope.$refs.video;
    scope.audio = scope.$refs.audio;

    scope.createEvents();
    scope.createVideoPlayer();
  }
}
</script>
<style lang="scss" scoped>
.max-size {
  width: 100% !important;
  height: 100% !important;
}
</style>
