<template>
  <div>
    <Transition name="comments">
      <div v-if="open" class="comments-popout">
        <div class="comments-click-out" @click="clickOut">

        </div>
        <div id="comments-popout-scroll" class="comments-list pa-2 pt-0" v-scroll:#comments-popout-scroll="onScroll">
          <div class="minimise-bar-container" @click="clickOut">
            <div class="comments-minimise-bar" @click="clickOut"></div>
          </div>
          <div class="mt-2">
            <slot>
            </slot>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
export default {
  name: 'CommentsPopout',
  props: ['open'],
  components: {
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    clickOut: function () {
      this.$emit('close');
    },
    onScroll: function (e) {
      this.$emit('scroll', e);
    }
  },
  mounted () {
    // document.getElementById('comments-popout-scroll').addEventListener('click', function (e) {
    //   e.stopPropagation();
    //   e.preventDefault();
    // });
  }
}
</script>
<style lang="scss" scoped>
.comments-popout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.comments-click-out {
  height: 200px;
}
.comments-list{
  background-color: #1f1f1f;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.minimise-bar-container {
  height: 12px;
}
.comments-minimise-bar {
  width: 33%;
  height: 4px;
  background-color: grey;
  border-radius: 2px;
  margin-right: 33%;
  margin-left: 33%;
  margin-top: 3px;
  margin-bottom: 6px;
}
</style>
<style>

/* .comments-enter-active {
  transition: all 0.8s ease-out;
} */
/* .comments-enter-active {
  transition: transform 1s ease-in;
}
.comments-leave-active {
  transition: transform 1s ease-out;
} */
/* 
.comments-leave-from,
.comments-enter-to {
  transform: translateY(0vh);
} */
/* 
.comments-enter-from,
.comments-leave-to {
  transform: translateY(100vh);
} */


.comments-enter-active {
  animation: slide-in 0.2s reverse ease;
}
.comments-leave-active {
  animation: slide-in 0.5s ease;
}
@keyframes slide-in {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  }
}

</style>