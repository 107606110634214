<template>
  <div class="max-size">
    <video autoplay ref="video" controls playsinline name="media" class="disrupt-video" v-bind:class="{'audio-player': mediaFormat == 'audio' }">
      <source ref="videoSource" type="video/mp4">
    </video>
    <audio ref="audio" v-show="useAudio" controls class="ios-audio">
      <source ref="audioSource">
    </audio>
  </div>
</template>
<script>
export default {
  name: 'VanillaPlayer',
  props: ['mediaFormat', 'ios', 'audioFile', 'audioOnly'],
  components: {
  },
  data: () => ({
    source: null
  }),
  errorCaptured(err) {
    this.$store.commit('addError', err);
  },
  computed: {
    useAudio () {
      return this.mediaFormat == 'audio' && this.ios;
    },
    player () {
      return this.useAudio ? this.$refs.audio : this.$refs.video;
    }
  },
  watch: {
  },
  methods: {
    isPlaying: function () {
      return !this.player.paused && !this.player.ended;
    },
    // parent functions
    setSource: function (selected, keepProgress, time) {
      let scope = this;

      if (scope.source != selected.signedUrl) {
        scope.source = selected.signedUrl;
        try {

          let timeStamp = scope.player.currentTime;
          if (time) {
            timeStamp = time;
          }

          if (!scope.useAudio) {
            scope.$refs.videoSource.setAttribute('src', scope.source);
          } else {
            scope.$refs.audioSource.setAttribute('src', scope.source);
          }

          scope.player.load();
          scope.player.play().then(function () {
            scope.handleCanplay();
          }).catch(function () {
            scope.handleCanplay();
          });

          if (keepProgress) {
            scope.player.currentTime = timeStamp;
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    requestFullscreen: function () {
      this.refs.video.webkitRequestFullScreen();
    },
    stop: function () {
      // let scope = this;
      // if (scope.video) {
      //   // scope.video.stop();
      // }
    },
    togglePlay: function () {
      if (this.isPlaying()) {
        this.player.pause();
      }
      else {
        this.player.play();
      }
    },
    setVolume: function (val) {
      let scope = this;
      if (scope.player) {
        scope.player.volume = val / 100.0;
      }
    },
    setTimestamp: function (val) {
      let scope = this;
      if (scope.player) {
        scope.player.currentTime = val;
      }
    },
    progressChanged: function (val) {
      let scope = this;
      if (scope.player) {
        let time = (val / 100.0) * scope.player.duration
        scope.player.currentTime = time;
      }
    },
    focusVideo: function () {
      let scope = this;
      if (scope.player) {
        // this.player.focus({ preventScroll: true });
      }
    },
    // events
    handlePause: function () {
      let scope = this;
      scope.playing = !scope.player.paused;
      scope.$emit('playerAction');
      scope.$emit('pause');
    },
    handlePlay: function () {
      let scope = this;
      scope.playing = !scope.player.paused;
      scope.$emit('play');
    },
    handleTimeUpdate: function () {
      let data = {
        current: this.player.currentTime,
        duration: this.player.duration
      }
      this.$emit('timeUpdate', data);
    },
    handleLoadedMetaData: function () {
      this.$emit('loadedMetaData')
    },
    handleVolumeChange: function () {
      let scope = this;

      scope.$emit('volumeChange', scope.player.volume);
    },
    handleEnded: function () {
      this.$emit('ended');
    },
    handleBlur: function () {
      this.$emit('blur');
    },
    handleCanplay: function () {
      this.$emit('canplay');
    },
    createEvents: function () {
      let scope = this;

      if (scope.player) {
        scope.player.addEventListener('pause', scope.handlePause, false);
        scope.player.addEventListener('play', scope.handlePlay, false);
        scope.player.addEventListener('timeupdate', scope.handleTimeUpdate);
        scope.player.addEventListener('loadedmetadata', scope.handleLoadedMetaData);
        scope.player.addEventListener('volumechange', scope.handleVolumeChange, false);
        scope.player.addEventListener('ended', scope.handleEnded, false);
        scope.player.addEventListener('blur', scope.handleBlur);

        scope.player.addEventListener('canplay', scope.handleCanplay);
      }
    }
  },
  beforeDestroy () {
    let scope = this;
    if (scope.player) {
      scope.player.removeEventListener('pause', scope.handlePause, false);
      scope.player.removeEventListener('play', scope.handlePlay, false);
      scope.player.removeEventListener('timeupdate', scope.handleTimeUpdate);
      scope.player.removeEventListener('loadedmetadata', scope.handleLoadedMetaData);
      scope.player.removeEventListener('volumechange', scope.handleVolumeChange, false);
      scope.player.removeEventListener('ended', scope.handleEnded, false);
      scope.player.removeEventListener('blur', scope.handleBlur);
    }
    console.log('vanilla player destroyed')
  },
  mounted () {
    let scope = this;
    scope.createEvents();
  }
}
</script>
<style lang="scss" scoped>
.max-size {
  width: 100% !important;
  height: 100% !important;
}
.disrupt-video {
  width: 100%;
  height: 100%;
  outline: none !important;
}
.disrupt-video::-webkit-media-controls {
	display:none !important;
}

.audio-player {
  display: none;
}

.ios-audio {
  z-index: 1000;
  position: absolute;
  bottom: 10px;
  left: 5%;
  right: 5%;
  width: 90%;
}
</style>
