<template>
  <div v-bind:style="{ 'width': width + 'px', 'height': height + 'px' }" class="player-media-window">
    <!-- <v-responsive v-if="source" :height="height" :aspect-ratio="1" v-bind:style="{ 'width': width, 'height': height }"> -->
    <div class="border-left" v-bind:style="{ 'width': borderWidth + 'px' }"></div>
    <div ref="mediaContainer" class="media-container" v-bind:class="{ 'hide-cursor': !showControls }"
      v-bind:style="{ 'width': mediaWidth + 'px', 'height': mediaHeight + 'px', 'left': mediaLeft, 'top': mediaTop }">
      <!-- video -->
      <mediaPlayer ref="mediaPlayer" 
        :episode="episode" :qualities="availableQualities"
        :hiddenControls="hiddenControls"
        @qualityChanged="qualityChanged"
        @updateView="updateViewData" @canplay="canplay" 
        @fade="fade" @unfade="unfade"
        @playerAction="playerAction"
        @mousemove="mousemove"
        @ended="triggerUpNext"/>
      <!-- <video autoplay ref="video" controls name="media" class="disrupt-video">
        <source ref="source" :src="source" type="video/mp4">
      </video> -->
      
      <!-- autoplay menu -->
      <div class="media-autoplay" v-if="showAutoplay" v-bind:class="{ 'hidden-controls': !showControls, 'ios-hidden': ios }">
        <div class="autoplay-title text-center">
          Up Next:
        </div>
        <div class="autoplay-episode">
          <div class="episode-container pa-6" v-if="nextEpisode">
            <episode :episode="nextEpisode" :height="episodeHeight"/>
          </div>
        </div>
        <v-progress-linear
          :height="36"
          :value="(autoplayTimer / autoplayWait) * 100"
          background-color="black"
          background-opacity="0.4"
        ></v-progress-linear>
      </div>
      <!-- close button -->
      <div class="media-close" v-bind:class="{ 'hidden-controls': !showControls, 'ios-hidden': ios }">
        <v-btn v-if="canMinimise" width="100%" height="100%" max-width="2em" max-height="2em" icon @click="minimise">
          <v-icon size="400%">mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn v-else width="100%" height="100%" max-width="2em" max-height="2em" icon @click="exit">
          <v-icon size="400%">mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
      <!-- video details -->
      <div class="media-details" v-bind:class="{ 'hidden-controls': !showControls, 'ios-hidden': ios }">
        <v-btn v-if="episode && show" class="episode-button" icon :height="32" :width="48" :color="showEpisodes ? 'primary' : 'white'" @click="toggleShowEpisodes">
          <v-img src="/icons/episodes.png" :height="24" :width="36" :aspect-ratio="1" contain />
          <!-- <v-icon x-large></v-icon> -->
        </v-btn>
        <v-btn v-if="canSwitchToAudio" icon class="audio-button" :height="32" :width="48" @click="switchToAudio">
          <v-img src="/icons/audio-icon.png" :height="24" :width="36" :aspect-ratio="1" contain />
          <!-- <v-icon x-large></v-icon> -->
        </v-btn>
        <v-btn v-if="canSwitchToVideo" icon class="video-button" :height="32" :width="48" @click="switchToVideo">
          <v-img src="/icons/video-icon.png" :height="24" :width="36" :aspect-ratio="1" contain />
        </v-btn>
        <v-row>
          <v-col cols="12">
          </v-col>
          <v-col cols="12" >
          </v-col>
          <v-col cols="12" >
          </v-col>
        </v-row>
      </div>

      <!-- episode list -->
      <div id="episodeList" class="episode-list" v-bind:class="{ 'hidden-controls': !showControls || !showEpisodes, 'ios-hidden': ios }">
        <div class="episode-container pa-6" v-bind:class="{ 'current-episode': episode && ep.id == episode.id }" :key="ep.id" v-for="ep in episodes">
          <episode :id="'episode-' + ep.id" :episode="ep" :height="episodeHeight" />
        </div>
        <div class="no-episodes text-center" v-if="episodes.length == 0">
          No Episodes
        </div>
      </div>

      <!-- loading gif -->
      <div class="disrupt-loader" v-if="loader">
        <v-img src="/icons/loop.gif" :aspect-ratio="1" contain height="100%" width="100%" />
      </div>
    </div>
    <div class="border-right" v-bind:style="{ 'width': borderWidth + 'px' }"></div>
  </div>
</template>
<script>
import Vue from 'vue';
import { EventBus } from '../../../eventBus';

import episode from '../../content/episode/episode';

import mediaPlayer from './mediaPlayer.vue'

export default {
  name: 'DisruptPlayer',
  props: ['mini', 'hiddenControls', 'minimised', 'episode', 'qualities', 'type', 'width', 'height', 'show', 'episodes'],
  components: {
    episode,
    mediaPlayer
  },
  data: () => ({
    hideControls: false,
    showEpisodes: false,
    showAutoplay: false,
    video: null,
    mediaFormat: null,
    autoplayTimer: 0,
    autoplayWait: 5000,
    autoplayInterval: null,
    screenHeight: 0,
    screenWidth: 0,
    loader: true,
    selectedQuality: null
  }),
  errorCaptured(err) {
    this.$store.commit('addError', err);
  },
  computed: {
    showControls () {
      return !this.hideControls && !this.hiddenControls;
    },
    availableQualities () {
      return this.qualities;
    },
    ios () {
      return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    },
    aspect () {
      return 1920/1080;
    },
    currentAspect () {
      return this.screenWidth / this.screenHeight;
    },
    mediaHeight() {
      if (this.type == 'video') {
        if (this.currentAspect > this.aspect) {
          return this.screenHeight;    
        } else {
          return this.screenWidth / this.aspect;
        }
      }
      return this.screenHeight;
    },
    mediaWidth () {
      if (this.type == 'video') {
        if (this.currentAspect > this.aspect) {
          return this.screenHeight * this.aspect;
        } else {
          return this.screenWidth;  
        }
      }
      return this.screenWidth;
    },
    borderWidth () {
      return (this.screenWidth - this.mediaWidth) / 2.0;
    },
    episodeHeight () {
      return Math.min(this.mediaHeight * 0.1, 80);
    },
    compactEpisodes () {
      if (this.episodeHeight < 80) {
        return true;
      }
      return false;
    },
    mediaTop () {
      if (!this.minimised) {
        return 'calc((100% - ' + this.mediaHeight + 'px) / 2)';
      }
      return 0;
    },
    mediaLeft () {
      if (!this.minimised) {
        return 'calc((100% - ' + this.mediaWidth + 'px) / 2)';
      }
      return 0;
    },
    nextEpisode () {
      if (this.episode && this.episodes) {
        let seasonNumber = this.episode.seasonNumber;
        let episodeNumber = this.episode.episodeNumber + 1;
        
        let next = this.episodes.find(e => e.seasonNumber == seasonNumber && e.episodeNumber == episodeNumber);
        if (next) {
          return next;
        }

        seasonNumber = seasonNumber + 1;
        episodeNumber = 1;
        return this.episodes.find(e => e.seasonNumber == seasonNumber && e.episodeNumber == episodeNumber);
      }
      return null;
    },
    currentQuality () {
      return this.selectedQuality;
    },
    videoQualities () {
      if (this.qualities) {
        return this.qualities.filter(q => q.format == "mp4");
      }
      return []
    },
    canSwitchToVideo () {
      if (this.currentQuality) {
        if (this.currentQuality.quality.toLowerCase() == 'audio') {
          if (this.videoQualities.length > 0) {
            return true;
          }
        }
      }
      return false;
    },
    canSwitchToAudio () {
      if (this.currentQuality) {
        if (this.currentQuality.quality != 'audio') {
          if (this.qualities.find(q => q.quality == 'audio')) {
            return true;  
          }
        }
      }
      return false;
    },
    isMobile () {
      return this.$store.getters.getIsMobile;
    },
    isEpisodePage () {
      return this.$route.name == 'Episode';
    },
    canMinimise () {
      if (this.isMobile && this.isEpisodePage) {
        return true;
      }
      return false;
    }
  },
  watch: {
    volumeOpen: function (val) {
      let scope = this;
      if (val) {
        Vue.nextTick(function () {
          let volumeElement = document.getElementsByClassName('volume-menu')[0]
          volumeElement.addEventListener('mouseleave', scope.handleMouseLeave);
        });
      } else {
        let volumeElement = document.getElementsByClassName('volume-menu')[0]
        volumeElement.removeEventListener('mouseleave', scope.handleMouseLeave);
      }
    },
    episode: function (val) {
      let scope = this;
        
      scope.loader = true;
      scope.autoplayTimer = 0;
      scope.showAutoplay = false;
      if (val) {
        scope.scrollEpisodeList();
      }
    },
    width: function () {
      this.checkWindowSize();
    },
    height: function () {
      this.checkWindowSize();
    }
  },
  methods: {
    qualityChanged: function (quality) {
      this.selectedQuality = quality;
    },
    togglePlay: function () {
      this.$refs.mediaPlayer.togglePlay();
    },
    changeQuality: function (quality) {
      this.$refs.mediaPlayer.changeQuality(quality);
    },
    toggleShowEpisodes: function () {
      let scope = this;
      scope.showEpisodes = !scope.showEpisodes;

      if (scope.showEpisodes) {
        scope.scrollEpisodeList();
      }
    },
    scrollEpisodeList: function () {
      let scope = this;
      Vue.nextTick(function () {
        let elementId = 'episode-' + scope.episode.id;

        let episodeList = document.getElementsByClassName('episode-list')[0];
        let episodeElement = document.getElementById(elementId);

        if (episodeList && episodeElement) {
          episodeList.scrollTo(0, episodeElement.offsetTop);
        }
      });
    },
    switchToAudio: function () {
      let audioQuality = this.qualities.find(q => q.quality == 'audio')
      if (audioQuality) {
        this.changeQuality(audioQuality)
      }
    },
    switchToVideo: function () {
      let videoQualities = this.qualities.filter(q => q.format == 'mp4' && q.quality.toLowerCase() != 'audio' && q.quality != 'source')
      if (videoQualities.length > 0) {
        let videoQuality = videoQualities[videoQualities.length - 1]
        this.changeQuality(videoQuality);
      }
    },
    checkWindowSize: function () {
      if (this.mini) {
        this.screenHeight = this.height;
        this.screenWidth = this.width;
      } else {
        this.screenHeight = document.body.clientHeight;
        this.screenWidth = document.body.clientWidth;
      }
    },
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    exit: function () {
      this.navigate('/');
    },
    minimise: function () {
      EventBus.$emit('minimisePlayer');
      this.navigate('/');
    },
    autoplayChecker: function () {
      let scope = this;

      scope.autoplayTimer += 100;

      if (scope.autoplayTimer >= scope.autoplayWait) {
        // go to the next episode
        clearInterval(scope.autoplayInterval);
        
        scope.autoplayTimer = 0;
        scope.showAutoplay = false;
        scope.navigateToEpisode(scope.nextEpisode);
      }
    },
    navigateToEpisode: function (episode) {
      let scope = this;
      if(scope.$route.name == 'Watch') {
        scope.navigate('/watch/' + episode.id);
      } else if (scope.$route.name == 'Episode') {
        scope.navigate('/episode/' + episode.id);
      }
    },
    mousemove: function () {
      let scope = this;
      clearInterval(scope.autoplayInterval);
    },
    playerAction: function () {
      let scope = this;
      
      scope.showAutoplay = false;
      scope.autoplayTimer = 0;
      clearInterval(scope.autoplayInterval);
    },
    triggerUpNext: function () {
      if (this.nextEpisode) {
        this.showAutoplay = true;
        this.autoplayTimer = 0;
        this.autoplayInterval = setInterval(this.autoplayChecker, 100);
      }
    },
    updateViewData: function (val) {
      this.$emit('updateView', val);
    },
    canplay: function () {
      this.loader = false;
    },
    setTimestamp: function (newTimestamp) {
      this.$refs["mediaPlayer"].setTimestamp(newTimestamp);
    },
    fade: function () {
      this.hideControls = true;
    },
    unfade: function () {
      this.hideControls = false;
    },
    handleMouseScroll: function () {
      let scope = this;
      scope.$refs.mediaPlayer.playerAction(false);
    },
    createEvents: function () {
      let scope = this;
      let episodeList = document.getElementById('episodeList');
      if (episodeList) {
        episodeList.addEventListener('wheel', scope.handleMouseScroll);
      }

      EventBus.$on('togglePlay', function () {
        scope.togglePlay();
      })
    }
  },
  created() {
    window.addEventListener("resize", this.checkWindowSize);
  },
  destroyed() {
    let scope = this;
    window.removeEventListener("resize", this.checkWindowSize);

    let episodeList = document.getElementById('episodeList');
    if (episodeList) {
      episodeList.removeEventListener('wheel', scope.handleMouseScroll);
    }
  },
  mounted () {
    let scope = this;

    scope.createEvents();
    scope.checkWindowSize();

    if (scope.episode) {
      scope.scrollEpisodeList();
    }
  }
}
</script>
<style lang="scss" scoped>
.player-media-window {
  position: relative;
  overflow: hidden !important;
}
.media-container {
  position: absolute;
  margin: 0 auto;
}

.disrupt-video {
  width: 100%;
  height: 100%;
  outline: none !important;
}
.disrupt-video::-webkit-media-controls {
	display:none !important;
}
.media-close {
  position:absolute;
  top: 4px;
  height: 10%;
  left: 4px;
  width: 10%;
  z-index: 2147483647;
  margin: 16px;
}
.media-details {
  position:absolute;
  top: 0;
  height: 100px;
  max-height: 20%;
  right: 0;
  max-width: 64px;
  z-index: 2147483647;
  padding: 8px 0px;
}
.media-details-list {
  background-color: initial;
}

.media-autoplay {
  position: absolute;
  top: 25%;
	bottom: 25%;
  left: 25%;
  right: 25%;
  z-index: 2147483647;
  background: none !important;
}


.episode-name {
  font-size: 100%;
}
.episode-subtitle {
  font-size: 80%;
}

.episode-list {
  position:absolute;
  top: calc(min(30%, 100px));
	bottom: 36px;
  width: 500px;
  max-width: 50%;
  right: 0;
  z-index: 2147483647;
  background: #1e1e1e !important;
  overflow-y: scroll;
}
.episode-container {
  padding: 5% !important;
  background: #141414;
}
.episode-container:nth-child(2n) {
  background: #0d0d0d;
}

.current-episode {
  background: #333333 !important;
  border: 4px solid var(--v-primary-base);
  padding: 5% calc(5% - 4px) !important;
}

.autoplay-episode {
  height: calc(100% - 36px - 48px);
  overflow: hidden;
}

.autoplay-episode .episode-container {
  height: 100%;
}

.autoplay-title  {
  font-size: 48px;
}

.audio-video-switch {
  position: absolute;
  z-index: 2147483647;
  right: 12px;
  top: calc(50vh);
}

.switch-item {
  height: 64px;
}

.switch-icon-text {
  display: inline-block;
  background-color: var(--v-secondary-darken1);;
  font-size: 1.2em;
}
.switch-icon-background {
  display: inline-block;
  width: 64px;
  height: 64px;
  text-align: center;
  background-color: var(--v-primary-base);
}

.ios-hidden {
  display: none !important;
}

.hidden-controls {
  display: none;
}

.border-left,
.border-right {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  background-color: black;
  z-index: 10;
}

.border-left {
  left: 0;
}
.border-right {
  right: 0;
}

.episode-button,
.audio-button,
.video-button {
  position: absolute;
  right: 8px;
}

.episode-button {
  top: 8px;
}
.audio-button,
.video-button {
  top: 56px;
}
</style>