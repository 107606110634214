<template>
  <v-dialog v-model="dialog" width="800" content-class="edit-user-dialog">
    <v-card v-if="userData">
      <v-card-title>
        Edit User
      </v-card-title>
      <v-card-text>
        <imageUploader ref="userImage" :width="200" :height="200" v-model="userData.imageId" :disabled="saving" :url="userData.image" placeholder="/icons/user.png"/>

        <serverValidationField ref="usernameField" v-model="userData.username" label="Username" fieldClass="edit-user-fields" :height="40" :disabled="saving" :acceptOriginalValue="true"
          endpoint="Auth/usernameAvailable" :dataFunction="usernameDataFunction" :responseFunction="usernameResponseFunction" :messages="usernameFailReason"/>
        
        <serverValidationField ref="emailField" v-model="userData.email" label="Email" fieldClass="edit-user-fields" :height="40" :disabled="saving" :acceptOriginalValue="true"
          :resolveFunction="validateEmail" :messages="emailFailReason" :inputDelay="10"/>

        <v-select :items="roleItems" item-text="name" class="edit-user-fields" v-model="userData.role" :disabled="saving" flat solo>
        </v-select>

        <v-switch v-model="userData.chatBanned" label="Chat Banned">
        </v-switch>


        <v-card v-if="activeSubscription">
          <v-card-title>
            Subscription
            <v-btn @click="checkStatus" v-if="isDev">Check Status</v-btn>
          </v-card-title>
          <v-card-text v-if="activeSubscription.premium">
            <v-layout row wrap class="px-4">
              <v-flex xs4>
                Premium
              </v-flex>
              <v-flex xs4>
                {{ activeSubscription.planType == 'month' ? '1 ' : '' }}{{ activeSubscription.planType }}
              </v-flex>
              <v-flex xs4>
                {{ activeSubscription.paymentType }}
              </v-flex>
            </v-layout>
            <v-layout row wrap class="px-4 mt-4">
              <v-flex xs6>
                {{ moment(activeSubscription.createdAt).format('YYYY MMM Do') }}
                to
                {{ activeSubscription.expires ? moment(activeSubscription.expires).format('YYYY MMM Do') : '-'}}
              </v-flex>
              <v-flex xs6>
                Status: {{ activeSubscription.status }}
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text v-else>
            <v-layout row wrapclass="px-4">
              <v-flex xs12>
                Free
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-btn tile class="cancel-button" @click="cancel()" :disabled="saving">
          Cancel
        </v-btn>
        <v-spacer/>
        <v-btn tile class="login-button" @click="saveUser()" :disabled="saving" color="primary">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { EventBus } from '../../../eventBus.js';
import { Api } from '../../../js/api';
import moment from 'moment'

import imageUploader from '../../global/imageUploader'
import serverValidationField from '../../global/serverValidationField'
export default {
  name: 'EditUserDialog',
  components: {
    imageUploader,
    serverValidationField
  },
  data: () => ({
    dialog: false,
    saving: false,
    usernameFailReason: null,
    emailFailReason: null,
    userData: {
      username: '',
      password: '',
      email: '',
      role: 'User',
      chatBanned: false
    }
  }),
  watch: {
    dialog: function(val) {
      if (!val) {
        this.userData = null;
      }
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters.getUser;
    },
    isDev () {
      return this.$store.getters.hasKey('Dev');
    },
    availableRoles () {
      let allRoles = ['SuperAdmin', 'Admin', 'Moderator', 'Investor', 'User'];

      let currentRole = this.currentUser.role;
      let currentIndex = allRoles.indexOf(currentRole);

      return allRoles.slice(currentIndex+1);
    },
    roleItems () {
      let items = []
      this.availableRoles.forEach(a => {
        items.push({
          name: a
        })
      })
      return items
    },
    activeSubscription () {
      if (this.userData && this.userData.subscriptions && this.userData.subscriptions.length > 0) {
        return this.userData.subscriptions[0];
      }
      return null;
    },
    moment() {
      return moment;
    }
  },
  methods: {
    checkStatus: function () {
      Api.get('AdminSubscription/getStatus/' + this.userData.id);
    },
    usernameDataFunction: function (username) {
      let data = {
        username: username
      }
      return data;
    },
    usernameResponseFunction: function (res) {
      if (res.data === true) {
        this.usernameFailReason = '';
      } else {
        this.usernameFailReason = res.data;
      }
      return res.valid;
    },
    validateEmail: function (email) {
      return new Promise((resolve) => {
        const validation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email.toLowerCase().match(validation)) {
          this.emailFailReason = 'Incorrect email format';
          resolve(false);
          return;
        }

        this.emailFailReason = null;
        resolve(true);
      })
    },
    cancel: function () {
      this.dialog = false;
    },
    saveUser: function () {
      let self = this;

      if (!this.$refs.usernameField.checkValid) {
        return;
      }
      if (!this.$refs.emailField.checkValid) {
        return;
      }

      if (self.$refs.userImage.file) {
        // upload the file first
        self.$refs.userImage.upload().then(function () {
          self.save();
        })
      } else {
        self.save();
      }
    },
    save: function () {
      let scope = this;
      let data = this.userData;

      scope.saving = true;
      Api.post('Admin/updateUser', data).then(function (res) {
        scope.dialog = false;
        scope.saving = false;

        if (res.valid) {
          EventBus.$emit('alert', {
            text: 'User updated',
            alertColor: 'green',
            alertIcon: 'success'
          })
        }

        scope.$emit('complete');
      })
    }
  },
  mounted () { let scope = this
    EventBus.$on('editUserDialog', function (user) {
      let currentUser = Object.assign({}, user);

      scope.userData = currentUser;
      scope.dialog = true;
    })
  }
}
</script>
<style lang="scss">
.edit-user-dialog,
.edit-user-dialog .v-card {
  background-color: #202020 !important;
  border-radius: 0px !important;
}
.edit-user-fields .v-input__slot{
  background-color: #282828 !important;
  border-radius: 0px !important;
}
.edit-user-fields .v-label {
  color: white !important;
  padding-left: 0px; 
}
</style>