<template>
  <v-dialog v-model="notificationDialog" persistent width="600">
    <v-card class="pa-0 pt-4" v-if="notificationSetting">
      <v-btn class="close-button" icon tile @click="close"><v-icon>mdi-close</v-icon></v-btn>
      <v-card-text class="pa-0 pt-4">
        <v-container class="pa-0 pt-4">
          <v-layout>
            <v-flex xs12 class="text-center">
              <div class="notification-bell-container">
                <v-icon size="128" class="notification-bell">mdi-bell</v-icon>
                <v-icon size="32" class="notification-circle" color="#f30001">mdi-circle</v-icon>
              </div>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 class="notification-text mb-4 py-4">
            Recieve <span class="notification-red">1 notification</span><br>
            when new content is released
            </v-flex>
          </v-layout>
          <v-layout class="notification-option">
            <v-flex xs1>
              <v-icon size="32">mdi-email</v-icon>
            </v-flex>
            <v-flex xs9 class="pt-4">
              Email
            </v-flex>
            <v-flex xs2>
              <v-switch :disabled="disabled" v-model="notificationSetting.email" @change="inputChanged" class="thick-switch"></v-switch>
            </v-flex>
          </v-layout>

          <v-layout class="notification-option">
            <v-flex xs1>
              <v-icon size="32">mdi-cellphone</v-icon>
            </v-flex>
            <v-flex xs9 class="pt-4">
              Text
              <v-text-field :disabled="disabled || notificationSetting.sms" v-model="number" @change="phoneNumberChanged" solo label="000-000-0000" :max-width="200" height="32" class="phone-number-field ml-2">
              </v-text-field>
              <div class="flag-container">
                (<v-img :src="url+'icons/US_Flag.png'" height="16px" width="28px" contain class="us-icon"/>Only)
              </div>
            </v-flex>
            <v-flex xs2>
              <v-switch :disabled="disabled" v-model="notificationSetting.sms" @change="smsChanged" class="thick-switch"></v-switch>
            </v-flex>
          </v-layout>

          <v-layout class="notification-option">
            <v-flex xs1>
              <v-icon size="32">mdi-tab</v-icon>
            </v-flex>
            <v-flex xs9 class="pt-4">
              Browser Notification
            </v-flex>
            <v-flex xs2>
              <v-switch :disabled="disabled" v-model="notificationSetting.web" @change="webNotificationsChanged" class="thick-switch"></v-switch>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import Vue from 'vue';
import webNotifications from '../../js/webNotifications.js'

export default {
  name: 'blank',
  props: ['dialog'],
  components: {
  },
  data: () => ({
    notificationDialog: false,
    notificationSetting: null,
    number: null,
    saving: false,
    loading: false
  }),
  computed: {
    disabled () {
      return this.saving || this.loading;
    },
    url () {
      return process.env.VUE_APP_CLIENT_PROTOCOL + process.env.VUE_APP_CLIENT_URL;
    },
  },
  watch: {
    dialog: function (val) {
      this.notificationDialog = val;
    }
  },
  methods: {
    close: function () {
      this.$emit('close');
    },
    getNotificationSettings: function () {
      let self = this;
      self.loading = true;
      self.$store.dispatch('getNotificationSettings').then(function (notificationSetting) {
        if (notificationSetting.number != null && notificationSetting.number != "") {
          self.number = "*******" + notificationSetting.number;
        }
        notificationSetting.number = null;
        self.notificationSetting = notificationSetting;
        self.loading = false;
      })
    },
    saveNotificationSettings: function () {
      let self = this;
      
      self.saving = true;

      let data = {}
      Object.assign(data, self.notificationSetting);

      if (self.number == null || self.number.includes('*')) {
        data.number = null;
      } else if (self.number == "") {
        data.number = "";
      } else {
        data.number = self.number;
      }

      self.$store.dispatch('saveNotificationSettings', data).then(function () {
        self.saving = false;
      })
    },
    phoneNumberChanged: function () {
    },
    smsChanged: function (val) {
      let self = this;
      if (val) {
        let validated = self.validatePhoneNumber(self.number)
        if (validated) {
          self.number = validated;
          self.saveNotificationSettings();
        } else {
          setTimeout(function () {
            Vue.nextTick(function () {
              self.notificationSetting.sms = false;
            })
          }, 200)
        }
      } else {
        self.number = "";
        self.saveNotificationSettings();
      }
    },
    validatePhoneNumber: function (number) {
      let trimmed = number.trim();
      let cleaned = trimmed.replace(/[^0-9]/g, '');
      if (cleaned.length > 6 && cleaned.length < 15) {
        return cleaned;
      }
      return false;
    },
    inputChanged: function () {
      this.saveNotificationSettings();
    },
    webNotificationsChanged: function (val) {
      let self = this;
      if (val) {
        webNotifications.promptForNotifications().then(function () {
          self.saveNotificationSettings();
        })
      } else {
        webNotifications.cancelNotifications().then(function () {
          self.saveNotificationSettings();
        })
      }
    }
  },
  mounted () {
    this.getNotificationSettings();
  }
}
</script>
<style lang="scss" scoped>
.notification-text {
  text-align: center;
  font-size: 32px;
  color: white;
  line-height: 1.1em;
}

.notification-option {
  font-size: 24px;
  color: white;
  padding: 0px 8px;
  padding-left: 32px;
}


.notification-option:nth-child(2n) {
  background-color:#0000002b;
}

.notification-option .flex {
  padding: 12px 0px;
  height: 64px;
}

.notification-option .flex .v-input--switch{
  margin: 0;
}

.phone-number-field {
  display: inline-block;
}

.notification-red {
  color: #f30001;
}

.notification-bell-container {
  height: 128px;
  position: relative;
}
.notification-bell {
  font-size: 128px;
  position: absolute;
  left: calc(50% - 64px);
}
.notification-circle {
  position: absolute;
  right: calc(50% - 48px);
  top: 24px;
}
.close-button {
  position: absolute;
  background-color: black;
  right: 0;
  top: 0;
}

.flag-container {
  position: absolute;
  height: 28px;
  font-size: 22px;
  display: inline-block;
  margin-top: 4px;
}
.us-icon {
  display: inline-block;
}

</style>
<style lang="scss">
  .phone-number-field .v-input__slot {
    min-height: 0 !important;
    background: #474747 !important;
    border-radius: 205px !important;
    width: 180px !important;
  }

  .thick-switch .v-input--switch__track{
    height: 24px;
    border-radius: 12px;
    top: calc(50% - 12px);
    width: 48px;
  }

  .thick-switch .v-input--switch__thumb {
    height: 28px;
    width: 28px;
    top: calc(50% - 14px);
  }

  .thick-switch .v-input--selection-controls__ripple {
    left: -10px !important;
  }

  .thick-switch.v-input--is-label-active .v-input--switch__thumb,
  .thick-switch.v-input--is-label-active .v-input--selection-controls__ripple {
    transform: translate(28px, 0) !important;
  }
</style>
