<template>
  <v-dialog v-model="dialog" width="600" content-class="shareholder-dialog">
    <v-card>
      <v-card-title>
        Edit Shareholder
      </v-card-title>
      <v-card-text class="shareholder-dialog-body">
        <v-text-field solo flat :value="user.username" label="Username" background-color="#282828" class="shareholders-user-field"></v-text-field>
        <v-text-field solo flat :value="user.email" label="Email" background-color="#282828" class="shareholders-user-field"></v-text-field>
        <div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Year
                  </th>
                  <th class="text-left">
                    Shares
                  </th>
                  <th class="text-left">
                    Dividends
                  </th>
                  <th class="text-left">
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="financeReport in shareData"
                  :key="financeReport.id"
                >
                  <td>{{ financeReport.year }}</td>
                  <td>
                    <v-text-field type="number" v-model="financeReport.shares" :disabled="financeReport.disabled"></v-text-field>
                  </td>
                  <td>
                    ${{ financeReport.dirty ? calculateDividend(financeReport).toFixed(2) : financeReport.dividend }}
                  </td>
                  <td>
                    <v-btn v-show="financeReport.disabled" tile color="primary" @click="financeReport.disabled = !financeReport.disabled">
                      {{ financeReport.disabled ? 'Edit' : 'Confirm' }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-btn tile class="cancel-button" @click="cancel()">
          Cancel
        </v-btn>
        <v-spacer/>
        <v-btn tile class="delete-button" @click="save()" color="primary">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { Api } from '../../../js/api';
import { EventBus } from '../../../eventBus.js';

export default {
  name: 'ShareholderDialog',
  props: ['financeReports'],
  components: {
  },
  data: () => ({
    dialog: false,
    saving: false,
    user: null,
    shareData: []
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    getShares: function (report) {
      let data = this.user.investorDatas.find(d => d.financeReportId == report.id)
      if (data) {
        return data.shares;
      }
      return '-';
    },
    getDividends: function (report) {
      let data = this.user.investorDatas.find(d => d.financeReportId == report.id);
      if (data) {
        return data.dividend;
      }
      return '-';
    },
    createShareData: function () {
      let scope = this
      scope.shareData = []
      scope.financeReports.forEach(report => {
        let sd = {
          id: report.id,
          year: report.year,
          shares: 0,
          dividend: 0,
          dirty: false,
          disabled: true
        }

        let data = this.user.investorDatas.find(d => d.financeReportId == report.id);
        if (data) {
          sd.shares = data.shares;
          sd.dividend = data.dividend
        }

        scope.shareData.push(sd)
      });
    },
    cancel: function () {
      this.dialog = false;
    },
    save: function () {
      let scope = this;

      let data = {
        userId: scope.user.id,
        shares: []
      };

      scope.shareData.forEach(sd => {
        data.shares.push({
          financeReportId: sd.id,
          shares: sd.shares
        })
      })

      scope.saving = true;
      Api.post('AdminInvestorUser/updateUserShares', data).then(function () {
        scope.saving = false;
        scope.dialog = false;
      })
    }
  },
  mounted () {
    let scope = this
    EventBus.$on('editUserSharesDialog', function (user) {
      let currentUser = Object.assign({}, user);

      scope.user = currentUser;

      scope.createShareData();
      scope.dialog = true;
    })
  }
}
</script>
<style lang="scss" scoped>
.shareholders-user-field {
  border-radius: 0 !important;
}
</style>
