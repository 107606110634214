<template>
 <v-bottom-navigation app mandatory background-color="#181818" color="primary" class="mobile-navigation" v-model="currentRoute">
    <v-btn class="mobile-button" value="Content" @click="navigate('/')">
      <!-- <span>Content</span> -->
      <v-icon>mdi-home</v-icon>
    </v-btn>
    <v-btn class="mobile-button" value="Livestream" @click="navigate('/livestream')">
      <!-- <span>Livestream</span> -->
      <v-icon>mdi-access-point</v-icon>
    </v-btn>
    <v-btn class="mobile-button" value="Rewards" @click="navigate('/rewards')">
      <!-- <span>Rewards</span> -->
      <v-icon>mdi-gift</v-icon>
    </v-btn>
    <v-btn v-if="isLoggedIn" class="mobile-button" value="Account" @click="navigate('/account')">
      <!-- <span>Account</span> -->
      <v-avatar size="24">
        <img :src="profileImage">
      </v-avatar>
      <!-- <v-icon>mdi-account</v-icon> -->
    </v-btn>
    <v-btn v-else class="mobile-button" value="Login" @click="navigate('/login')">
      <span>Login</span>
      <v-icon>mdi-login</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {
  name: 'MobileNavigation',
  props: [],
  components: {
  },
  data: () => ({
  }),
  computed: {
    currentRoute () {
      return this.$router.currentRoute.name;
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
    user () {
      return this.$store.getters.getUser;
    },
    profileImage () {
      if (this.user) {
        if (this.user.image) {
          return this.user.image;
        }
      }
      return "/icons/user.png";
    },
  },
  watch: {
  },
  methods: {
    navigate: function (path) {
      this.$router.push(path);
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.mobile-button {
  width: 140px !important;
}
.mobile-navigation {
  z-index: 300 !important;
}
</style>
