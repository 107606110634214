<template>
  <v-dialog content-class="show-dialog" v-model="dialog" @click:outside="setShowDialog(false)" :transition="dialog ? 'dialog-transition' : ''" width="800" overlay-opacity="0.6" overlay-color="black">
    <div class="show-container-dialog" v-if="show">
      <showBanner :show="show" @close="setShowDialog(false)" @startWatching="startWatching"/>
      <div class="season-list px-6 py-4">
        <seasonSwitcher v-if="season && seasonCount > 1" :seasons="seasons" v-model="season"/>
      </div>
      <div class="episode-list pa-6" v-if="loadingEpisodes">
        <v-row align="center" class="text-center">
          <v-col>
            <v-progress-circular :size="40" :width="2" color="white" class="site-loader" indeterminate></v-progress-circular>
          </v-col>
        </v-row>
      </div>
      <div class="episode-list px-6" v-else>
        <div class="episode-container" :key ="episode.id" v-for="episode in filteredEpisodes">
          <episode :episode="episode" :height="episodeHeight" :hideText="true" :showDetails="true"/>
        </div>
        <div class="no-episodes text-center" v-if="filteredEpisodes.length == 0">
          No Episodes
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
  import { EventBus } from './../../../eventBus.js';
  
  import showBanner from '../../global/showBanner';
  import seasonSwitcher from '../../global/seasonSwitcher';
  import episode from '../episode/episode';

  export default {
    name: 'ShowDialog',
    components: {
      showBanner,
      seasonSwitcher,
      episode,
    },
    data: () => ({
      // dialog: false,
      show: null,
      episodes: [],
      loadingEpisodes: true,
      season: null
    }),
    watch: {
      dialog: function (val) {
        if (!val) {
          if (this.$router.currentRoute.name == 'Show') {
            this.$router.replace('/');
            this.resetScroll();
          }
        }
      },
      seasons: function () {
        if (this.seasons.length > 0) {
          this.season = this.seasons[0].n;
        }
      }
    },
    computed: {
      dialog () {
        return this.$store.getters.getShowDialog;
      },
      mobile () {
        return this.isMobile;
        // return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
      },
      isMobile() {
        return this.$store.getters.getIsMobile;
      },
      episodeHeight () {
        return this.mobile ? 110 : 100;
      },
      seasonCount () {
        if (this.show && this.episodes) {
          return Math.max(...this.episodes.map(e => e.seasonNumber));
        }
        return 0;
      },
      seasons () {
        let s = []
        for(let i = 1; i <= this.seasonCount; i++) {
          let sn = i;
          if (this.show.descending) {
            sn = this.seasonCount - (i - 1);
          }

          if (this.episodes.find(e => e.seasonNumber == sn) != null) {
            s.push({
              n: sn,
              name: "Season " + sn
            });
          }
        }
        return s
      },
      filteredEpisodes () {
        if (this.show) {
          if (this.show.descending) {
            return this.episodes.filter(e => e.seasonNumber == this.season).sort(function (a, b) {
              return b.episodeNumber - a.episodeNumber;
            });
          } else {
            return this.episodes.filter(e => e.seasonNumber == this.season).sort(function (a, b) {
              return a.episodeNumber - b.episodeNumber;
            });
          }
        }
        return []
      }
    },
    methods: {
      setShowDialog: function (val) {
        this.$store.commit('setShowDialog', val);
      },
      getEpisodes: function (show) {
        let scope = this;
        return new Promise((resolve) => {
          scope.$store.dispatch('getEpisodes', show.id).then(function (episodes) {
            scope.episodes = episodes;
            scope.season = 1;
            
            scope.loadingEpisodes = false;

            resolve()
          })
        })
      },
      navigate: function (path) {
        this.$router.push(path).catch(()=>{});
      },
      startWatching: function () {
        let episodeToWatch = null;
        if (this.filteredEpisodes.length > 0) {
          episodeToWatch = this.filteredEpisodes[0];
        } else if(this.episodes.length > 0) {
          episodeToWatch = this.episodes[0];
        }

        if (episodeToWatch) {
          this.navigate('/watch/' + episodeToWatch.id);
        }
      },
      resetScroll: function () {
        let showDialogElement = document.getElementsByClassName('show-dialog')
        if (showDialogElement.length > 0) {
          showDialogElement[0].scrollTop = 0;
        }
      }
    },
    mounted () {
      let scope = this
      EventBus.$on('viewShow', function (show) {
        scope.resetScroll();

        scope.setShowDialog(true);
        scope.show = show;

        scope.$router.replace('/show/' + show.id);
        scope.loadingEpisodes = true;
        scope.getEpisodes(show);
      })
      EventBus.$on('closeShowDialog', function () {
        scope.setShowDialog(false);
      })
    }
  }
</script>
<style lang="scss" scoped>

.show-container-dialog {
  min-height: 100%;
  background-color: #202020 !important;
}
.no-episodes {
  height: 64px;
}

</style>
<style lang="scss">

// .show-dialog {
//   overflow-y: hidden;
// }
// .show-dialog .episodes-loaded {
//   overflow-y: auto;
// }



.disrupt-mobile .show-dialog {
  width: 100% !important;
  // height: calc(100% - 54px) !important;
  height: 100% !important;
  margin: 0px !important;
  max-height: inherit !important;
  // margin-bottom: 54px !important;
}
.season-selector .v-input__control {
  min-height: auto !important;
}
</style>
