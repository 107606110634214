<template>
  <v-container fill-height justify-center align-start>
    <v-row>
      <v-col class="text-center">
        <h1>
        Notifications
        </h1>
      </v-col>
    </v-row>
    <v-row justify-center>
      <v-col></v-col>
      <v-col>
        <v-card width="800" class="pa-4">
          <v-row>
            <v-col>
              <span>Title</span>
              <v-text-field solo v-model="title" :disabled="disabled" class="notification-field" background-color="#363636" :persistent-palceholder="true" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span>Message</span>
              <v-textarea solo v-model="message" :disabled="disabled" class="notification-field" background-color="#363636" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span>Link</span>
              <v-text-field solo v-model="link" :disabled="disabled" class="notification-field" background-color="#363636" />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-bind:style="{ 'max-width': '256px'}">
              <span>Image</span>
              <imageUploader ref="bannerImage" class="notification-background-color" v-model="bannerImageId" :url="bannerImageUrl" :disabled="disabled" placeholder="/icons/upload-image.png" @change="updateBannerImage" :width="177" :height="100" :aspect="16/9"/>
            </v-col>
            <v-col v-bind:style="{ 'max-width': '164px'}">
              <span>Icon</span>
              <imageUploader ref="iconImage" class="notification-background-color" v-model="iconImageId" :url="iconImageUrl" :disabled="disabled" placeholder="/icons/upload-image.png" @change="updateIconImage" :width="100" :height="100" :aspect="1"/>
            </v-col>
            <v-col>
            </v-col>
            <v-col>
              <v-checkbox label="Web" v-model="web" :disabled="disabled" />
              <v-checkbox label="SMS" v-model="sms" class="mt-0" :disabled="disabled" />
              <v-checkbox label="Email" v-model="email" class="mt-0" :disabled="disabled" />
            </v-col>
            <v-col>
              <v-btn tile color="primary" @click="attemptSave" :disabled="disabled" class="notification-send-button d-inline-block pr-4">
                Send
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-row>
    <v-spacer />
    </v-row>
  </v-container>
</template>
<script>
import imageUploader from '../../global/imageUploader';

import { EventBus } from '../../../eventBus';
import { Api } from '../../../js/api';

export default {
  name: 'Notifications',
  props: [],
  components: {
    imageUploader
  },
  data: () => ({
    title: '',
    message: '',
    link: '',
    bannerImageId: null,
    bannerImageUrl: '',
    iconImageId: null,
    iconImageUrl: '',
    web: false,
    sms: false,
    email: false,
    saving: false,
    loading: false
  }),
  computed: {
    disabled () {
      return this.saving || this.loading;
    }
  },
  watch: {
  },
  methods: {
    updateBannerImage: function (image) {
      this.bannerImageUrl = image;
    },
    updateIconImage: function (image) {
      this.iconImageUrl = image;
    },
    attemptSave: function () {
      let self = this;
      if (self.$refs.bannerImage.file) {
        self.$refs.bannerImage.upload().then(function () {
          self.attemptSave();
        })
      } else if(self.$refs.iconImage.file){
        self.$refs.iconImage.upload().then(function () {
          self.attemptSave();
        })
      } else {
        self.createNotification();
      }
    },
    getLastNotification: function () {
      let self = this;
      self.loading = true;
      
      Api.get('AdminNotification/last', null).then(function (res) {
        if (res.valid && res.data) {
          self.bannerImageId = res.data.bannerImageId;
          self.bannerImageUrl = res.data.bannerImageUrl;
          self.iconImageId = res.data.iconImageId;
          self.iconImageUrl = res.data.iconImageUrl;
        }
        self.loading = false;
      })
    },
    createNotification: function () {
      let self = this;

      let data = {
        title: this.title,
        message: this.message,
        link: this.link,
        bannerImageId: this.bannerImageId,
        bannerImageUrl: '',
        iconImageId: this.iconImageId,
        iconImageUrl: '',
        web: this.web,
        sms: this.sms,
        email: this.email
      }

      self.saving = true;
      Api.post('AdminNotification/create', data).then(function (res) {
        if (res.valid) {
          self.saving = false;

          EventBus.$emit('alert', {
            text: 'Notification sent',
            alertColor: 'green',
            alertIcon: 'success',
            duration: 10000
          })
        } else {
          self.saving = false;

          EventBus.$emit('alert', {
            text: 'Failed to send notification',
            alertColor: '#a70505',
            duration: 10000
          })
        }
      })
    }
  },
  mounted () {
    this.getLastNotification();
  }
}
</script>
<style lang="scss" scoped>
.notification-field {
  border-radius: 0px !important;
}

.notification-field .v-input__slot {
  box-shadow: none !important;
}

.notification-background-color {
  background-color: #363636;
}

.notification-send-button {
  position: absolute;
  right: 16px;
  bottom: 16px;
}
</style>
<style lang="scss">
.notification-field .v-input__slot {
  box-shadow: none !important;
}
</style>