<template>
  <v-card class="" color="#363636" tile flat>
    <v-card-title>
      Daily Total Users
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div id="user-graph"></div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import c3 from 'c3';

export default {
  name: 'UserGraph',
  props: ['data', 'width', 'height'],
  components: {
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    loadChart: function () {
      c3.generate({
        bindto: '#user-graph',
        data: {
          columns: this.data,
          type : 'bar',
          groups: [ ['paypal', 'stripe', 'coupon', 'free' ] ],
          xFormat: '%Y-%m-%dT%H:%M:%S',
          x: 'x',
          colors: {
            paypal: '#00457C',
            stripe: '#635bff',
            coupon: '#AA0000',
            free: '#b1b1b155'
          },
          onclick: function (d, i) { console.log("onclick", d, i); },
          onmouseover: function (d, i) { console.log("onmouseover", d, i); },
          onmouseout: function (d, i) { console.log("onmouseout", d, i); }
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: '%m-%d',
              rotate: -90
            }
          },
          y: {
            tick: {
              format: function(x) { return x % 1 === 0 ? x : ''; }
            }
          }
        },
        size: {
          width: this.width,
          height: this.height
        }
      });
    }
  },
  mounted () {
    this.loadChart();
  }
}
</script>
<style lang="scss">
#user-graph .tick,
#user-graph .c3-legend-item {
  fill: white;
}

#user-graph .domain {
  fill: none;
  stroke: white;
}
</style>
